.content-box-shadow {
    background: $white;
    box-shadow: 0 0 3px nth($grey-shades, 26);
    color: nth($grey-shades, 12);
    min-height: 100%;
    @include media-breakpoint-down(sm) {        
        margin-bottom: 0;
        padding-bottom: 0;
        .assessment-m-b {
            height: 100%;
            margin-bottom: 0;
        }
    }
}