

/********** Start of CIS Logo  **************/

.header-logo {
    position: absolute;
    height: 95px;
    width: 50px;
    @include media-breakpoint-down(xs) {
        margin-left: 5px;
    }
    @include media-breakpoint-up(md) {
        height: 129px;
        margin-left: 50px;
    }
@include media-breakpoint-up(md) {
    height: 129px;
margin-left: 20px;
}
@include media-breakpoint-up(xl) {
    margin-left: 40px;
    }
    .main-logo {
        display: flex;        
        align-items: flex-end;
        height: 101px;
        width: auto;
    @include media-breakpoint-up(md) {
            height: 140px;
            align-items: center;
            margin-left: 40px;
            }
            
@include media-breakpoint-only(xl) {
    margin-left: 120px;
}
        img {
            width: 70%; 
            height: auto;
            @include media-breakpoint-down(md) {
                width: 57px;

            }
        }        
    }
}

.responsive-header {
    position: absolute;
    top: 35px;
        width: 100px;
        height: 70px;
    
        @include media-breakpoint-down(md) {
            top: 37px;
        }
    img {
        width: 90%;
        height: auto;
@include media-breakpoint-down(lg) {
    width: 75%;
}
    }
}

/********** End of CIS Logo  **************/