@mixin panel-background($bg, $color, $display) {
    background: $bg;
    color: $color;
    display: $display;
}

@mixin panel-gradient-bg($color1, $color2) {
    background: linear-gradient(180deg, $color1 0%, $color1 0%, $color1 33%, $color1 57%, $color2 120%);
    /* w3c */
    padding-top: 25px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: $white;
}

@mixin panel-hover-bg($color, $bg-color) {
    color: $color;
    background: $bg-color;
} ///smiles width claculation
@function width-calculation($some-number, $another-number) {
    @return calc($some-number / $another-number);
}

.assessment-questions {
    i {
        color: $white;
    }
    .class-withOpacity {
        opacity: 0.5;
        cursor: not-allowed!important;
        @include position(relative);
    }
    /****************************
    * Next and Previous  Button  Start
    ****************************/
    .panel-background {
        height: 55px;
        width: 100%;
        @mixin panal-bg-colors( $value...) {
            @each $i in $value {
                &.#{nth($i, 1)} {
                    @include panel-background(nth($i, 2), $white, flex);
                }
            }
        }
        span{
            font-weight: bold
        }
        @include panal-bg-colors('orange' nth($orange-shades, 6), 'light-blue' nth($blue-shades, 32),  'light-blue-shades' nth($red-shades, 16), 'powderblue' nth($green-shades, 4), 'blue' nth($blue-shades, 7), 'skyblue' nth($blue-shades, 21), 'light-green' nth($green-shades, 36), 
                                    'pink' nth($pink-shades, 3), 'dark-green' nth($green-shades, 8), 'purple' nth($purple-shades, 2), 'parrot-green' nth($green-shades, 35), 'light-pink-shades' nth($pink-shades, 12), 'dark-orange' nth($orange-shades, 13) nth($orange-shades, 13), 'deep-blue' nth($blue-shades, 33) nth($blue-shades, 33));
        @include common-border-shadow(-1px, 7px, 10px, -4px, nth($black-shades, 2), 0);
        padding: 15px 8px 12px 6px;
        @include media-breakpoint-down(lg){
            padding: 15px 15px 12px 6px; 
        }
        position: relative;
        .white-color {
            color: $white!important;
            text-decoration: none;
        }
        // .left-arrow-class {
        //     a {
        //         margin: 0 0 0 -10px!important;
        //     }
        //     span {
        //         opacity: 1 !important;
        //         color: $white;
        //         @include position (absolute, $top: 2px, $left: 34px);
        //     }
        // }
        // .right-arrow-class {
        //     span {
        //         opacity: 1 !important;
        //         margin: 0 0 0 -20px;
        //         @include position (relative, $right: 0px, $top: -3px);
        //     }
        // }
        @at-root {
            .progress {
                height: 0.8em;
                //margin-left: 24px;
                @include common-border-radius(0.5em, 1px);
                margin-top: 8px;
                @include media-breakpoint-down(sm) {
                    margin-top: 11px; 
                }
                .progress-bar-bg-green {
                    background: nth($green-shades, 37);
                    border: 1px solid gray;
                    border-radius: 4px;
                    border-left: 0px;
                }
            }
        }
        i {
            cursor: pointer;
            font-size: $font-size-lg!important;
        }
        .class-with-opacity {
            opacity: 0.5;
            cursor: not-allowed;
            position: absolute;
        }
    }
    .wes_remaining-width{
        width: 180px;
    }
    /****************************
    * Next and Previous Button  end
    ****************************/
    /****************************
    * Smiles Button  Start
    ****************************/
    .panel-question-box {
        min-height: 186px;
        @mixin panal-bg-gradent( $value...) {
            @each $i in $value {
                &.#{nth($i, 1)} {
                    @include panel-gradient-bg(nth($i, 2), nth($i, 3));
                }
            }
            span {
                font-weight: bold;
            }
        }
        @include panal-bg-gradent('orange' nth($gradient-shades, 1) nth($gradient-shades, 1), 'light-blue' nth($blue-shades, 32) nth($blue-shades, 32), 'powderblue' nth($green-shades, 6) nth($green-shades, 6), 
                                    'blue' nth($blue-shades, 5) nth($blue-shades, 5), 'skyblue' nth($blue-shades, 21) nth($blue-shades, 21), 'light-green' nth($green-shades, 36) nth($green-shades, 36), 'light-blue-shades' nth($red-shades, 16) nth($red-shades, 16), 
                                    'pink' nth($gradient-shades, 4) nth($gradient-shades, 4), 'dark-green' nth($green-shades, 8) nth($green-shades, 8), 'purple' nth($purple-shades, 2) nth($purple-shades, 2), 'parrot-green' nth($green-shades, 35) nth($green-shades, 35), 
                                    'light-pink-shades' nth($pink-shades, 12) nth($pink-shades, 13), 'dark-orange' nth($orange-shades, 13) nth($orange-shades, 13), 'deep-blue' nth($blue-shades, 33) nth($blue-shades, 33));
        @include media-breakpoint-down(sm) {
            .mini-bar{
                padding: 1px 0;
                box-shadow: 0 2px 7px 1px #404040;
                .white-color {
                    width: 13px;
                }
                button {
                    background-color: transparent;
                    border: none;
                }
            }
            .mobile-height{
                height: 65px;
            }
            .mobile-height-os{
                height: 85px;
            }
            min-height: 140px!important;
            padding-top: 0!important;
        }
    }
    .circle-width {
        float: left;
        &.four {
            width: width-calculation(100%, 4);
        }
        &.five {
            width: width-calculation(100%, 5);
        }
        &.three {
            width: width-calculation(100%, 3);
        }
        &.six {
            width: width-calculation(100%, 6);
            .circle-button{
                @media (max-width:991px){
                    width: 110px;
                    height: 110px;
                }
            }
        }
    }
    .icon-styles {
        font-size: $display4-size;
        @include panel-hover-bg(nth($orange-shades, 1), none);
        @include media-breakpoint-down(lg) {
            font-size: $font-size-h2;
        }
        @include media-breakpoint-down(md) {
            font-size: $font-size-h4;
        }
    }
    .smile-circle {
        @include media-breakpoint-only(md) {
            margin-top: -82px;
        }
                @include media-breakpoint-up(lg) {
            margin-top: -105px;
        }
        .circle-button {
            font-size: $font-size-base;
            line-height: $font-sizes-6;
            color: nth($grey-shades, 13)!important;
            background-color: $white;
            margin: auto;
            position: relative;
            cursor: pointer;
            padding-top: 20px;
            // @include media-breakpoint-between(md, lg) {
            //     padding-top: 5px!important;
            // }
            i {
                @extend .icon-styles;
            }
            @include common-border-radius(50%, 0);
            @include height-width(180px, 180px);
            @include common-border(2px, 2px, 2px, 2px, nth($grey-shades, 16));
            @include media-breakpoint-down(lg) {
                @include height-width(135px, 135px);
            }
            .wil-assessment-page & {
                @include height-width(130px, 130px);
                i {
                    font-size: $font-size-h1!important;
                }
            }
        }

    }
    /****************************
    * Smiles Button  End
    ****************************/
    .smile-cards-mobile {
        .smile-card-body {
            @include common-border-shadow(0, 1px, 3px, 1px, nth($grey-shades, 18), 0);
            position: relative;
            padding: 12px 0px 5px 10px;
            overflow: hidden;
            min-height: 52px;
            margin: 10px 0;
            cursor: pointer;
            i {
                @extend .icon-styles;
            }
            p {
                display: table-cell;
                vertical-align: middle;
                padding-top: 4px;
            }
        }
    }
    .hover:hover,
    .previous {
        @mixin smile-hover( $value...) {
            @each $i in $value {
                &.#{nth($i, 1)} {
                    @include panel-hover-bg($white, nth($i,2));
                }
            }
        }
        @include smile-hover('orange' nth($orange-shades, 6),'light-blue' nth($blue-shades, 32), 'powderblue' nth($green-shades, 4), 'blue' nth($blue-shades, 7), 'skyblue' nth($blue-shades, 21), 'light-green' nth($green-shades, 36), 'light-blue-shades' nth($red-shades, 16), 'pink' nth($pink-shades, 3), 'dark-green' nth($green-shades, 8), 'purple' nth($purple-shades, 2),'parrot-green' nth($green-shades, 35), 'light-pink-shades' nth($pink-shades, 12), 'dark-orange' nth($orange-shades, 13) nth($orange-shades, 13), 'deep-blue' nth($blue-shades, 33) nth($blue-shades, 33));
        text-decoration: none;
        border-color: $white;
        p,i,.level-4 {
            color: $white!important;
        }
        .fa::before {
            color: $white!important;
        }

    }
    /******************
    * Range bar start 
    ******************/
    .rangebar-paragraph {
        display: table-cell;
        @include height-width(180px, 172px);
        margin: auto;
        padding: 0;
        vertical-align: middle;
        text-align: center;
    }
    .range-barcircle {
        font-size: $font-size-h6!important;
        line-height: $font-sizes-6 !important;
        cursor: default;
        white-space: normal;
        margin: auto;
        position: relative;
        @include common-border-radius(50%, 0);
        @include height-width(160px, 160px);
        
            @include media-breakpoint-between(md, lg) {
                    @include height-width(125px, 125px);
        }
    }
    .range-bar {
        background: none;
        top: 18px;
        border: none;
        box-shadow: none;
        left: 2px;
    }
    .green-circle {
        background-color: nth($green-shades, 8);
        @include common-border(2px, 2px, 2px, 2px, $white!important);
        color: $white;
        p {
            display: table-cell;
            margin: auto;
            padding: 0;
            vertical-align: middle;
            @include height-width(170px, 145px);
            text-align: center;
            @include custom-device(768px, 992px) {
                @include height-width(90px, 80px);
            }
        }
    }
    .white-circle {
        color: nth($grey-shades, 28)!important;
        background-color: nth($grey-shades, 29)!important;
        @include common-border(2px, 2px, 2px, 2px, nth($grey-shades, 16)!important);
    }
    .noUi-vertical {
        @include custom-device(200px, 768px) {
            @include height-width(20px, 270px);
            position: absolute;
        }
    }
    /******************
    * Range bar End 
    ******************/

/******************
    * Arrow ToolTip Start 
    ******************/
@media (min-width: 768px) {
    .arrowToolTip {
        @include position(relative, $top: 5px, $right: 60px);
        display: inline-block; 
        border-bottom: 1px dotted $black;
    .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: $gray;
        color: $white;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        @include position(absolute, $left: 50%, $bottom: 125%);    
        z-index: 1;    
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;
 &::after {
        content: "";
        @include position(absolute, $top: 100%, $left: 71%);  
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: $gray transparent transparent transparent;
    }
}  
.tooltiptext {
        visibility: visible;
        opacity: 1;
        
    }
    }
}
  
@media (min-width: 768px) {
.arrow_box {
    position: relative;
    background: nth($grey-shades, 4);
    border: 2px solid nth($grey-shades, 9);
    padding: 5px;
    border-radius: 6px;  
  &:after, &:before {
    @include position(absolute, $left: 50%, $bottom: 100%);
    border: solid transparent;
    content: " ";
    @include height-width(0px, 0px);
    pointer-events: none;
  }  
  &:after { 
    border-bottom-color: nth($grey-shades, 4);
    border-width: 15px;
    margin-left: -15px;
  }
  &:before {
    border-bottom-color: nth($grey-shades, 9);
    border-width: 18px;
    margin-left: -18px;
  }
   

  }

}
 /******************
    * Arrow ToolTip End 
    ******************/
    &.babby-cci {
        .smile-circle {
            margin-top: 0px!important;
        }
        .circle-button{
            @media (max-width: 1199px){ @include height-width(180px, 180px); }
            @media (max-width:992px){  @include height-width(140px!important, 140px!important); }
            p {
             color:nth($grey-shades,13);
            }
            &:hover{
                p{
                    font-weight: bold;
                }
            }
        }
    }
    .baby-cci-frame{
        position: absolute;
        margin: -5px 0px 0px -12px;

        img{
        
            width: 100%;
                    // height: 100%;
                // @include custom-device(768px, 992px) {
                //         height: 345px;
                    //     }
                }
    }
    .qstn-count-large {
        @media (min-width: 1200px) {
            width: 46px;   
        }
        button {
            right: 3px;
        }
    }
    .qstn-count {
        @include media-breakpoint-up(md) {
            width: 35px;   
        }
        @include media-breakpoint-up(lg) {
            width: 36px;   
        }
    }
   
}
/****************************
    * Smiles Card  Start WIL
****************************/

.wil-assessment-page {
    @include media-breakpoint-up(md) {
        .wil-border {
            @include common-border(1px, 1px, 1px, 1px!important, nth($grey-shades, 8)!important);
        }
        .will-scroll {
            position: relative;
            height: 303px;
            overflow-x: hidden;
            overflow-y: auto;
            width: 100%;
            padding: 10px;
            padding-bottom: 10px;
        }
        .smile-circle {
            margin-top: -90px!important;
        }
        .panel-question-box {
            min-height: 145px!important;
            p {
                padding: 5px;
            }
        }
    }
    .will-footer {
        @include position(absolute, $bottom: 10px);   
        .pink-smiley{
            border-radius: 50%;
            color: white !important;
            padding: 0px 7px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #cd79ab !important
        }
}
.wil-emoji-btn{
    background: transparent;
    width: 35px;
    padding: 0px;
    }
    .pb-space {
        padding-bottom: 2.5rem !important;
    }
    .wil-card-block {
        padding: 10px;
        height: 175px;
        background: nth($white-shades, 10);
        .text-center {
            font-size: $font-sizes-4;
        }
        @include media-breakpoint-down(xs) {
            height: 130px;
        }
        ul {
            list-style: none;
            display: flex;
            font-size: $font-sizes-9;
            padding: 0px;
            margin: auto;
 			justify-content: center;
            height: 40px;
            @include media-breakpoint-down(sm){
            //height: 10px;
            }
            li {
                text-align: center;
              
                i {
                    color: nth($green-shades, 21);
                }
                .common-button {
                    height: 45px;
                    padding: 5px 4px 8px;
                    font-size: 1rem;
                    align-items: flex-start;
                    display: flex;
                    @include media-breakpoint-down(sm){
                    padding: 7px 4px 8px;
                    height: 30px;
                    }
                }
                .wil-circle-notAllowed {
                    position: absolute;
                    // top: 12px!important;
                    margin:0px -3px;
                    color: nth($red-shades, 3);
                    opacity: 0.6;
                    font-size: $font-sizes-15;
                }
                &:last-child {
                    display: none;
                }
            }
        }
    }
    @at-root {
        .checked-image-wil {
            margin: 0px 3px 0 0;
            padding: 0;
            @include position(absolute, $top: 0px, $right: 0px);
        }
        .wil-select-right {
            @include height-width(0, 0);
            overflow: hidden;
            @include position(absolute, $top: -1px, $right:-1px);
            border-top: 40px solid nth($green-shades, 6);
            border-left: 40px solid transparent;
        }
    }
    .sticky-smile-box {
        background: nth($pink-shades, 3);
        height: 65px;
        width: 100%;
        padding-left: 5px;
        padding-top: 6px;
        left: 0;
        z-index: 9;
        li {
            width: 20%;
            text-align: center;
        }
        .padding-smiles {
            padding: 0 5px;
        }
        .smile-list {
            background: $white;
            margin: 2px;
            i,
            p {
                color: nth($pink-shades, 3);
            }
        }
        p {
            color: $white;
            margin-bottom: 0;
        }
        i {
            font-size: $font-size-lg;
        }
    }
}


/****************Reality Check Start ************/


.reality-check {
    
/******************
    * Arrow ToolTip Start 
    ******************/
@media (min-width: 768px) {
    .arrowToolTip {
        @include position(absolute, $top: -15px, $right: 75px);
        display: inline-block; 
        border-bottom: 1px dotted $black;
    .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: nth($sky-blue, 2);
        color: $white;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        @include position(absolute, $left: 50%, $bottom: 125%);    
        z-index: 1;    
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;
 &::after {
        content: "";
        @include position(absolute, $top: 100%, $left: 71%);  
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: nth($sky-blue, 2) transparent transparent transparent;
    }
}  
.tooltiptext {
        visibility: visible;
        opacity: 1;
        
    }
    }
}
  
@media (min-width: 768px) {
.arrow_box {
    position: relative;
    background: nth($sky-blue, 2);
    border: 2px solid nth($sky-blue, 2);
    padding: 5px;
    border-radius: 6px;  
  &:after, &:before {
    @include position(absolute, $left: 50%, $bottom: 100%);
    border: solid transparent;
    content: " ";
    @include height-width(0px, 0px);
    pointer-events: none;
  }  
  &:after { 
    border-bottom-color: nth($sky-blue, 2);
    border-width: 15px;
    margin-left: -15px;
  }
  &:before {
    border-bottom-color: nth($sky-blue, 2);
    border-width: 18px;
    margin-left: -18px;
  }
   

  }

}
 /******************
    * Arrow ToolTip End 
    ******************/
    
    .header_3 {
    color: nth($sky-blue, 2);
    @include media-breakpoint-down(sm) {  
    font-size: $font-sizes-7 !important;
   }
    .custom-with {
    &:nth-child(odd) {
    padding-right: 5px;
   }
   &:nth-child(even) {
    padding-left: 5px;
   }
   }
   }
   /**************** tabs Start ************/
   
    .tab-bar-arrows {
       background: nth($sky-blue, 2);
       padding: 10px 1px 10px 1px;
       border-radius: 8px;
   }
   .rc-tab {
    background: nth($white-shades, 3);
       border-radius: 8px;
    ul {
    position: relative;
    height: 48px;
    @include media-breakpoint-up(lg) {
    height: 70px;
   }
    display: table;
    width:  100%;
    margin: auto;
    li {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    a {
        padding: 0;
   }
    span.round-tab {
    @include height-width(18px, 18px);
    @include media-breakpoint-only(md) {
    @include height-width(29px, 29px);
    font-size: 16px;
   }
    @include media-breakpoint-up(lg) {
    @include height-width(45px, 45px);
   font-size: 25px;
   }
    display: inline-block;
    border-radius: 100px;
    background: $white;
    z-index: 2;
    text-align: center;
    font-size: 12px;
   }
    span.round-tab:hover {
    color: $white;
    background: nth($green-shades, 13);
    i {
    color: $white;
   }
   }
    i {
    @include media-breakpoint-only(md) {
    line-height: 29px;
   }
    @include media-breakpoint-up(lg) {
    line-height: 45px;
   }
    color: nth($black-shades, 1);
   }
   }
    .nav-item.disabled {
    opacity: 0.2;
    pointer-events: none;
    cursor: default;
   }
   }
   .nav-pills .savedIds {
    .nav-link {
    background: none!important;
    border: none!important;
    span.round-tab {
    color: $white;
    background: nth($blue-shades, 10);;
    i {
    color: $white;
   }
   }
   }
   }
   .nav-pills .nav-link.active {
       background: none!important;
       border: none!important;
    span.round-tab {
    color: $white;
    background: nth($green-shades, 13);
    i {
    color: $white;
   }
   }
    &::after {
        @include media-breakpoint-up(md) {
            content: "";
            display: block;
            border-left: 17px solid transparent;
            border-right: 17px solid transparent;
            border-top: 17px solid nth($white-shades, 3);
            @include position (absolute, $right: 0px, $left: 0px);
            @include height-width(0px, 0px);
            margin: 3px auto;
        }
        @include media-breakpoint-up(lg) {
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid nth($white-shades, 3);
        }
   }
   }
   }
   /****************End  tabs ************/
   
   
   /**************** Cards Designs Start ************/       
       
   
       .card-align-justify {
    @media (max-width: 379px) {
    width: 500px;
    justify-content: center;
   }
    @media (min-width: 380px) and (max-width: 544px) {
    width: 500px;
   }
    @media (min-width: 545px) and (max-width: 767px) {
    width: 498px;
   }
    @include media-breakpoint-only(md) {
    width: 664px;
   }
    @include media-breakpoint-only(lg) {
    width: 830px;
   }
   .card {
    background: nth($green-shades, 13);
    border: 2px solid nth($green-shades, 13)!important;
       height: 195px;
       width: 150px;
    .right-checked {
    @include position(absolute, $top: 15px, $left: 0, $right: 0);
    pointer-events: hand;
   }
   input {
       border-radius: 8px;
       border: none;
       padding-left: 5px;
   }
    input[type=number]::-webkit-inner-spin-button,  input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
   }
   }
   }
   .card-align-justify-four {
    @include media-breakpoint-only(lg) {
    justify-content: center;
    margin: auto;
   }
   }
   .mb-2:last-child .card p span {
       display: none;
   }
   /****************End  Cards Designs ************/  
   
   
   /****************tooltips Start ************/  
       
      
   
                  .tooltip-tab {
                      position: relative;
                      cursor: pointer;
                      display: inline-block;
        
                      .tooltiptext {
                          text-align: center;
                          // color: nth($blue-shades, 16);
                          color: nth($green-shades, 13);
                          background: nth($white-shades, 3);
                          background-clip: padding-box;
                          box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5);
                          border: 3px solid nth($green-shades, 13);
                          ;
                          border-radius: 8px;
                          @include position (absolute, $left: 50%);
                          width: 120px;
        
                          @include media-breakpoint-down(md) {
                              width: 85px;
                              margin-left: -42px;
                          }
        
                          margin-left: -58px;
                          padding: 2px 0;
                          bottom: 100%;
                          margin-bottom: 10px;
                          visibility:hidden;
                          opacity:0;
        
                          &:before,
                          &:after {
                              content: "";
                              @include position (absolute, $top: 100%, $left: 50%);
                              border-left: 10px solid transparent;
                              border-right: 10px solid transparent;
                              margin-left: -10px;
                          }
        
                          &:before {
                              border-top: 10px solid nth($green-shades, 13);
                              border-top: 10px solid nth($green-shades, 13);
                              margin-top: 0px;
                          }
        
                          &:after {
                              border-top: 10px solid nth($white-shades, 3);
                              margin-top: -4px;
                              z-index: 1;
                          }
        
                          visibility: visible;
                          opacity: 1;
                      }
                  }
}
   /****************tooltips End ************/   
      
   
   
   /****************clusters  List  ************/ 
   
     
   
   .reality-check-box {
    @media (min-width: 768px) {
    column-count: 2;
   }
   p {
       text-align: left;
   }
   .rc-select-right {
    @extend .wil-select-right;
    &::after {
    border-top: 500px solid nth($green-shades, 13);
   }
   }
   .rc-select-tickmark {
    @include position (absolute, $top: 5px, $right: 5px);
   }
   .list-group-item {
       height: 74px!important;
    i {
    font-size: $font-sizes-12;
   }   
   }
   .list-group-item:focus{
       border: 1px dotted;
   }
   .list-group-item.disabled {
    opacity: 0.2;
    pointer-events: none;
    cursor: default;
    }
   .list-group-item-addon {
       height: 30px;
   }
   .list-group-item-addon.disabled {
    opacity: 0.2;
    pointer-events: none;
    cursor: default;
    }
    .mb-2:nth-child(1) {
    .list-group-item {
    background: nth($white-shades, 9)!important;  
   }
    i {
    color:  nth($orange-shades, 1);
   }
   }
   .mb-2.disabled {
       opacity: 0.2;
       pointer-events: none;
       cursor: default;
   }
    .mb-2:nth-child(1) {
   order: 1;
   }
    .mb-2:nth-child(2) {
   order: 3;
   }
    .mb-2:nth-child(3) {
   order: 5;
   }
    .mb-2:nth-child(4) {
   order: 7;
   }
    .mb-2:nth-child(5) {
   order: 9;
   }
    .mb-2:nth-child(6) {
   order: 11;
   }
    .mb-2:nth-child(7) {
   order: 13;
   }
    .mb-2:nth-child(8) {
   order: 15;
   }
    .mb-2:nth-child(9) {
   order: 17;
   }
    .mb-2:nth-child(10) {
   order: 2;
   }
    .mb-2:nth-child(11) {
   order: 4;
   }
    .mb-2:nth-child(12) {
   order: 6;
   }
    .mb-2:nth-child(13) {
   order: 8;
   }
    .mb-2:nth-child(14) {
   order: 10;
   }
    .mb-2:nth-child(15) {
   order: 12;
   }
    .mb-2:nth-child(16) {
   order: 14;
   }
    .mb-2:nth-child(17) {
   order: 16;
   }
   }
   /****************End clusters  List  ************/ 
   
   
  
   /***********************
   End  Reality Check 
   ************************/
   
