

.well-p2:hover,.well-p2:focus{
    background:$white!important;
    box-shadow:0 0 5px nth($grey-shades,8);
    z-index:999;  
    -webkit-transition:all .2s ease-in;    
    -webkit-transform:scale(1.0);
    -ms-transition:all .2s ease-in;
    -ms-transform:scale(1.0);
    -moz-transition:all .2s ease-in;
    -moz-transform:scale(1.0);
    transition:all .2s ease-in;
    transform:scale(1.0);    
    margin-bottom:5px;
    cursor: pointer;
    .checked-image, .eq-select-right{
       @include position(absolute, $top:0px, $right : 0px);
    }
}
.cards-hover-effect:hover,.cards-hover-effect:focus{
    box-shadow:0 0 5px nth($grey-shades,8);
}

/* Ideas chart Animation */
@keyframes first {
    from {transform: rotate(0deg);}
    to {transform: rotate(60deg);}
}
    
@keyframes second {
    from {transform: rotate(0deg);}
    to {transform: rotate(150deg);}
}
    
@keyframes third {
    from {transform: rotate(0deg);}
    to {transform: rotate(111deg);}
}
@keyframes five {
    from {transform: rotate(0deg);}
    to {transform: rotate(160deg);}
}
.ability-model{
    -webkit-animation: NAME-YOUR-ANIMATION 3s;
    -moz-animation: NAME-YOUR-ANIMATION 3s;
    -o-animation: NAME-YOUR-ANIMATION 3s;
    animation: NAME-YOUR-ANIMATION 3s;
    opacity: 1;
}

@keyframes NAME-YOUR-ANIMATION {
    0% {
        opacity: 0;
    }
    49% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    99% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
 }