/**************************
*  Main Footer
**************************/
.common-footer {
    width: $width-value;
    @extend .fixed-bottom;
    z-index: 1050;
    &.assessments-footer {
        @include media-breakpoint-down(sm) {
            position: absolute;
            bottom: 0;
        }       
    }
    @include media-breakpoint-down(md) {
        position: relative;
        z-index: 0;        
    }
    .copyright {
        background: nth($black-shades, 6);
        color: nth($grey-shades, 10);
        z-index: 5;
        width: $width-value;
        font-size: $font-size-xs!important;
        @media (min-width: 457px) {
            z-index: 0;
        }
        @include media-breakpoint-up(md) {
            .footer-text-desktop {
                // display: flex;
                flex-direction: column;
                height: 64px;
                overflow-y: auto; /* Vertical scroll shown when more content visible in footer box */
                width: $width-value - 18%;
                margin: 0 auto;
                padding: 10px;
            }
        }
        // @include custom-device(320px, 359px) {
        //     font-size: $font-size-xs!important;
        // }
        @include media-breakpoint-down(sm) {
            @include position(relative);
            font-size: $font-size-sm;
            p {
                vertical-align: middle;
                margin: 0;
                padding: 18px;
            }
            .footer-mobile-text {
                color: nth($green-shades, 5);
                display: inline-block;
            }
            .tooltip-footer {
                width: $width-value;
                text-align: center;
                .footer-mobile-text {
                    color: nth($green-shades, 5);
                }
                .tooltiptext {
                    visibility: hidden;
                    background-color: nth($black-shades, 6);
                    text-align: center;
                    padding: 10px 20px 5px;
                    @include position(absolute, $left: 0);
                    z-index: 1;
                    bottom: 0!important;
                    @include common-border-radius(0, 0);
                    color: nth($grey-shades, 10);
                    cursor: pointer;
                    overflow-x: hidden;
                }
                &:hover .tooltiptext {
                    visibility: visible;
                    @include position(absolute, $top: 10px, $left: 0);
                    z-index: 99;
                    height: auto;
                    display: block;
                    line-height: 18px;
                    width: 100%;
                }
            }
        }
    }
    .footer-bottom {
        height: 10px;
        background: $black;
    }
}