/****************************
* Header Section
****************************/
.master-head {
    background: $white;
    height: 45px;

    .master-head-text {
        @include common-header(nth($blue-shades, 11), $font-size-lg, center, none);
        font-size: 1.0em;
        
            @include custom-device(480px, 992px) {
                    padding-right: 5px;
                font-size: 0.9em;
        }
        @include large-device(992px) {
            font-size: 1.1em; //$font-size-h5;
        }
@include custom-device(none, 480px) {
            @include common-header(none, $font-sizes-3, left, none);
            text-align: center;
            padding-right: 5px;
                font-size: 1.0em;
        }
    }
    .master-header-top {
        // @include position (absolute, $top: 0, $right: 17px);
        z-index: 9;
        display: block;
        .master-header-menu {
            color: nth($blue-shades, 2);
            @include large-device(768px) {
                .tooltip {
                    display: none!important;
                }
            }
            ul li {
                &.account-dropdown {
                    border-left: 4px solid $white;
                }
                list-style: none;
                .dropdown-toggle {
                    position: relative;
                    background: none;
                    border: none;
                    color: nth($blue-shades, 11)!important;
                    @include position(relative, $top: 1px);
                    @include media-breakpoint-up(sm) {
                        background: transparent;
                        .text-display-head {
                            display: flex;
                            img {
                                width: 20px;
                                top: -2px;
                                position: relative;
                            }
                            /** Added Elipses for header user name */
                            .login-mobile-username {
                                overflow: hidden;
                                max-width: 170px;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                        }
                        .icn-display-head {
                            display: none;
                        }
                    }
                                        @include media-breakpoint-down(lg) {
                        background: $secondary_color;
                        color: $white;
                        padding: 4px;
                        .text-display-head {
                            display: none;
                        }
                        .icn-display-head {
                            display: inline-block;
                            margin: 1px 0px;
                            @include common-header($white, $font-size-sm, none, none);
                            img {
                                width: 25px;
                                border-radius: 50%;
                            }
                        }
                    }
                }
                .dropdown-menu-right {
                    min-width: 7rem;
                    left: auto !important;
                }
                @include media-breakpoint-up(lg) {
                    background: $gray-lighter;
                    height: 41px;  
                    &.bg-transparent {
                        background: transparent
                    }                 
                }
               
                @include media-breakpoint-down(sm) {
                    background: none;
                    .dropdown-toggle::after {
                        display: none;
                    }
                }
                @include media-breakpoint-down(lg) {
                    .dropdown-menu {
                        right: 0;
                        left: auto;
                    }
                }                
            }
            @include media-breakpoint-down(sm) {
                [data-tooltip] {
                    &::before,
                    &::after {
                        display: block;
                    }
                }
            }
            @include media-breakpoint-up(sm) {
                [data-tooltip] {
                    &::before,
                    &::after {
                        display: none;
                    }
                }
            }
            .username-3-dots {
                max-width: 232px;
                cursor: auto;
                @extend .three-dots;
                vertical-align: middle;
                /** Padding is added to User Profile name */
                img {
                    width: 20px;
                }
                span{
                    top: 3px;
                    position: relative;
                }          
            }
            .icn-signout {
                background: $secondary_color;
                color: $white;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                i {
                    color: nth($blue-shades, 31);
                }
            }
            .gear-icon {
                width: 18px;
            }
            .dropdown-menu {
                right: 0;
                box-shadow: 0 5px 7px -1px nth($black-shades, 12);
                margin-left: auto;
                cursor: auto;
                margin-top: 8px
            }
        }
    }
}


/****************************
* Header Section
****************************/


/****************************
* Assessment Header 
****************************/

.assessment-header {
    /******desktop*****/
    @at-root {
        .common-headding {
            @include common-header($secondary_color, $font-sizes-7, left, bold);
            line-height: 1.1;
            display: flex;
            align-items: center;
            @include media-breakpoint-between(md, lg) {
                font-size: $font-size-base;
            }
            @include media-breakpoint-down(xs){
                width: 100%;
            }          
            &.sub-headding {
                margin-bottom: 10px;
            }
            img {
                max-width: $headerIconSize;
                height: auto;
                padding-right: 10px;
            }
            .title-wraps {
                line-height: 1.5;
               display: table-cell;
               vertical-align: middle;
            }
        }
    }
  
    .headding-mobil-ass {
        background: nth($green-shades, 13);
        margin-left: -15px;
        margin-right: -15px;
        
    }
    .mobile-title-bg {
        overflow-wrap: break-word;
        color: $white;
        box-shadow: 0 4px 6px -6px nth($grey-shades, 14);
        font-weight: 700;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .headding {
            padding: 15px 0 15px 5px;
            img {
                width: 75%;
            }
        }
        .icons-mobile {
            display: inline-flex;
            i {
                font-size: $font-sizes-5;
                &.font-siz {
                    font-size: $font-sizes-20;
                }
            }
            button {
                box-shadow: none;
                min-width: 30px;
                margin-top: 10px;
                height: 40px;
            }
        }
    }
    .hr-line {
        margin-bottom: 20px;
        padding-top: 10px;
        @include common-border(0, 0, 1px, 0, nth($white-shades, 3));
        overflow: hidden;
        width: $width-value;
    }
}

.left-arrow_icon{
    font-size: 1.16em!important; // to override the default font-size
    position: relative;
    top: 1px;
    height: 18px;
}
/****************************
* Assessment Header End
****************************/


.wt-space {
    white-space: normal;
}
.d-block {
    display: block;
}
.account-setting-header {
    @include common-header($secondary_color, $font-sizes-7, left, bold);
    line-height: 1.1;

    @include media-breakpoint-between(md, lg) {
        font-size: $font-size-base;
    }
}