.tooltip.tooltip-right,
.tooltip.bs-tether-element-attached-left {
    z-index: 999 !important;
    width: 100% !important;
}

.career-list {
    @include media-breakpoint-up(sm) {
        .common-checkbox {
            margin-left: 10px;

            &.filter-block-checkbox {
                margin-left: 45px;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .common-checkbox {
            margin-left: 0px;
            margin-top: 0px;
        }

        &.filter-block-checkbox {
            margin-left: 45px;
        }
    }

    .common-checkbox label {
        display: flex;
        right: 5px;

    }

    .input-group {
        margin-top: 10px !important;
    }

    .cluster-panal-list {
        &.search-results {
            @include media-breakpoint-down(sm) {
                .common-checkbox label {
                    padding-left: 2px;
                    right: 0;
                }
            }
        }
    }

    .search-results {
        margin-bottom: 20px;
        @include common-border(1px, 1px, 1px, 1px, nth($grey-shades, 8));

        .mbl-emp-wag-align {
            @include media-breakpoint-down(sm) {

                // When there is no hotjob for the career. We are hiding hotjob text in mobile view.
                .hotjob-hidden,
                .stem-hidden,
                .edu-hidden {
                    display: none;
                }

                li {
                    display: flex;

                    .hotjob-text {
                        line-height: 24px;
                    }
                }
            }
        }

        @include media-breakpoint-up(md) {
            .list-group-item {
                padding: 5px 5px 5px 15px;
            }
        }

        @include media-breakpoint-down(sm) {
            .common-checkbox label {
                padding-left: 6px;
            }
        }
    }

    // .text-wages{ margin-left: -13px; }
    //  .powderblue .tp-alignLeft {
    //     & + .tooltip {
    //         position: absolute !important; //We have written important & absolute to to align the Tooltip hover message.
    //         top: -2px !important;
    //         transform: translate(0px, -60px);
    //    }
    // }
    // .powderblue .tp-align_right {
    //     & + .tooltip {
    //         transform: translate(0px, -55px) !important; //We have written important to to align the Tooltip hover message.
    //     }
    // }
    // .common-checkbox{
    //     &+ .tooltip{
    //         z-index: 1 !important; //We have written important & absolute to to align the Tooltip hover message.
    //     }
    // }
    // .title-tooltip a{
    //     &+ .tooltip{
    //         z-index: 1 !important; //We have written important & absolute to to align the Tooltip hover message.
    //     }
    // }
    .thumbsdown-tooltips {
        position: relative;

        &+.tooltip {
            position: absolute !important; //We have written important & absolute to to align the Tooltip hover message.
            bottom: 15px !important; //We have written important & absolute to to align the Tooltip hover message.
            width: 200px !important;
        }
    }

    .thumbsdown-tooltips-fav {
        &+.tooltip {
            position: absolute !important; //We have written important & absolute to to align the Tooltip hover message.
            top: -25px !important; //We have written important & absolute to to align the Tooltip hover message.
            width: 200px !important;
        }
    }

    .thumbsdown-tooltips-hotjob {
        &+.tooltip {
            position: absolute !important; //We have written important & absolute to to align the Tooltip hover message.
            top: 10px !important; //We have written important & absolute to to align the Tooltip hover message.
            width: 200px !important;
        }
    }

    .checkbox-tooltips {
        &+.tooltip {
            transform: translate(25px, -5px) !important; //We have written important to to align the Tooltip hover message.
        }
    }

        // .left-tooltip {
        //     &+.tooltip {
        //         width: 200px !important;
        //         position: absolute !important; //We have written important & absolute to to align the Tooltip hover message.
        //         top: 0px !important; //We have written important & absolute to to align the Tooltip hover message.
        //     }
        // }

    .btn-tooltip {
        &+.tooltip {
            width: 100px !important;
            z-index: 2 !important;
        }
    }

    .career-list-table {
        &.powderblue a {
            color: $white;
        }

        .font-reduce {
            font-size: $font-sizes-5;
        }

        @include common-border(0px, 0px, 1px, 0px, $gray-light);
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;

        &.powderblue {
            background-color: $secondary-color;
            padding: 10px 5px 10px 15px;
            color: $white;
            font-size: $font-sizes-5;
            font-weight: bolder;
            font-size: $font-sizes-5;

            @include media-breakpoint-only(md) {
                font-size: $font-sizes-3;
            }
        }

        &.grey {
            background-color: $gray-lighter;
            padding: 12px 5px;
            cursor: pointer;
            color: $black;

            button {
                background: none;
                box-shadow: none;
                border: none;
                cursor: pointer;

                i {
                    font-size: $font-size-lg;
                }
            }
        }
    }

    .whitespace {
        white-space: nowrap;
    }

    .cluster-panal-list {
        label {
            & li:nth-child(even) {
                background: nth($white-shades, 8);
            }
        }

        .tooltip-inner {
            width: 350px !important;
        }

        .hot-job {
            font-size: $font-sizes-5;
        }

        .hot-job-image {
            width: 20px;
        }
    }

    a,
    .cluster-panal-list a,
    .text-list {
        word-break: normal;
        @include common-header(nth($green-shades, 20), $font-sizes-5, inherit, inherit);

        @media (max-width:992px) {
            font-size: $font-sizes-5;
        }

        a,
        &:hover {
            text-decoration: none;
        }
    }

    .white-space {
        white-space: nowrap;
    }

    .thumbcolor {
        color: #0e90a0;
        font-size: 1rem;
    }

    .thumb_btn_outline {
        outline: 1px;
        cursor: pointer;
        box-shadow: none
    }

    .thumbsIconSize {
        font-size: $font-sizes-9;
        color: nth($green-shades, 20)
    }

    /****** dtata not found image start******/
    .img-dataNotFound {
        @include height-width(50%, 300px);
        position: relative;
        margin: auto;

        .dta-not-found-img {
            @include position (absolute, $top: 0, $right: 0, $bottom: 0, $left: 0);
            margin: auto;
        }
    }

    /**** dtata not found image End ****/
    .compare-btn-selected {
        opacity: 0.5;
        pointer-events: none;
        cursor: pointer;
        border-radius: 7px;
        height: 38px;
        margin-top: 2px;

        @include media-breakpoint-down(sm) {
            margin-top: 12px;
        }
    }

    /** radio button **/
    .common-radio-btn .radio-button-custom+.radio-custom-label:before {
        border: solid #0e90a0;
    }

    .common-radio-btn .radio-button-custom:checked+.radio-custom-label:before {
        background: #00346d;
    }

    .switch-button-align {

        // scss code for gap between two sections under view to combine for under two screen width.
        //start
        .btn-group {
            margin-top: 5px;
        }

        .common-button {
            margin: 0;
        }
    }

    //end
    .last-filter-icon {
        &:last-child {
            padding-left: 5px;
        }
    }

    @include media-breakpoint-down(sm) {
        .list-thumb {
            position: relative;
            top: -7px;
        }
    }
}

.foc-blue-bg-switch {
    background: nth($blue-shades, 17);
    border-color: nth($blue-shades, 17);
    color: $white;
}

.foc-white-bg-switch {
    background-color: nth($grey-shades, 27);
    border-color: nth($blue-shades, 17);
    box-shadow: none;
    @include common-header(nth($blue-shades, 17), $font-sizes-3, inherit, inherit);
}

.career-onmylist .btn {
    white-space: normal !important;
}

/******* chips css start *********/

.chips-design {
    list-style: none;
    margin: 0px 11px 0px -33px;
    word-break: break-word;
    line-height: 1em;
}

.chips-design li {
    i {
        @include position (relative, $top: 2px);

        @include media-breakpoint-down(sm) {
            @include position (relative, $top: 3px);
        }

        color: nth($red-shades, 4);
                font-size: $font-sizes-5;
        //  padding: 1px 3px 1px 1px;
    }

    h6 {
        font-size: 15px;
        vertical-align: top;
        line-height: 16px !important;
        @extend .three-dots;

        @media (max-width: 321px) {
            max-width: 200px;
        }

        &.cluster-cmpar {
            max-width: 140px;
            margin-left: 15px;
        }

        &.not-onmylist-chips {
            max-width: 140px
        }
    }
}

.filter-Removebtn {
    position: absolute;
    position: static;
}

/**** popup *****/
.content-color .modal-header {
    background-color: nth($orange-shades, 5);
    color: $white;
    padding: 0px 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    &.blue {
        background-color: nth($blue-shades, 17) !important;
        height: 40px;
    }

    i {
        font-size: $font-sizes-10;
        cursor: pointer;
    }
}

/******* chips css end *********/
/**********Ideas occlist chart*****************/
.idea-occ-list-page {

    // @include common-border(1px,1px,1px,1px,#C0C0C0);;
    .list-box-ideas {
        margin: 15px 0px;
    }

    @include custom-device(767px, 991px) {
        .color-text {
            text-align: center;
        }
    }

    @at-root {
        $colors-list: nth($red-shades, 5) nth($green-shades, 31) nth($green-shades, 20) nth($pink-shades, 3) nth($grey-shades, 14);

        @each $current-color in $colors-list {
            $i: index($colors-list, $current-color);

            .level-#{$i} {
                color: $current-color !important;
            }
        }
    }

    .dehigh-lighting-text-num {
        @include common-header(nth($grey-shades, 24) !important, $font-sizes-3, none, none);
        text-decoration: none;
    }

    .dehigh-lighting-text-num-print {
        @include common-header(nth($grey-shades, 24) !important, 13px, none, none);
        text-decoration: none;
    }


    .outerChart span,
    .middleChart span {
        text-decoration: underline;
    }
}

/**********End Ideas occlist chart*****************/
@media (max-width:355px) {

    .d-table-mob {
        display: flex !important;
        width: auto !important;
    }

}

@media (max-width:767px) {

    .d-table-mob {
        display: table;
        width: 100%;
    }

    .d-tablecell-mob {
        display: table-cell;
        vertical-align: middle;
        float: none;
    }

}

.netBtn {
    margin: auto;
}
.occ-active-menu {
    @include media-breakpoint-up(md) {
        border-bottom: 3px solid yellow;
    }
}
.occ-wage {
    margin-left: 20%;

    @include media-breakpoint-down(md) {
        margin-left: 1%;
    }
}
.mobile-design-display {
    .left-margin {
        margin-left: 25px;
    }
}

.occ-left-margin {
    margin-left: 20px;
}

.btn-margin-compare {
    height: 40px !important;

    @include media-breakpoint-down(md) {
        margin-top: 5px !important;
    }
}

.btn-margin-compare-wrap {
    margin-top: 18px !important;

    @include media-breakpoint-down(md) {
        margin-top: 10px !important;
    }
}