/***** Career Header section Start***/

.career-heading {
    align-items: center;
    @include position (relative);
    display: flex;

    i {
        font-size: $font-size-h1;
        line-height: 50px;
    }

    .career-text {
        margin: 0px 0 0 11px;
        @include common-header($secondary_color, $font-size-h6, none, bold);
    }
}

.career-indent {
    text-indent: -20px;
}

.course-indent {
    text-indent: 6px;
}

.rate-career-bg-black {
    background-color: $black;
    height: 58px;
    margin: -58px 0 0;
    opacity: 0.5;
    padding: 10px 0 0;
    @include position (relative, $left: 0, $right: 0);

    @include media-breakpoint-down(sm) {
        margin: -33px 0 0;
        @include padding-height(4px, 33px);
    }
}

div.opacity-career-header {
    @include position (relative, $right: 0px, $bottom: 10, $left: 0);
        padding: 10px 0 0;
    margin: -58px 15px 0;
    @include media-breakpoint-down(sm) {
        margin: -34px 15px 0;
        @include padding-height(4px, 37px);
    }

    .video-icon {
        width: 38px;
        display: block;
    }
}

.video-hight-plp3 {
    @include media-breakpoint-down(sm) {
        height: 15px;
        margin-top: 5px;
    }
}

.video-box {
    @include height-width ($width-value, $height-value);
}

.rate-this-text {
    padding: 10px 10px;
    @include common-header($white, $font-sizes-6, right, none);

    @include media-breakpoint-down(sm) {
        padding: 5px 0px;
        font-size: $font-sizes-3;
    }
}

.view-in-center {
    text-align: center;
}

.adjustment-to-content {
    max-width: 100%;
    width: auto !important;
    display: inline-block;
}

.rating-up-down {
    font-size: $font-sizes-14;
        margin-top: 0px;
        
            @include media-breakpoint-down(sm) {
        font-size: $font-sizes-8;
        margin-top: -4px;
    }
    
                            
    a {
        text-decoration: none;
        padding: 0 5px 0 8px;

        &:focus {
            border: 1px solid $blue;
            color: $blue;
        }
    }

    .fillstroke {
        -webkit-text-fill-color: $white;
        -webkit-text-stroke-width: 2px $white;
        color: $white;
    }
}

.comparemodal {
    position: absolute;
    margin-top: -50px;
}

.career-card {
    flex-direction: column;
    display: flex;
    @include common-header($white, $font-sizes-6, inherit, inherit);
    margin-bottom: 10px;

    &.orange {
        background: nth($orange-shades, 2) !important;
    }

    &.blue {
        background: nth($blue-shades, 7) !important;
        @include common-border(1px, 1px, 1px, 1px, nth($blue-shades, 7));
    }

    &.purple {
        background: nth($purple-shades, 1) !important;
        @include common-border(1px, 1px, 1px, 1px, nth($purple-shades, 1));
    }

    &.sky-blue {
        background: nth($sky-blue, 1) !important;
    }

    .career-card-header {
        background: rgba(0, 0, 0, 0.1);
        color: $white;
        padding: 0px 10px 0px;
        height: 65px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-up(md) {
            padding: 0px 15px 0px;
        }

        .card-title-print {
            vertical-align: middle;
            width: 100%;
            display: flex;
            @include common-header($white, 18px, none, bold);
            margin: 0px;
            align-items: center;
        }

        .font-small {
            font-size: $font-sizes-3;
        }

        .card-title {
            vertical-align: middle;
            width: 100%;
            display: flex;
            font-size: $font-sizes-5;
                @include common-header($white, $font-sizes-5, none, bold);
            margin: 0px;
            align-items: center;
        }

        i {
            font-size: $font-sizes-13;
        }

        img {
            width: 30px;
            height: 40px;

            &.card-header-image {
                width: auto;
                height: auto;
                margin-right: 10px;
            }
        }

        .icn-font-size {
            font-size: $font-sizes-10;
        }

        @include media-breakpoint-down(sm) {
            font-size: $font-size-sm;

            .icn-font-size {
                font-size: $font-size-base;
            }
        }

        .accordion-pathways {
            span {
                width: 93%;
                float: left;
            }

            button {
                width: 5%;
            }
        }
    }

    .card-height_alignment {
        @include media-breakpoint-up(xl) {
            height: auto !important;
        }
    }

    
    .career-card-body {
        overflow-y: auto;
        padding: 1rem 1.25rem;

        @include media-breakpoint-up(md) {
            height: 343px;

            @at-root {
                .employment-outlook-card-height {
                    height: 330px;
                }
            }
        }

        @include custom-device(768px, 991px) {
            height: 350px;
        }

        @at-root {
            .list {
                padding-left: 30px;

                a {
                    color: $white;
                    text-decoration: underline;
                }

                li {
                    margin-bottom: 10px;
                    list-style: none;
                    margin-left: 8px;
                    &:before {
                        content: '\f054';
                        font-family: 'Fontawesome'; //$fa-style-family'';
                        font-size: $font-sizes-1;
                        float: left;
                        margin: 2.5px 0px 0 -10px;
                    }
                }
            }

            .sub-list {
                padding: 0 18px;

                li {
                    margin-bottom: 10px;
                    list-style: none;
                    display: flex;

                    p {
                        margin-left: 10px;
                        width: 90%;
                        padding: 0;
                        margin-bottom: 0;
                    }

                    &:before {
                        content: '\f00c';
                        font-family: 'Fontawesome';
                        font-size: $font-sizes-1;
                        margin: 2px 0px;
                    }
                }
            }

            .headding-arrow {
                &:before {
                    content: '\f054';
                    font-family: 'Fontawesome';
                    font-size: $font-sizes-1;
                    margin: 3px 5px;
                    min-height: 45px;
                }
            }

            .headding-arrow-align {
                position: relative;
                right: calc(100% - 98%)
            }
        }
    }

    .card-block2-p-plp3 {
        @include width-height(100%, 199px);

        @include custom-device(768px, 991px) {
            @include width-height(100%, 185px);
        }

        @include media-breakpoint-down(sm) {
            height: auto;
        }

        @include common-header($white, inherit, center, inherit);
        padding-top: 15px;
        text-decoration: none;

        &.emerging-blueCard {
            height: 165px;
        }

        &.pointer-events-none {
            cursor: context-menu !important;
        }

        i {
            font-size: $font-sizes-15;
            color: $white
        }

        i.font-default {
            font-size: $font-sizes-5;
        }

        a {
            text-decoration: none;
        }

        .career-cluster-panel {
            display: table;

            .career-cluster-icon {
                width: 25%;
                text-align: center;
                display: table-cell;
                vertical-align: middle;
            }

            .career-cluster-icon {
                width: 100%;
                display: block;
            }

            .career-cluster-text {
                text-align: center;
                font-size: 13px;
            }
        }

        &.schools-career-card {
            @include media-breakpoint-up(lg) {
                height: 408px;
            }

            @include media-breakpoint-only(md) {
                height: 415px;
            }
        }
    }

    .school-career-card {
        height: 231px;

        @include media-breakpoint-down(md) {
            height: 235px;
        }
    }

    .career-card-ataglance {
        height: 282px;
    }

    .education-card-height {
        @include media-breakpoint-up(md) {
            height: 408px;
        }
    }

    .cluster-noun-text {
        i {
            font-size: $font-size-base;
        }

        .font-large {
            font-size: $font-sizes-15 !important;
        }

        &.larg-height {
            @include custom-device(768px, 991px) {
                height: 415px !important;
            }

            @media (min-width:992px) {
                height: 395px !important;
            }
        }

        @include custom-device(768px, 991px) {
            height: 220px;

            h2 {
                font-size: 1.42rem;
            }

            h2,
            ol {
                margin-bottom: 0;
            }
        }
    }

    .panal-icon-align {
        margin-right: 10px;
    }

    .Careercluster-at-glance {
        height: 195px;
    }

    .cluster-at-glance {
        height: 408px;

        @include media-breakpoint-only(md) {
            height: 415px;
        }

        img {
            width: 120px;
        }

        .cluster-icn {
            font-size: $font-sizes-16;
        }

        .cluster-icn-div-two {
            font-size: $font-sizes-17;
            height: 50%;
            width: 100%;
            padding: 0 5px;
            text-align: center;
            background-color: nth($green-shades, 13);

            i {
                font-size: 70px;
            }
        }

        .bg-opacity {
            background: $black;
            opacity: 0.3;
            height: 197px;
        }

        .bg-opacity-text {
            background-color: nth($green-shades, 46);
            padding: 0 15px;
            text-align: center;
            height: 50%;
            display: flex;
            align-items: center;
            font-weight: bold;

            img {
                width: 70px;
            }

            p {
                text-align: center;
            }
        }

        @media (max-width:767px) {
            height: auto;

            .cluster-icn,
            &.card-block {
                padding: 0;
            }
        }
    }

    .career-card-block {
        width: 130px;
        margin: auto;

        @include media-breakpoint-up(lg) {
            width: 50%;
        }

        @include media-breakpoint-only(md) {
            width: 75%;
        }
    }
}

.career-jr {
    .career-card {
        .card-block2-p-plp3 {
            height: 200px;

            @include media-breakpoint-only(md) {
                height: 172px;
            }
        }
    }
}

.flex-row-cards.row {
    display: flex;
    flex-wrap: wrap;
}

.flex-row-cards.row>[class*='col-'] {
    display: flex;
    flex-direction: column;
}

.flex-row-cards.row:after,
.flex-row.row:before {
    display: flex;
}

.flex-row-cards .cards-equal-height {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.top-five-states {
    background: url('/../assets/images/map-plp3.jpg') repeat !important;
    height: auto;
    padding: 20px 4px;

    .header_6 {
        color: yellow;
        padding: 10px;
    }

    .loc-button-color {
        padding: 10px;
        @include common-border(2px,
            2px,
            2px,
            2px,
            $white);
        color: $white;
        border-radius: 5px;
        min-height: 54px;
        line-height: 1;

        @include media-breakpoint-down(sm) {
            margin: 5px 0px !important;
        }
    }
}

/**** At a glance Start ***/

.career-ataglance {
    ul {
        color: nth($white-shades, 14);
        margin-bottom: 0;

        &.detail-list {
            li a {
                color: nth($white-shades, 14);
                text-decoration: underline;
            }
        }
    }

    @include custom-device(361px, 767px) {
        .text-center-graph {
            text-align: center;
        }
    }

    .graph-card {
        color: nth($black-shades, 7);
        text-align: center;

        ul:nth-child(2) {
            height: 0px;
        }

        .card-block {
            box-shadow: 0 0 3px 1px nth($grey-shades, 8);
            .dropdown-toggle {
                width: 220px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                position: relative;
                text-align: left;
                &::after {
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    transform: translateY(-50%);
                    border-top: 6px solid;
                    border-right: 5px solid transparent;
                    border-left: 5px solid transparent;
                }
            }
            .dropdown-menu {
                top: auto;
                left: 20px;

                @include media-breakpoint-down(xs) {
                    left: 0;
                    right: 0;
                    width: 190px;
                    margin: 0 auto;
                }
            }

            .chart-skills {
                margin-left: auto;

                @include media-breakpoint-down(xs) {
                    margin-right: auto;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            .card-block {
                height: 130px;
                align-items: center;
                justify-content: center;

                &.career-glance-box {
                    height: 175px;
                }
            }
        }

        .education-card {
            height: 136px;
        }

        .educationjr-card {
            height: 136px;
        }

        .card-block-print {
            height: 140px;
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 10px;

            &.career-glance-box-print {
                height: 175px;
            }
        }

        .wages-text {
            margin-left: -100px;
            text-indent: -118px;
        }

        .salary-graph {
            margin-left: 165px;
        }
    }

    .career-listed-disc {
        position: relative;

        li {
            list-style-type: none;
            font-size: $font-sizes-6;

            &::before {
                content: "";
                position: absolute;
                @include height-width(10px, 10px);
                background-color: nth($white-shades, 1);
                border-radius: 50px;
                left: 1em;
                margin-left: 9px;
                margin-top: 6px;
            }
        }
    }

    .salry-value {
        text-align: center;
        position: relative;
        font-size: $font-sizes-3;
        top: -30px;

        &.at-glance-salry-value {
            @include media-breakpoint-only(sm) {
                right: 0;
            }
        }

        @include media-breakpoint-only(sm) {
            right: -50px;
        }

        @media (min-width: 768px) {
            position: absolute;
            top: 60px;
                right: 70px;
        }
    }

    .salary-count {
        position: relative;
        top: 80px;
    }

    .career-cluster-boxs .card-block {
        @media (min-width: 768px) {
            padding: 5px 20px;

            h5 {
                font-size: 1rem;
                font-weight: bold;
            }
        }

        .pointer {
            color: $white;
            word-break: break-word;
        }
    }

    .et-graph-card {
        i {
            position: relative;
            top: -45px;
            font-size: $font-size-h2  !important;
            padding: 0 10px;

            @include custom-device(767px, 1199px) {
                padding: 0;
            }

            @media (max-width:384px) {
                padding: 0;
            }
        }

        .headding {
            position: absolute;
            top: 10px;
        }

        .edu-name-style {
            top: 130px;
        }

        .stacked-bar-graph span {
            background: $white;
        }
    }
}

.general-info-print {
    height: 136px;
}
/***** At a glance End ***/

/**** Path Way Start ***/

.pathway-card-bg-color:nth-child(2n+1) .inner-card {
    background-color: nth($blue-shades, 26) !important;
}

.pathway-card-bg-color:nth-child(2n+2) .inner-card {
    background-color: nth($green-shades, 12) !important;
}
/**** Path Way End

* Personal Qualities Start ***/

.personal-qualities {
    .career-card-header {
        justify-content: start;
        height: 75px;
    }

    .pq-img-card {
        padding: 40px 0px;
        background-color: $white;

        .list li,
        a {
            color: nth($green-shades, 20);
        }
    }

    .pichart-align-plp3 ul {
        width: 100%;
        margin: auto;
        text-align: center;
        display: table-cell;
        padding: 0px 0px 10px;
    }

    .cards-font {

        @include custom-device(990px,
            1200px) {
            font-size: $font-sizes-3;
        }
    }

    .pq-list a {
        display: flex;
        align-items: center;

        .pq-list-circle {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
        }

    }
}

/**** Personal Qualities End ***/

/**** Related careers Start ***/

.findjobs-text {
    a {
        color: nth($green-shades, 20);

        &:hover {
            color: nth($green-shades, 20);
        }
    }
}
/**** Related careers end

*Employment Outlook Start
***/

.employment-outlook {
    .location-box {
        background-color: nth($green-shades, 16);
        min-height: 100px;
        color: nth($white-shades, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        @include media-breakpoint-down(sm) {
            height: 40px;
        }

        @include media-breakpoint-only(md) {
            font-size: 14px;
        }
    }

    .substate {
        background-color: nth($subState, 1);
    }

    .location-card {
        border: 1px solid nth($grey-shades, 8);
        background-color: $white;
        color: $black;
        padding: 9px;

        .location-heading {
            height: 60px;
        }

        .employee-states {
            font-size: $font-sizes-2;

            .box-status-text {
                text-align: left;
                padding-left: 15px;
            }

            .box-avg-text {
                text-align: right;
                float: right;
                padding-right: 10px;
            }
        }
    }
}
/**** Employment Outlook End ***/

/**** Wages Start ***/

.career-wages {
    .wages-row-common {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -12px;
        margin-left: -12px;
    }

    .content-wages-body {
        @include common-border(2px,
            2px,
            2px,
            2px,
            nth($grey-shades, 8));
        margin-top: -1px;
        border-radius: 4px;
        height: auto;
        overflow: auto;
        padding: 10px 23px;
        margin-bottom: 20px;
    }

    .wages-row-bg1 {
        background: nth($green-shades, 22);
        @extend .wages-row-common;
    }

    .wages-row-bg2 {
        background: nth($green-shades, 23);
        @extend .wages-row-common;
    }

    .wages-table .wages-table-header-bg,
    .main-table-row-header,
    .wages-row-bg1,
    .wages-row-bg2 {
        color: $white;
        padding: 12px;
    }

    .wages-table-header-bg {
        background: nth($green-shades, 17);
        min-height: 50px;
        font-weight: $font-bold;
    }

    .main-table-row-header {
        @include media-breakpoint-up(md) {
            font-size: $font-sizes-9 !important;
        }

        line-height: 24px !important;
    }

    .main-table-row-header {
        background: nth($green-shades, 24);
        @include common-border(0,
            0,
            1px,
            0,
            nth($green-shades, 22));
    }

    .word-wrap {
        word-wrap: break-word;
    }

    @include media-breakpoint-down(sm) {
        .wages-tab-mobile {
            right: -15px !important;
            left: -15px !important;
        }

        .wages-table-header-bg .text-center,
        .location-wag-mob {
            padding: 0px !important;
        }

        .main-table-row-header,
        .wages-row-bg1,
        .wages-row-bg2,
        .wages-table .wages-table-header-bg {
            padding: 7px 17px 7px 7px;
            font-size: $font-sizes-2;

            @media(max-width:350px) {
                padding: 0px 16px 0px 3px;
            }
        }

        .main-table-row-header {
            @include media-breakpoint-up(md) {
                font-size: $font-sizes-9 !important;
            }
        }

        #wages .tab {
            margin-top: 3px;
        }
    }
}

.wages-print-text {
    @include media-breakpoint-down(sm) {
        font-size: $font-sizes-5 !important;
    }
}

.wages-location {
    @include media-breakpoint-down(sm) {
        word-wrap: break-word;
        padding-left: 2px;
        padding-right: 7px;
    }

    padding-left: 0px;
}

/**** Wages End ***/


/********************** Start Compare Two Careers ****************/

.cmpr-graph-card-plp3 {
    margin-bottom: 10px;

    .cmpr-h-grp-mb {
        padding: 0px;
        @include height-width(70%,
            110px);
    }
}

@include custom-device(none,
    360px) {
    .cmpr-graph-card-plp3 .cmpr-h-grp-mb {
        width: 90%;
    }
}

.wages-table {
    color: $white;

    .table {
        text-align: center;
    }

    th,
    td {
        border: none !important;
    }

    thead th {
        text-align: center;
    }

    tbody tr {
        background: nth($green-shades, 13) !important;
        text-align: center;
    }
}

.table.wages-table>thead>tr>th {
    border: none !important;
}

.cmpir-2-btn-plp3 {
    background-color: nth($blue-shades, 11);
    @include common-header($white, $font-sizes-5, center, none);
    margin: 0 0 5px 0;
    padding: 10px 0;

    @include media-breakpoint-down(sm) {
        margin: 0;
        border-bottom: 1px solid nth($white-shades, 1);

        &:last-child {
            border-bottom: none;
        }
    }
}

.cmpr-wages-plp3 {
    @include common-border(1px,
        1px,
        1px,
        1px,
        nth($grey-shades, 8));
    margin-bottom: 10px;
}

.hideContainer1 {
    @include media-breakpoint-down(sm) {
        display: block !important;
    }

    @include media-breakpoint-up(sm) {
        display: none !important;
    }
}

.wages-card-header {
    background: rgba(0, 0, 0, 0.1);
    color: $white;
    padding: 11px 11px 14px !important;
    vertical-align: middle;
}

.bgColr-cmpr:nth-child(odd) {
    background-color: rgba(0, 0, 0, .1) !important;
}

.bgColr-cmpr p,
.bgColr-cmpr {
    margin-bottom: 0px !important;
    padding: 5px 11px !important;
}

.wag-cmpr-mob-hide {
    display: none;
}

.cmpr-img-weather img {
    width: 44px;
    margin: 0 0 0 -40px;
}

.two-headdings {
    &.container {
        padding-left: 0;
        padding-right: 0;

        @media (max-width: 575px) {
            width: 100%;
            max-width: 100%;
        }
    }
}

.compare-titles {
    @include media-breakpoint-up(md) {
        display: flex;

        .cmpir-2-btn-plp3 {
            &:first-child {
                margin-right: 10px;
            }
        }
    }
}

/*********************End Compare Two Careers****************/


/*********************Start Reality Check ****************/

.Rality-check {



    a:focus,
    a:hover {
        color: $white;
    }

    .panel-default .list-group-item.active {
        color: nth($green-shades, 20);
        background-color: nth($white-shades, 6);
        border-color: nth($white-shades, 6);

    }

    .modal-dialog {
        max-width: 100% !important;
    }


    .modal-open .modal {
        display: flex !important;
        align-items: center;
    }




}


/*********************End Reality Check ****************/

/********************* school start *********************/
.school-tabs {
    @include media-breakpoint-up (md) {
        .center-piechart-mt {
            margin-top: 56px;
        }
    }

    @media (min-width: 575px) and (max-width: 767px) {
        .justify-content-flex-end {
            justify-content: flex-end;
        }
    }

    .common-button {
        margin-bottom: 0;
    }

    .common-radio-btn .radio-button-custom:checked+.radio-custom-label:before {
        background: nth($blue-shades, 3) !important;
        box-shadow: inset 0px 0px 0px 4px $white  !important;
    }


    .common-radio-btn .radio-button-custom+.radio-custom-label:before {
        border: solid nth($blue-shades, 3) !important;
    }


    .program-of-study {
        @include media-breakpoint-up(md) {
            .card-block {
                height: 145px !important;
            }
        }
    }

    .top-cards-img {
        overflow: hidden;

        img {
            width: 100%;
            position: relative;
            height: 150px;
        }
    }

    .top-cards-img-print {
        overflow: hidden;

        img {
            width: 100%;
            position: relative;
            height: 131px;
        }
    }

    .list-unstyled .square-box {
        border-radius: 0px;
        margin-right: 10px;
    }

    @media (max-width: 490px) {
        #wages .nav-link {
            padding: 6px 2px;
            margin-left: 3px;
            font-size: $font-sizes-2;
            height: 100%;
            display: flex;
            align-items: center;

            &.active {
                margin-top: 0;
            }
        }

        .career-wages .content-wages-body {
            padding: 10px 5px;
        }
    }

    .span-text {
        margin-left: 17px;
    }

    .card-block {
        .graph-text-card {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            .div-with {
                width: 150px;
            }

            .fa-check:before {
                color: nth($pink-shades, 10);

            }
        }
    }

    .degrees-offered-list {
        @include media-breakpoint-down(md) {
            height: inherit !important; //height is set to be decreased/zero at mobile responsive look. So that's the reason we have used "!important" to overcome this issue.
        }

        &.scroll-class {
            overflow-y: auto;
            overflow-x: hidden;
        }

        &.scroll-class::-webkit-scrollbar {
            width: 10px;
            border-radius: 20px;
        }

        &.scroll-class::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 20px;
        }

        &.scroll-class::-webkit-scrollbar-thumb {
            width: 10px;
            background: #888;
            border-radius: 10px;
        }
    }

    .two-column-list {
        column-count: 2;
        text-align: left;
    }

    .school-sizelist {
        list-style: none;
        font-size: $font-sizes-3;
        color: nth($orange-shades, 11);
        font-weight: bold;
        display: flex;

        .square-circle {
            margin-right: 3px;

            &::before {
                content: "";
                @include height-width(10px, 10px);
                background-color: nth($pink-shades, 3);
                border-radius: 50%;
                margin: 3px;
                display: inline-block;
            }
        }

        .light-pink {
            background-color: nth($pink-shades, 7);
        }

        &.degrees-sizelist {
            color: nth($pink-shades, 11);
        }
    }


    .square-box {
        border-radius: 9px !important;

    }

    .visit-btn {
        border-radius: 50%;
        @include height-width(65px, 65px);
        background-color: nth($yellow-shades, 1);
        text-decoration: underline;
        display: flex;
        align-items: center;
        justify-content: center;
        color: nth($blue-shades, 6);
        margin: auto;

        a {
            color: nth($blue-shades, 20) !important;
        }
    }

    .school-calender-set {
        @media (max-width:767px) {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .school-calender {
            border-radius: 10px;
            background-color: nth($grey-shades, 8);
            width: 75px;
            overflow: hidden;
            margin: 10px;

            .month {
                padding: 4px 2px 0 2px;
                background-color: nth($brown-shades, 1);
                color: $white;
                border-bottom: 5px solid nth($green-shades, 13);
                text-align: center;
                font-size: $font-sizes-1;
                font-weight: bold;
            }

            .date {
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $font-sizes-9;
                color: nth($brown-shades, 1);
                font-weight: bold;
            }
        }
    }

    .school-card-headding {
        font-size: $font-sizes-10;
        font-weight: bold;
        word-break: break-word;

        &.pink {
            color: nth($pink-shades, 11);
        }

        &.skyblue {
            color: nth($green-shades, 20);
        }

        &.orange {
            color: nth($orange-shades, 11);
        }

        a {
            color: nth($green-shades, 20) !important;
            font-size: $font-sizes-5;
        }

        span {
            font-size: $font-sizes-5;
        }

        &.specificCard {
            font-size: $font-sizes-5;

        }
    }

    .card-career {

        .school-img-icon {
            width: 50px;
        }

        @include media-breakpoint-only(md) {
            height: 170px;
        }

        @include media-breakpoint-up(lg) {
            height: 166.5px;
        }


    }

    .degrees-replacement-card {
        height: 235px;
        width: 100%;

        @include media-breakpoint-only(lg) {
            height: 231px;
        }

        @include media-breakpoint-up(xl) {
            height: 232px;
        }
    }

    .horizontal-bar {
        height: 15px;
        background-color: nth($grey-shades, 8);
        margin: 5px 0;

        .bar {
            @include height-width(50%, 100%);
            background-color: nth($green-shades, 13);
        }
    }

    /***   School General info end   ***/


    /***   School Student Life start   ***/
    .alter-colors:nth-child(even) {
        background-color: nth($brown-shades, 1);
    }

    .alter-colors:nth-child(odd) {
        background-color: nth($red-shades, 7);
    }

    .list-table {
        border: 1px solid $white;
        border-radius: 4px;
        padding: 0 15px;
        font-size: $font-sizes-4;

        .list-head {
            background-color: rgba(0, 0, 0, 0.47);
            word-break: break-word;
        }

        .alternative-list-color {
            .sub-list-color {
                min-height: 30px;

                .col:first-child {
                    text-align: left !important;
                }
            }

            &:nth-child(odd) .sport-sub-list-color {
                background-color: lighten(#dd7c09, 20%);
            }

            &:nth-child(odd) .sub-list-color {
                background-color: rgba(0, 0, 0, 0.20);
            }
        }

        .alternative-list-name {
            @include custom-device(320px, 480px) {
                padding: 0;
            }
        }

        .sport-head {
            background-color: nth($orange-shades, 6);
            color: $white;
        }
    }

    .border-yellow {
        border: 1px solid nth($orange-shades, 6);
    }

    .list-table-border {
        .list-border {
            min-height: 30px;
        }

        .list-border .bottom-border {
            border-bottom: 1px solid $white;
        }

        .list-border {
            .bottom-border-grey {
                border-bottom: 1px solid nth($white-shades, 7);
            }
        }
    }

    .score-section {
        position: relative;

        .canvas-chart {
            height: 100px !important;
            width: 100px !important;

            @include media-breakpoint-only(lg) {
                height: 80px !important;
                width: 80px !important;
            }
        }
    }

    .canvas-size-increase {
        .canvas-chart {
            height: 150px !important;
            width: 150px !important;

            @media (max-width:767px) {
                height: 80px !important;
                width: 80px !important;
            }
        }
    }

    .canvas-chart2 {

        @include media-breakpoint-up(md) {
            position: relative;
            right: 0;
            top: 0px;
            left: 24px;
        }

        @include media-breakpoint-up(lg) {
            left: 65px;
        }

        @include media-breakpoint-down(sm) {
            position: absolute;
            right: 15px;
            top: 30px;
        }
    }

    .canvas-chart2-print {
        position: absolute;
        right: 15px;
        top: 30px;
    }

    .school-size-chart {
        @media only screen and (min-width: 992px) {
            left: 16px;
        }

        @media only screen and (max-width: 991px) {
            left: 0;
        }
    }

    .school-size-chart-print {
        right: 0.2em;
    }

    .order-first {
        -ms-flex-order: -1;
        order: -1;
    }

    .order-middle {
        -ms-flex-order: 5;
        order: 5;
    }

    .order-last {
        -ms-flex-order: 9;
        order: 9;
    }

}

.custom-image-size {
    width: 100%;
    height: 408px;
    justify-content: center;
    position: relative;

    .career-card-header {
        &.tution-card {
            position: absolute;
            top: 0;
        }
    }

    @include media-breakpoint-only (md) {
        height: 415px;
    }

    &.program-of-study-card-image {
        height: 540px;
    }
}

.school-text {
    min-width: 135px;
}

.school-filter-list {
    display: flex;
    flex-wrap: wrap;

    li {
        flex: 0 50%;

        @include media-breakpoint-up(md) {
            flex: 0 100%;
        }

        @include media-breakpoint-up(xl) {
            flex: 0 50%;
        }
    }
}

.emerging-footer {
    position: absolute;
    left: calc(100% - 93%);
    width: 86%;
    bottom: 0;
}

.emerging-preparationList ul li {
    line-height: 23px;
}

.financial-close-data {
    column-count: 2;

    ul {
        width: 100%;
        display: inline-block;
    }

    @include media-breakpoint-only(md) {
        column-count: 1;
    }
}

.financial-column-data {
    @include media-breakpoint-only(md) {
        .financial-close-data {
            column-count: 2;
        }
    }
}

/********************* school end *********************/

/********************* MilitaryCareer start *********************/
.milatry-text {
    font-size: $font-sizes-5;
}

.milatry-text b {
    color: #d4a00f;
    font-size: $font-sizes-6;
}

.milatary-career-card {
    .card-block {
        width: 135px;
        margin: auto;
    }

    @include media-breakpoint-up(md) {
        height: 118px;
    }

    @include media-breakpoint-up(lg) {
        height: 145px;
    }
}

.milatry-work {
    height: 40px;

    img {
        width: 40px;
        height: 40px;
    }
}

@media (min-width: 319px) and (max-width: 575px) {
    .milatry-work span {
        padding-left: 0.25rem !important;
        font-size: $font-sizes-4 !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .milatry-work {
        font-size: $font-sizes-2 !important;
    }
}

.milatry-card .list {
    overflow: hidden;
}

/********************* 
MilitaryCareer end 
*********************/
/*********************
scholarship card start
*********************/
.scholarship-card {
    .scholar-head {
        position: relative;
        top: 5px;
    }
}

/********************* 
scholarship card end
*********************/

.list-print li ::before {
    content: '\f054';
    font-family: 'Fontawesome';
    float: left;
    font-size: $font-sizes-1;
    margin: 2.5px 5px 0 0;
}

.milatry-cardWidth {
    max-width: 49.2%;
}

//rc tooltips alignment
.tooltip-md {
    position: relative;

    &+.tooltip {
        width: 200px !important;
        position: absolute !important;
        left: 0px !important;
    }
}

.dropDown-alignment {
    min-width: 130px;

    .dropdown-item {
        padding: 3px 14px
    }
}

.card-emergin {
    height: 50px;
}

.careerjr-popup {
    max-height: 350px;
    height: 100%;

    h5 {
        font-size: 22px;
    }

    h6 {
        line-height: 1.3;
        font-size: 16px;
    }
}

.emerging-model {
    color: nth($purple-shades, 2);
    width: 80%;
    margin: auto;
}

.checkIcon {
    width: 22px;
    padding: 0px 4px;
}

.activities {
    width: 30px;
}

.career-jr {
    .career-card {
        @include media-breakpoint-up(md) {

            .cluster-at-glance,
            .education-card-height {
                height: 386px;
            }
        }

        @include media-breakpoint-up(lg) {

            .cluster-at-glance,
            .education-card-height {
                height: 414px;
            }
        }

        @media (max-width: 767px) {
            margin-left: 6px;
            margin-right: 6px;
        }

        .card-block img {
            width: 50px;
            height: 50px;
        }

        @include custom-device(768px, 991px) {
            .cluster-noun-text {
                height: 200px;

                ol {
                    max-height: 120px;
                    overflow-y: auto;
                    overflow-x: hidden;
                }
            }

        }

    }

    .career-plp-card {
        height: 400px;
    }

    .text-more {
        color: white;
        text-decoration: underline;
        font-weight: 500;
    }

    .check-icon {
        width: 20px;
    }
}

.careerCluster-icn-div-two {
    font-size: 36px;
}

.wagesprint {
    margin-top: 20px;
}

.wagesprint-header {
    margin-top: 20px;
}

//helping Styles
.lineheight-21 {
    line-height: 21px;
}

@include media-breakpoint-down(sm) {
    .career-text-block {
        display: block;
    }
}

.milatary-career {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em;

    @include media-breakpoint-up(lg) {
        height: 253px;
    }

    &.card-block {
        @include media-breakpoint-up(lg) {
            padding: 0 30px !important; // Style for only support milatary carrer box inside gap
        }
    }

    p {
        font-size: $font-sizes-5;
        margin-bottom: 10px;
    }
}

.content-break {
    word-break: break-word;
}

.h-424 {
    height: 424px;
}

.text-break {
    word-break: break-all;
}

.content-break {
    word-wrap: break-word;
}
/*
new classes for career details view

*/
.atGlanceBox {
    height: 130px;
    box-shadow: 0 0 3px 1px #ccc;
}

.educationTraining {
    @include media-breakpoint-down(md) {
        height: 200px;
    }
}

.careerWage {
    @include media-breakpoint-down(md) {
        height: 170px;
    }
}
.fa-check-circle-o {
    color: green !important;
}