.resume-builder{
    #ResumeContainer {
        width: 100%;
        max-width: 833px;
        background: white;
        margin: 30px auto;
        box-shadow: 0 0 10px #333;
        padding: 30px;
        font-size: 14px;
        font-family: arial, sans-serif;
        font-weight: normal;
        line-height: 1.5;
    }

    .resume-wrapper {
        width: 100%;   
        background: white;       
        font-size: 14px;
        font-family: arial, sans-serif;
        font-weight: normal;
        line-height: 1.5;
        color: #424242;
    }

    .clear-both {
        clear: both;
    }
    
    .form-group {
        margin-bottom: 1rem;
    }

    p {
        margin-top: 0;
        /* margin-bottom: 1rem; */
    }

    h1, .h1 {
        font-size: 2.5rem;
    }

    h1 {    
        margin: 0.67em 0;
    }

    h2, .h2 {
        font-size: 2rem; 
    }

    h3, .h3 {
        font-size: 1.75rem;
    }

    h1, h2, h3, h4, h5, h6 {
        margin-top: 0;
        margin-bottom: .5rem;
    }

    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        margin-bottom: 0.5rem;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.1;
    }

    /*
    ################################################
            Chronological Basic
    ################################################
    */

    #heading.chronological-basic {
        padding-bottom: 10px;
        line-height: 1.5;
        font-size: 15px;
    }
    .chronological-basic {
        font-size: 14px;
        font-family: arial, sans-serif;
        font-weight: normal;
        line-height: 1.5;
    }
    .chronological-basic h1 {
        padding-top: .6em;
        padding-left: 0;
        font-size: 30px !important;
        font-weight: bold;
        line-height: 1;
    }
    .chronological-basic h2 {
        font-size: 20px;
        font-weight: bold;
        padding: 0;
        text-decoration: underline;
    }
    .chronological-basic h3 {
        font-size: 18px;
        font-weight: bold;
        padding-top: 10px;
    }
    .chronological-basic h4 {
        font-size: 16px;
        font-weight: bold;
        margin: 10px 45px 5px 0;
        margin-top: 10px;
    }
    .chronological-basic hr {
        border-top: 2px solid #000;
        margin: 0;
    }
    .chronological-basic p {
    display: inline-block; 
    padding-top: 4px;
    margin-bottom: 0 !important;
    }
    .rc-vertical-bar {
    padding: 8px;
    font-size: 18px;
    } 
    .chronological-basic .answers ul {
        padding-left: 30px;
    } 
    .chronological-basic .position-title {
        float: left;
    }
    .chronological-basic .location {        
        margin: 0; 
    }
    .chronological-basic .text-area-value ul {    
        display: block;
        padding-left: 30px;        
    }
    // .chronological-basic .degree-program {
    //     margin-left: 20px;
    // }
    .chronological-basic .education-name {
        float: left;
    }
    .chronological-basic .title-org-loc {
        // float: left;
        position: relative;
        width: 100%;
    }
    .chronological-basic .date-range {
        position: absolute;
        right: 0;
    }
    .chronological-basic .date-awarded {    
        float: left;
    }
    .chronological-basic .license-date {
    display: inline;
    }
    
    // .chronological-basic .license-name {
    //     float: left;
    //     padding-left: 45px;
    //        }
    
    //     .chronological-basic .licensing-body {
    //     padding-left: 45px;
    //     }
    .website {
        display: block;
    }

    /*
    ################################################ 
            Chronological Contemporary 
    ################################################
    */

    #heading.chronological-contemporary {
        text-align: right;
        padding-bottom: 30px;
    }

    .chronological-contemporary {
        font-size: 14px;
        font-family: arial, sans-serif;
        font-weight: normal;
        line-height: 1.5;
    }

    .chronological-contemporary h1 {
        padding-top: .6em;
        padding-left: 0;
        font-size: 2.1em;
        font-weight: bold;
        line-height: 1;
    }

    .chronological-contemporary h2 {
        font-size: 20px;
        font-weight: bold;
        margin-left: 1em;
        padding: 8px 0 0 0;
        float: left;
        margin-left: 0;
    }

    .chronological-contemporary h3 {
        font-size: 16px;
        font-weight: bold;
        padding-left: 30%;
        padding-top: 10px;
    }

    .chronological-contemporary h4 {
        font-size: 16px;
        font-weight: bold;
        margin-left: 30%;
        margin-top: 10px;

    }

    .chronological-contemporary hr {
        border-top: 2px solid #000;
        margin: 0;
    }

    .chronological-contemporary p {
        display: inline-block;
        padding-top: 4px;
        margin-bottom: 0 !important;
    }

    .rc-vertical-bar {
        padding: 8px;
        font-size: 18px;
    }
    .chronological-contemporary ul {
        padding-left: 30px;
    }
    .chronological-contemporary .answers {
        padding: 0 0 0 30%;
    }
    .chronological-contemporary .position-title {
        float: left;
    }
    .chronological-contemporary .location {    
        float: left;
        margin: 0;
    }
    .chronological-contemporary .text-area-value {
        margin: 5px 0 0 30%;
        margin-bottom: 10px;
    }
    .chronological-contemporary .text-area-value ul{
        margin-bottom: 10px;
    }
    // .chronological-contemporary .organization-name {
    //     float: left;
    // }
    .chronological-contemporary .education-name {
        float: left;
        clear: both;
    }
    .chronological-contemporary .degree-program {
        margin-left: 30%;
    }
    .chronological-contemporary .date-range {
        // text-align: right;
    }
    .chronological-contemporary .date-awarded { 
        float: left;
        margin-left: 30%;
    }
    .chronological-contemporary .title-org-loc {
    margin-left: 30%;
    // float: left;
    clear: both;
    }
    .chronological-contemporary .license-date {
    display: inline;
    }
    .chronological-contemporary .license-name {  
        // float: left;
        // margin-left: 30%;
        width: 70%;
    }
    .chronological-contemporary .licensing-body {
    padding-left: 30%;
    clear: both;
    }

    .website {
        display: block;
    }

    /*
    ################################################
            FunctionalBasic 
    ################################################
    */

    #heading.functional-basic {
        padding-bottom: 30px;
    }
    .functional-basic {
        font-size: 14px;
        font-family: arial, sans-serif;
        font-weight: normal;
        line-height: 1.5;
    }
    .functional-basic h1 {
    padding-top: .6em;
    padding-left: 0;
    line-height: 1;
    }
    .functional-basic h2 {
        font-size: 20px;
        font-weight: bold;
        padding: 0 0 0 0;    
        text-decoration: underline;
    }
    .functional-basic h3 {
        font-size: 18px;
        font-weight: bold;
        padding-top: 10px;
    }
    .functional-basic h4 {
        font-size: 16px;
        font-weight: bold;
        margin: 10px 45px 5px 0;
        margin-top: 10px;
    }
    .functional-basic hr {
        border-top: 2px solid #000;
        margin: 0;
    }
    .functional-basic p {
    display: inline-block; 
    padding-top: 4px;
    margin-bottom: 0 !important;
    }
    .rc-vertical-bar {
        padding: 8px;
        font-size: 18px;
    }

    .functional-basic .position-title {
        float: left;
    }
    .functional-basic .location {
        float: left;
        margin: 0;
    }
    .functional-basic .text-area-value {
        margin: 5px 30px 0 0;
    }
    .functional-basic .text-area-value ul{
        padding-left: 30px;
        margin-bottom: 10px;
    }
    .functional-basic .organization-name {    
        float: left;
    }
    .functional-basic .education-name {
        float: left;
    }
    .functional-basic  .degree-program {
        margin-left: 45px;
    }
    .functional-basic .date-range {
        text-align: right;
        margin-right: 3em;
    }
    .functional-basic .date-awarded {
        float: left;
    }
    .functional-basic .title-org-loc {
        float: left;
        width: 100%;
    }
    .functional-basic .license-date {
        display: inline;
    }

    .functional-basic .license-name {
        float: left;
        padding-left: 45px;
    }
    
    .functional-basic .licensing-body {
    padding-left: 45px;
    }

    .website {
        display: block;
    }

    /*
    ################################################ 
            FunctionalContemporary 
    ################################################
    */

    #heading.functional-contemporary {
        text-align: left;
        padding-bottom: 30px;
    }
    .functional-contemporary {
        font-size: 14px;
        font-family: arial, sans-serif;
        font-weight: normal;
        line-height: 1.5;
    }
    .functional-contemporary h1 {
        padding-top: .6em;
        padding-left: 0;
        font-size: 2.1em;
        font-weight: bold;
        line-height: 1;
    }
    .functional-contemporary h2 {
        font-size: 20px;
        font-weight: bold;
        margin-left: 1em;
        padding: 8px 0 0 0;
        float: left;
        margin-left: 0;
    }
    .functional-contemporary h3 {
        font-size: 16px;
        font-weight: bold;
        padding-left: 30%;
        text-decoration: underline;
        padding-top: 10px;
    }
    .functional-contemporary h4 {
        font-size: 16px;
        font-weight: bold;
        margin-left: 30%;
        margin-top: 10px;
    }
    .functional-contemporary hr {
        border-top: 2px solid #000;
        margin: 0;
    }
    .functional-contemporary p {
    display: inline-block; 
    padding-top: 4px;
    margin-bottom: 0 !important;
    }
    .rc-vertical-bar {
        padding: 8px;
        font-size: 18px;
    }
    .functional-contemporary .answers {
        padding: 0 0 0 30%;
    }
    .functional-contemporary ul{
        padding-left: 30px;
    }
    .functional-contemporary .position-title {    
        float: left;
    }
    .functional-contemporary .location {    
        float: left;
        margin: 0;
    }
    .functional-contemporary .text-area-value {
        margin: 5px 30px 0 30%;
    }
    .functional-contemporary .organization-name {
        float: left;
    }
    .functional-contemporary .education-name {
        float: left;
        clear: both;
    }
    .functional-contemporary .title-org-loc {
        margin-left: 30%;
        float: left;
        clear: both;
        width: 75%;
    }
    .functional-contemporary .degree-program {
        margin-left: 30%;
    }
    .functional-contemporary .date-range {
        text-align: right;
        margin-right: 3em;
    }
    .functional-contemporary .date-awarded {    
        float: left;
        margin-left: 30%;
    }
    .functional-contemporary .license-date { 
    display: inline;
    }
    .functional-contemporary .license-name {
        float: left;
        margin-left: 30%;
        clear: both;
    }
    .functional-contemporary .licensing-body {
    padding-left: 30%;
    clear: both;
    }

    .website {
        display: block;
    }
}
.rc-validation {
    display: none !important;
  }
  .ql-snow.ql-toolbar button.ql-active .ql-stroke{
    stroke: #444 !important;
  }
  .ql-snow .ql-toolbar button:hover{
   stroke:#444 !important;
  }
  .hidden {
    display: none !important;
  }
  