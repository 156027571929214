.modal-dialog {
    width: 92%!important;
    @include media-breakpoint-down(sm) {
        margin: 10px auto;
 }
}
/****************************
* Tool-2 Tip End
****************************/
.tooltip {
    z-index: 999999 !important;
    @include media-breakpoint-down(sm) {
        display: none;
 }

}


.tooltip-inner {
    background: nth($blue-shades, 15);
    z-index: 999999;
}
// .factors-page {
//     .tooltip {
//         top: 0px!important;
//         transform: translate(-28px,0px) !important;
//     }
//     .tooltip-inner {
//         max-width: 220px!important;
//         width: 220px!important;
//         padding: 5px!important;
//         @include custom-device(767px, 992px) {
//             max-width: 140px!important;
//         }
//         &::before {
//             @include position(absolute, $top: 20px!important);
//         }
//     }
//     .factors-list li:nth-child(5) {
//         .tooltip {
//         transform: translate(-28px,10px) !important;
//         }
//     }
//     .factors-list li:nth-child(6) {
//         .tooltip {
//         transform: translate(-28px,10px) !important;
//         }
//     }
//     .factors-list li:nth-last-child(-n+6) {
//         .tooltip {
//             bottom: 0;
//             right: 0;
//             left: 13.8rem !important;
//         }
//         .tooltip-inner {
//             @include position(absolute, $bottom: 0px!important, $right: 55%);
//             &::before {
//                 @include position(none, $bottom: 18px!important, $right: -5px, $top: auto!important);
//             }
//         }
//     }
// }


/****************************
* Tool-2 Tip End
****************************/


/****************************
* Modal fo into pages start
****************************/

.intro-pages {
    .model-width {
        .modal-header {
          padding: 10px;
            @include common-border(0, 0px, 1px, 0, nth($grey-shades, 4));
            margin-bottom: 10px;
            .header_5 {
                margin-top: 7px!important;
                font-size: $font-sizes-6 !important;
            }
        }
        .modal-body {
            padding: 0px 15px;
            position: relative;
            width: $width-value;
            margin: 0px!important;
            button {
                color: $gray-light;
            }
            .intro-options-mobile {
                margin: 15px 0 0 0px;
                list-style: none;
                li {
                    padding: 5px 0;
                    vertical-align: middle;
                    i {
                        color: nth($orange-shades, 1);
                        display: table-cell;
                        font-size: $font-sizes-16;
                        vertical-align: middle;
                    }
                    .smileys-mobile-popup {
                        vertical-align: middle;
                        display: table-cell!important;
                        width: 95%!important;
                        @include common-border-shadow(0, 0px, 0px, 0px, white, 0px);
                        font-size: $font-sizes-3 !important;
                    }
                }
            }
        }
    }
}


/****************************
* Modal fo into end
****************************/


/****************************
* Modal for result cards
****************************/

.modal-color {
    background-color: $white!important;
    color: $black;
    .modal-header {
        color: $white;
        padding: 10px;
        @include common-individual-border-radius($top-left: 4px, $top-right: 4px);
    }
    .modal-header-eq {
        background-color: nth($green-shades, 12);
        display: flex;
        align-items: center;
        color: $white;
        padding: 10px;
        @include common-individual-border-radius($top-left: 4px, $top-right: 4px);
    }
    .modal-header-ae {
        background-color: nth($green-shades, 35);
        display: flex;
        align-items: center;
        color: $white;
        padding: 10px;
        @include common-individual-border-radius($top-left: 4px, $top-right: 4px);
    }
    .careerEmerModal {
        display: flex;
        align-items: center;
        color: $white;
        padding: 10px;
        @include common-individual-border-radius($top-left: 4px, $top-right: 4px);
        box-shadow: rgb(0 0 0 / 20%) 0px 31px 32px -12px;
    }
    .modal-width {
        width: 92%;
    }
    i {
        font-size: $font-sizes-10;
        cursor: pointer;
    }
    .modal-heading {
        border-bottom: 0;
    }
    .modal-card-title {
        width: 92%;
    }
    .modal-close {
        background: none;
        border: none;
        color: $white;
        cursor: pointer;
        font-size: $font-sizes-9;
    }
    .modalPopup-close{
        color:nth($purple-shades,2);
        font-weight: 600;
        position: relative;
        // bottom: 3px;
        line-height: initial;
    }
    .close-btn{
        background: nth($white-shades,1);
        width: 30px;
        height: 30px;
        border-radius: 50px;
        text-align: center;
        padding-bottom: 1px;
    }
    .card-data {
        padding: 2px 15px;
    }
    h6 {
        @include media-breakpoint-down(md) {
            margin: 0 0 0 4px;
        }
    }
}


/****************************
* End of Modal for result cards
****************************/


/*************** Print start*****************/

.eq-result-page,
.ip-wil-result-page,
.os-print-result {
    .print-page {
        max-width: 92%!important;
        .icon-text-align {
            position: relative;
            width: 30px;
            top: 5px;
            margin-right: 10px;
        }
        ol li {
            padding: 5px;
        }
    }
}


/*************** Print End*****************/


/***********snackbar***********/


/****************snackbar************/

#snackbar {
    visibility: hidden;
    // width: 97%; //  margin-left: -125px;
    background-color: #6fa301;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 16px;
    position: fixed;// Position and z-index values support to display the snackbar design on application screen at bottom place.
    z-index: 99999; //left: 90%;
    right: 20px;
    // left: 0;
    margin: auto;
    bottom: 90px;
    font-size: 17px;
    @include media-breakpoint-down(sm) {
        bottom: 15px;
        right: 15px;
        left: 15px;
    }
}
#snackbarred {
    visibility: hidden;
    min-width: 250px; //  margin-left: -125px;
    background-color:nth($red-shades,3);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 16px;
    position: fixed;// Position and z-index values support to display the snackbar design on application screen at bottom place.
    z-index: 99999; //left: 90%;
    right: 25px;
    bottom: 100px;
    font-size: 17px;
    @include media-breakpoint-down(sm) {
        bottom: 50px;
    }
}


/* Show the snackbar when clicking on a button (class added with JavaScript) */

#snackbar.show {
    visibility: visible;
    /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

#snackbarred.show {
    visibility: visible;
    /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */

@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: 100px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: 100px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 100px;
        opacity: 1;
    }
    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 100px;
        opacity: 1;
    }
    to {
        bottom: 0;
        opacity: 0;
    }
}


/*************/
/***** RC Assessment Popup *****/
.rc-assessment-popup{
    .modal-header{
        background-color: nth($blue-shades, 17) !important;
        color:nth($white-shades,1);
        .modal-title{
            font-size: $font-sizes-8;
        }
    }
    .modal-body p{
        color:nth($blue-shades,28);
        font-size: $font-sizes-8;
    }
    .modal-footer button{
        background-color: nth($blue-shades,28);
    }
}
/***** RC Assessment Popup End *****/