@font-face {
    font-family: "plpicons";
    src: url('./fonts/plpicons.eot');
    src: url('./fonts/plpicons.eot?#iefix') format('eot'), /* url('./fonts/plpicons.woff2') format('woff2'), */
    url('./fonts/plpicons.woff') format('woff'), url('./fonts/plpicons.ttf') format('truetype'), url('./fonts/plpicons.svg#plpicons') format('svg');
}
[class^="icon-"],
[class*=" icon-"] {
    font-family: "plpicons"!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
    text-decoration: none;
    text-transform: none;
	/* font-size:25px; */
	/* margin-top: -8px !important; */
}

.icon-ablityexplorer_menu_icon:before {
	content: "\E001";
}

.icon-academicservices:before {
	content: "\E002";
}

.icon-achievement-star-award-symbol:before {
	content: "\E003";
}

.icon-action-plan:before {
	content: "\E004";
}

.icon-activities:before {
	content: "\E005";
}

.icon-add:before {
	content: "\E006";
}

.icon-additionalServices:before {
	content: "\E007";
}

.icon-ageofstudents:before {
	content: "\E008";
}

.icon-aid-type:before {
	content: "\E009";
}

.icon-applicationdates:before {
	content: "\E00A";
}

.icon-applicationrequirements:before {
	content: "\E00B";
}

.icon-asmnt-assessment:before {
	content: "\E00C";
}

.icon-asmnt-back-arrow:before {
	content: "\E00D";
}

.icon-asmnt-between-somewhat:before {
	content: "\E00E";
}

.icon-asmnt-between-strongly:before {
	content: "\E00F";
}

.icon-asmnt-change-language:before {
	content: "\E010";
}

.icon-asmnt-cross:before {
	content: "\E011";
}

.icon-asmnt-date:before {
	content: "\E012";
}

.icon-asmnt-delete:before {
	content: "\E013";
}

.icon-asmnt-forward-arrow:before {
	margin-top: 5px;
	content: "\E014";
}

.icon-asmnt-head-icon:before {
	content: "\E015";
}

.icon-asmnt-language-change:before {
	content: "\E016";
}

.icon-asmnt-main-assessment:before {
	content: "\E017";
}

.icon-asmnt-menu:before {
	content: "\E018";
}

.icon-asmnt-my-result:before {
	content: "\E019";
}

.icon-asmnt-print:before {
	content: "\E01A";
}

.icon-asmnt-question-mark:before {
	content: "\E01B";
}

.icon-asmnt-restore-2:before {
	content: "\E01C";
}

.icon-asmnt-save:before {
	content: "\E01D";
}

.icon-asmnt-somewhat:before {
	content: "\E01E";
}

.icon-asmnt-star:before {
	content: "\E01F";
}

.icon-asmnt-start-over-reverse:before {
	content: "\E020";
}

.icon-asmnt-start-over:before {
	content: "\E021";
}

.icon-asmnt-strongly-agree:before {
	content: "\E022";
}

.icon-asmnt-strongly-disagree:before {
	content: "\E023";
}

.icon-asmnt-tick-mark:before {
	content: "\E024";
}

.icon-asmnt-un-sure:before {
	content: "\E025";
}

.icon-at-a-glance-education-training:before {
	content: "\E026";
}

.icon-athleticMembership:before {
	content: "\E027";
}

.icon-baby:before {
	content: "\E028";
}

.icon-back-left-arrow:before {
	content: "\E029";
}

.icon-bath-tub:before {
	content: "\E02A";
}

.icon-campussecurity:before {
	content: "\E02B";
}

.icon-cancel:before {
	content: "\E02C";
}

.icon-career-cluster:before {
	content: "\E02D";
}

.icon-career-clusters-interest:before {
	content: "\E02E";
}

.icon-career-goals:before {
	content: "\E02F";
}

.icon-careercluster_menu_icon:before {
	content: "\E030";
}

.icon-careers:before {
	content: "\E031";
}

.icon-careerServicesOffered:before {
	content: "\E032";
}

.icon-career_rediness_key:before {
	content: "\E033";
}

.icon-cciquickpic_menu_icon:before {
	content: "\E034";
}

.icon-chat:before {
	content: "\E035";
}

.icon-civilian_counterparts:before {
	content: "\E036";
}

.icon-clipboard:before {
	content: "\E037";
}

.icon-clus_ageofstudents:before {
	content: "\E038";
}

.icon-clus_aidBasedonEthnicorNationalBackground:before {
	content: "\E039";
}

.icon-clus_aidBasedonFinancialNeed:before {
	content: "\E03A";
}

.icon-clus_aidBasedonHobbiesTalentsandInterests:before {
	content: "\E03B";
}

.icon-clus_aidBasedonLeadership:before {
	content: "\E03C";
}

.icon-clus_aidBasedonRacialBackground:before {
	content: "\E03D";
}

.icon-clus_aidBasedonWorkorVolunteerExperience:before {
	content: "\E03E";
}

.icon-clus_aidforIndividualswithDisabilities:before {
	content: "\E03F";
}

.icon-clus_aidforMen:before {
	content: "\E040";
}

.icon-clus_aidforstudyinArchitectureandCommunityPlanning:before {
	content: "\E041";
}

.icon-clus_aidforstudyinBiomedicalSciences:before {
	content: "\E042";
}

.icon-clus_aidforstudyinComputerScienceandInformationTechnologies:before {
	content: "\E043";
}

.icon-clus_aidforstudyinCulturalandEthnicStudies:before {
	content: "\E044";
}

.icon-clus_aidforstudyinEngineering:before {
	content: "\E045";
}

.icon-clus_aidforstudyinEngineeringTechnologies:before {
	content: "\E046";
}

.icon-clus_aidforstudyinFamilyandConsumerSciences:before {
	content: "\E047";
}

.icon-clus_aidforstudyinHealthcareAdministration:before {
	content: "\E048";
}

.icon-clus_aidforstudyinInterdisciplinaryandGeneralStudies:before {
	content: "\E049";
}

.icon-clus_aidforstudyinLanguagesLiteratureandLinguistics:before {
	content: "\E04A";
}

.icon-clus_AidforstudyinMathematicsandStatistics:before {
	content: "\E04B";
}

.icon-clus_aidforstudyinMedicalTestingandLaboratoryTechnology:before {
	content: "\E04C";
}

.icon-clus_aidforstudyinMedicineDentistryPharmacyandVeterinary:before {
	content: "\E04D";
}

.icon-clus_aidforstudyinNaturalResources:before {
	content: "\E04E";
}

.icon-clus_aidforstudyinNursing:before {
	content: "\E04F";
}

.icon-clus_aidforstudyinPersonalServices:before {
	content: "\E050";
}

.icon-clus_aidforstudyinPhysicalSciences:before {
	content: "\E051";
}

.icon-clus_aidforstudyinPrecisionProduction:before {
	content: "\E052";
}

.icon-clus_aidforstudyinProtectiveServices:before {
	content: "\E053";
}

.icon-clus_aidforstudyinPsychology:before {
	content: "\E054";
}

.icon-clus_aidforstudyinSecretarialStudiesandOfficeManagement:before {
	content: "\E055";
}

.icon-clus_aidforstudyinSocialSciences:before {
	content: "\E056";
}

.icon-clus_aidforstudyinTheologyReligionandPhilosophy:before {
	content: "\E057";
}

.icon-clus_aidforstudyinTherapyandRehabilitationServices:before {
	content: "\E058";
}

.icon-clus_aidforstudyinTransportation:before {
	content: "\E059";
}

.icon-clus_aidforThoseintheMilitary:before {
	content: "\E05A";
}

.icon-clus_aidforWomen:before {
	content: "\E05B";
}

.icon-clus_aidRequiringAffiliationwithaBusiness:before {
	content: "\E05C";
}

.icon-clus_aidRequiringAffiliationwithaClubCivicAssoc:before {
	content: "\E05D";
}

.icon-clus_aidRequiringReligiousAffiliation:before {
	content: "\E05E";
}

.icon-clus_Communications Technologies:before {
	content: "\E05F";
}

.icon-clus_CommunicationsTechnologies:before {
	content: "\E060";
}

.icon-clus_Miscellaneous Awards:before {
	content: "\E061";
}

.icon-clus_MiscellaneousAwards:before {
	content: "\E062";
}

.icon-clu_agriculture:before {
	content: "\E063";
}

.icon-clu_architecture:before {
	content: "\E064";
}

.icon-clu_arts:before {
	content: "\E065";
}

.icon-clu_business:before {
	content: "\E066";

}

.icon-clu_education_and_training:before {
	content: "\E067";
}

.icon-clu_finance:before {
	content: "\E068";
}

.icon-clu_government:before {
	content: "\E069";
}

.icon-clu_health_science:before {
	content: "\E06A";
}

.icon-clu_hospitality_and_tourism:before {
	content: "\E06B";
}

.icon-clu_human_services:before {
	content: "\E06C";
}

.icon-clu_information_technology:before {
	content: "\E06D";
}

.icon-clu_law:before {
	content: "\E06E";
}

.icon-clu_manufacturing:before {
	content: "\E06F";
}

.icon-clu_marketing:before {
	content: "\E070";
}

.icon-clu_science_technology:before {
	content: "\E071";
}

.icon-clu_transportation_distribution_and_logistics:before {
	content: "\E072";
}

.icon-combat_speciality:before {
	content: "\E073";
}

.icon-comments-sign:before {
	content: "\E074";
}

.icon-contact:before {
	content: "\E075";
}

.icon-course-plan:before {
	content: "\E076";
}

.icon-cp_check_Box:before {
	content: "\E077";
}

.icon-cp_close:before {
	content: "\E078";
}

.icon-cp_completed:before {
	content: "\E079";
}

.icon-cp_dual_credit:before {
	content: "\E07A";
}

.icon-cp_edit:before {
	content: "\E07B";
}

.icon-cp_planned:before {
	content: "\E07C";
}

.icon-cp_plus:before {
	content: "\E07D";
}

.icon-cp_unplanned:before {
	content: "\E07E";
}

.icon-cross_mark:before {
	content: "\E07F";
}

.icon-degreeoffered:before {
	content: "\E080";
}

.icon-delete:before {
	content: "\E081";
}

.icon-down-rounded-arrow:before {
	content: "\E082";
}

.icon-education-level-1:before {
	content: "\E083";
}

.icon-education-level-2:before {
	content: "\E084";
}

.icon-education-level-3:before {
	content: "\E085";
}

.icon-education-level-4:before {
	content: "\E086";
}

.icon-education-level-5:before {
	content: "\E087";
}

.icon-education-plans:before {
	content: "\E088";
}

.icon-electronic_and_Electrical_Repair:before {
	content: "\E089";
}

.icon-employment-history-work:before {
	content: "\E08A";
}

.icon-employment-outlook:before {
	content: "\E08B";
}

.icon-enterprene_menu_icon:before {
	content: "\E08C";
}

.icon-entrancedifficulty:before {
	content: "\E08D";
}

.icon-exams:before {
	content: "\E08E";
}

.icon-experiential-learning:before {
	content: "\E08F";
}

.icon-extracurricular-activities:before {
	content: "\E090";
}

.icon-factorsconsidered:before {
	content: "\E091";
}

.icon-filter:before {
	content: "\E092";
}

.icon-financialaidprogramsatthisschool:before {
	content: "\E093";
}

.icon-first-aid-kit:before {
	content: "\E094";
}

.icon-gears-configuration-tool:before {
	content: "\E095";
}

.icon-grad-requirement:before {
	content: "\E096";
}

.icon-graduate-student-avatar:before {
	content: "\E097";
}

.icon-graduate_Employment_Outcome:before {
	content: "\E098";
}

.icon-gradution-cap:before {
	content: "\E099";
}

.icon-helpful-high-school-courses:before {
	content: "\E09A";
}

.icon-helpful_Attributes:before {
	content: "\E09B";
}

.icon-helpful_fields_of_Study:before {
	content: "\E09C";
}

.icon-hiring_practice:before {
	content: "\E09D";
}

.icon-home:before {
	content: "\E09E";
}

.icon-housing:before {
	content: "\E09F";
}

.icon-ideas_menu_icon:before {
	content: "\E0A0";
}

.icon-interestprofilier_menu_icon:before {
	content: "\E0A1";
}

.icon-job-list:before {
	content: "\E0A2";
}

.icon-knowledge:before {
	content: "\E0A3";
}

.icon-lastyearsapplicants:before {
	content: "\E0A4";
}

.icon-learningstyle_menu_icon:before {
	content: "\E0A5";
}

.icon-licensing-certification:before {
	content: "\E0A6";
}

.icon-light:before {
	content: "\E0A7";
}

.icon-like:before {
	content: "\E0A8";
}

.icon-loan:before {
	content: "\E0A9";
}

.icon-location:before {
	content: "\E0AA";
}

.icon-machine_Operator_and_Production:before {
	content: "\E0AB";
}

.icon-major-employers-icon:before {
	content: "\E0AC";
}

.icon-military-careers:before {
	content: "\E0AD";
}

.icon-occ-and-clusters-interest:before {
	content: "\E0AE";
}

.icon-occupationsort_menu_icon:before {
	content: "\E0AF";
}

.icon-otheradmissionsinformation:before {
	content: "\E0B0";
}

.icon-overview:before {
	content: "\E0B1";
}

.icon-pencil-striped:before {
	content: "\E0B2";
}

.icon-personal-info:before {
	content: "\E0B3";
}

.icon-physical-demands:before {
	content: "\E0B4";
}

.icon-pig:before {
	content: "\E0B5";
}

.icon-plate-fork-and-knife:before {
	content: "\E0B6";
}

.icon-postsecondary-schools:before {
	content: "\E0B7";
}

.icon-preparation:before {
	content: "\E0B8";
}

.icon-prog-of-study-of-interest:before {
	content: "\E0B9";
}

.icon-programs-of-study:before {
	content: "\E0BA";
}

.icon-progs_apprenticeship:before {
	content: "\E0BB";
}

.icon-progs_concentrations:before {
	content: "\E0BC";
}

.icon-progs_graduate:before {
	content: "\E0BD";
}

.icon-progs_internship:before {
	content: "\E0BE";
}

.icon-progs_portfolio:before {
	content: "\E0BF";
}

.icon-progs_practicum:before {
	content: "\E0C0";
}

.icon-progs_preperation:before {
	content: "\E0C1";
}

.icon-progs_program_topics:before {
	content: "\E0C2";
}

.icon-progs_residency:before {
	content: "\E0C3";
}

.icon-progs_schools:before {
	content: "\E0C4";
}

.icon-progs_specialties:before {
	content: "\E0C5";
}

.icon-progs_things-to-know:before {
	content: "\E0C6";
}

.icon-question-circular-button:before {
	content: "\E0C7";
}

.icon-realitycheck_menu_icon:before {
	content: "\E0C8";
}

.icon-red-arrow:before {
	content: "\E0C9";
}

.icon-remove:before {
	content: "\E0CA";
}

.icon-reset:before {
	content: "\E0CB";
}

.icon-result:before {
	content: "\E0CC";
}

.icon-schoolsetting:before {
	content: "\E0CD";
}

.icon-schoolsize:before {
	content: "\E0CE";
}

.icon-schooltype:before {
	content: "\E0CF";
}

.icon-search:before {
	content: "\E0D0";
}

.icon-self-knowledge-career-assessment:before {
	content: "\E0D1";
}

.icon-servicesforwomenandfamilies:before {
	content: "\E0D2";
}

.icon-sitting-dog:before {
	content: "\E0D3";
}

.icon-skills-abilities:before {
	content: "\E0D4";
}

.icon-smiley:before {
	content: "\E0D5";
}

.icon-smiley0:before {
	content: "\E0D6";
}

.icon-smiley1:before {
	content: "\E0D7";
}

.icon-smiley2:before {
	content: "\E0D8";
}

.icon-smiley3:before {
	content: "\E0D9";
}

.icon-smiley4:before {
	content: "\E0DA";
}

.icon-sports-car:before {
	content: "\E0DB";
}

.icon-star:before {
	content: "\E0DC";
}

.icon-support-network:before {
	content: "\E0DD";
}

.icon-t-shirt-black-silhouette:before {
	content: "\E0DE";
}

.icon-test-scores:before {
	content: "\E0DF";
}

.icon-thumbs-down-hover:before {
	content: "\E0E0";
}

.icon-thumbs-down:before {
	content: "\E0E1";
}

.icon-thumbs-up-hover:before {
	content: "\E0E2";
}

.icon-thumbs-up:before {
	content: "\E0E3";
}

.icon-top-5-employment-opps:before {
	content: "\E0E4";
}

.icon-top-5-salaries:before {
	content: "\E0E5";
}

.icon-training_provided:before {
	content: "\E0E6";
}

.icon-transferStudents:before {
	content: "\E0E7";
}

.icon-transportation_Management:before {
	content: "\E0E8";
}

.icon-tv:before {
	content: "\E0E9";
}

.icon-up-rounded-arrow:before {
	content: "\E0EA";
}

.icon-verry-poor:before {
	content: "\E0EB";
}

.icon-video:before {
	content: "\E0EC";
}

.icon-volun-comm-service:before {
	content: "\E0ED";
}

.icon-wes_menu_icon:before {
	content: "\E0EE";
}

.icon-whoReceivedFinancialAid:before {
	content: "\E0EF";
}

.icon-wil_menu_icon:before {
	content: "\E0F0";
}

.icon-work-setting:before {
	content: "\E0F1";
}

/*interview module icon*/
.icon-interview-prepare:before {
	content: "\E0B8";
}
.icon-interview-practice:before {
	content: "\E09D";
}
.icon-interview-interview:before {
	content: "\E09D";
}
.icon-interview-followup:before {
	content: "\E075";
}