/******************************
    Horizontal Bar graph Start
*******************************/
.wes-graph-body {
    @include common-border(1px, 1px, 1px, 1px, nth($grey-shades, 8));
    padding: 30px 0 35px 0;
    background: $white;
    height: auto;
    margin: 0;
    @at-root {
        .wes-colorbox-list {
            list-style: none;
            display: flex;
            margin-bottom: 40px;
            float: right;
            @at-root {
                .wes-color-box {
                    padding: 0 12px;
                    border-radius: 5px;
                    margin: 0 5px 0 20px;
                    @include media-breakpoint-down(xs) {
                        margin: 0 5px 0 10px;
                    }
                }
                .wes-color-box-print {
                    padding: 0 12px;
                    border-radius: 5px;
                    margin: 0 5px 0 20px;
                }
            }
            
            li {
                @include media-breakpoint-down(xs) {
                    display: block!important;
                    text-align: left!important;
                    
                    .wes-color-box {
                        padding: 0 20px!important;
                    }
                } 
            }
        }
    }
    .skill-score {
        text-align: center;
        z-index: 9;
        background: $white;
        padding-top: 5px;
    }
    .horizontal-bar-graph {
        dl {
            width: $width-value;
            max-width: 700px;
            position: relative;
            @include media-breakpoint-only(lg) {
                max-width: 640px;
            }
            .graph-block {
                display: flex;
                min-height: 100px;
                margin: 0 0 5px 0;
                span {
                    font-size: $font-sizes-3;
                }
            }
            .graph-name {
                align-items: center;
                justify-content: flex-end;
                height: 36px;
                @include media-breakpoint-up(xl) {
                    display: table-cell;
                    width: 300px!important;
                }
                @include media-breakpoint-up(md) {
                    display: table-cell;
                    width: 186px;
                }
                @include media-breakpoint-down(sm) {
                    justify-content: center;
                    width: 42px;
                    img {
                        position: absolute;
                    }
                }
                @include media-breakpoint-down(xs) {
                    display: table-cell;
                    width: 32px;
                }
                .icon-graph {
                    @include media-breakpoint-down(sm) {
                        @include height-width(30px, 30px);
                        margin-top: -5px;
                    }
                    @include media-breakpoint-down(xs) {
                        @include height-width(25px, 25px);
                        margin-top: -2px;
                    }
                }
            }
            .horizontal-bar {
                font-size: .8em;
                line-height: 1;
                text-transform: uppercase;
                @include height-width(100%, 13%);
                background: repeating-linear-gradient(to right, nth($grey-shades, 8), nth($grey-shades, 8) 1px, $white 1px, $white 8.30%);
                display: table-cell;
                position: absolute;
                &.horizontal-bar-wesJr {
                    background: repeating-linear-gradient(to right, nth($grey-shades, 8), nth($grey-shades, 8) 1px, $white 1px, $white 11.07%);                    
                }
                @include media-breakpoint-up(lg) {
                    background: repeating-linear-gradient(to right, nth($grey-shades, 8), nth($grey-shades, 8) 1px, $white 1px, $white 8.32%);
                    &.horizontal-bar-wesJr {
                        background: repeating-linear-gradient(to right, nth($grey-shades, 8), nth($grey-shades, 8) 1px, $white 1px, $white 11.1%);                    
                    }
                }                
                @include media-breakpoint-down(md) {
                    @include height-width(100%, 13%);
                }
                .horizontal-bar-after {
                    content: "";
                    height: 30%;
                    text-align: right;
                }
                .horizontal-bar-score{
                    position: relative;
                    left: 15px;
                    top: 15px;
                }
                .percentage {
                    &:hover,
                    &:focus {
                        &:after {
                            background-color: nth($white-shades, 5);
                        }
                    }
                }
                &.ability-graph-modal{
                   width: 70%;
                   @include media-breakpoint-down(xs) {
                        width: 62%;
                    }
                   height: 50%;
                   background: -webkit-repeating-linear-gradient(left, nth($grey-shades, 8), nth($grey-shades, 8) 1px, $white 1px, $white 9.97%);
                   background: repeating-linear-gradient(to right, nth($grey-shades, 8), nth($grey-shades, 8) 1px, $white 1px, $white 9.97%);
                }
            }
            .horizontal-bar-wesprint{
                font-size: .8em;
                line-height: 1;
                text-transform: uppercase;
                width: 100%;
                background: repeating-linear-gradient(to right, nth($grey-shades, 8), nth($grey-shades, 8) 1px, $white 1px, $white 8.32%);
                display: table-cell;
                position: absolute;
                .horizontal-bar-score{
                    position: relative;
                    left: 14px;
                    top: 1px;
                    text-align: right;
                }
            }
            .numbers-12 {
                font-size: .8em;
                line-height: 1;
                text-transform: uppercase;
                @include common-border(1px, 0px, 0px, 0px, nth($grey-shades, 8));
                @include height-width(100%, 40px);
                padding-top: 8px;
                margin-top: 0px;
                background: $white;
                display: table-cell;
                position: absolute;
                    @include media-breakpoint-down(md) {
                            left: 32px;
                            bottom: -26px;
                            margin-top: -14px;
                        }
@include media-breakpoint-down(sm) {
    left: 44px;
    }
    
    @include media-breakpoint-between(md, xl) {
        left: 187px;
        bottom: -26px;
        margin-top: -14px;
    }
                ul li {
                    list-style: none;
                    display: inline-block;
                    position: absolute;
                    width: $width-value;
                    margin: 0 0 0 -3px;
                }
            }
            .occupation-horizontal-graph{
                &:last-child{
                    height: 10px;
                    .ability-graph-modal{
                        height: auto;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .cards-spacing {
        flex: 0 0 7em!important;
        @include custom-device(768px, 991px) {
            margin-bottom: 0px;
        }
    }

    .cards-heigh {
        height: 23em;
        @include custom-device(768px, 991px) {
            height: 23em;
        }
      /*** ip cards **/
        .ip-wil-result-page &{
            @media (min-width:992px){
                height: 14em;
            }
            @include custom-device(768px, 991px) {
                height: 18em;
                .cards-spacing{
                    flex: 0 0 5em !important;
                }
            }
        }
        /***** *****/
          /** ideas chart ***/
        .chart-ideas-result &{

            @media (min-width:992px){
                height: 25em;
            }
            @include custom-device(768px, 991px) {
                .cards-spacing{
                    flex: 0 0 3em !important;
                }
            }
        }
         /***** *****/
    }
}
.ideas-print-spacing{
    flex: 0 0 6em;
}
.print-columned{
    flex-flow: column wrap;
    height: 23em;
}
/******************************
    Horizontal Bar graph End
*******************************/
/*css for dynamic*/

.wes-color-box-proficient {
    background: nth($pink-shades, 12);
}
.wes-color-box-adult-proficient {
    background: nth($red-shades, 16);
}
.wes-color-box-Knowledge {
    background: nth($green-shades, 30);
}

.wes-color-box-NeedImp {
    background: nth($blue-shades, 21);
}

/*css for dynamic end*/

/***********************
     EQ Pie chart CSS
***********************/
.bordered {
    @include position(relative);
    @include height-width(200px, 200px);
    text-align: center;
    line-height: 180px;
    @include common-border-radius(50%, 0px);
    background-color: #00c546;
    background-image: linear-gradient(90deg, transparent 50%, chocolate 50%), linear-gradient(90deg, chocolate 50%, transparent 50%);
    animation: border-fill 3s linear infinite;
    .inner-border {
        @include position(absolute, $top: 10px, $left:10px);
        height: 90%;
        width: 90%; 
        background-color: nth($green-shades, 6);
        @include common-header(#00c546, 41px, inherit, inherit);
        border-radius: 50%;
    }
}

/******************************
    EQ Pie chart CSS Ending
******************************/

/****************************************
    At-A-Glance Salary graph  Starts
****************************************/
.chart-skills {
    list-style-type: none;
    position: relative;
    overflow: hidden;
    float: right;
    @include height-width(160px, 80px);
    @include media-breakpoint-down(sm) {
        float: none;
        @include height-width(167px, 83px);
    }
    li {
        transform-origin: 50% 0;
        animation: linear .4s forwards;
        margin-top: 5px;
        @include position (absolute, $top: 100%, $left: 0);
        @include height-width(inherit, inherit);
        @include common-border(0px, 18px, 18px, 18px, null);
            @include common-individual-border-radius($bottom-left: 185px, $bottom-right: 185px);
    }
    li:nth-child(1) {
        z-index: 4;
        border-color: nth($grey-shades, 7);
        animation-name: rotate-one;
        span {
            top: 3px;
            left: 10px;
            transform: rotate(-79.6deg);
        }
    }
    li:nth-child(2) {
        z-index: 3;
        border-color: nth($blue-shades, 10);
        animation-name: rotate-two;
        animation-delay: .4s;
        span {
            top: 20px;
            left: 10px;
            transform: rotate(-21.2deg);
            animation-delay: .4s;
        }
    }
    li:nth-child(3) {
        z-index: 2;
        border-color: nth($blue-shades, 17);
        animation-name: rotate-three;
        animation-delay: .8s;
        span {
            top: 10px;
            left: -2px;
            transform: rotate(-109.4deg);
            animation-delay: .8s;
        }
    }
    span {
        position: absolute;
        font-size: .85rem;
        backface-visibility: hidden;
        animation: fade-in .4s linear forwards;
    }
    span.chart-ative-p3 {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid nth($blue-shades, 17);
        margin-top: 8px !important;
        @include height-width(0px, 0px);
    }
}
.career-jr {
    .chart-skills {
        li:nth-child(3) {
            border-color: nth($green-shades, 49);
        }
        li:nth-child(2) {
            border-color: nth($green-shades, 37);
        }
        span.chart-ative-p3 {
            border-top: 10px solid nth($green-shades, 49);
        }
        span.chart-ative-p2 {
            border-top: 10px solid nth($green-shades, 37);
        }
    }
}
/**************************************
    At-A-Glance Salary graph  Ends
**************************************/

/********************************************
   Educatio ans Training Salary graph Start
********************************************/
.stacked-bar-graph {
    @include height-width(100%, 20px);
    // margin-bottom: 28px;
    // margin-top: 20px;
    span {
        background-color: nth($grey-shades, 7);
        width: 25%;    
        p.edu-name-style {
            @include position(absolute);
            line-height: 1.1;
            padding: 0 1px;
        }
        &:nth-of-type(5) p.edu-name-style  {
            right: 20px!important;
        
        }
    }
 }

/*******************************************
    Educatio ans Training Salary graph End
********************************************/

/************************************************
    Employment Opportunities Salary graph Start
************************************************/
.eo-bar-chart {
    table-layout: fixed;
    max-width: 95px;
    // float: right;
    li {
        display: table-cell;
        vertical-align: bottom;
        height: 85px;
        @include media-breakpoint-only(md) {
            height: 72px;
        }
    }
    span {
        display: block;
        animation: draw 1s ease-in-out;
    }
    li:nth-child(1) span {
        background: nth($grey-shades, 7);
        height: 50%;
    }
    li:nth-child(2) span {
        background: nth($grey-shades, 4);
        height: 70%;
    }
    li:nth-child(3) span {
        background: nth($grey-shades, 7);
        height: 100%;
    }
    li:nth-child(4) span {
        background: nth($grey-shades, 14);
        height: 100%;
    }
    .eo-bar-chart{
        @include custom-device(320px, 767px){
            margin-right: 50px;
        }
    }    
}
.ataglance-bargraph{
    width: 100%;
}
.ataglance-bargraph-print{
    position: relative;
    left: 5px;
}
@include media-breakpoint-down(md) {
    .ataglance-bargraph1 {
        @include position(relative, $top: 85px, $left:10px);
        margin: 0 0 0 -68px!important;
    }
}

@include media-breakpoint-up(md) {
    .ataglance-bargraph {
        @include position(relative, $top: 0px);
        text-align:center;
        margin-left: auto;
        max-width: 100px;
    }
        
    
@media (min-width:1200px) {
    .ataglance-bargraph {
    right: 5px;
    }
}
    .ataglance-bargraph1 {
        margin: 0 0 0 -66px!important;
        @include position(relative, $top: 85px);
    }
}



@include custom-device(992px, 1199px) {
    .ataglance-bargraph1 {
        top: 75px;
        margin: 0 0 0 -55px!important;
    }
}

/************************************************
    Employment Opportunities Salary graph End
************************************************/

/****************************************
       Ideas Pie Chart  Start
*****************************************/
#ideas-piechart {
    position: relative;
    width: 98%;  
    font-size: $font-sizes-3;
    .chart-grid {
       float: left;
       padding: 10px 0;
       position: relative;
       width: 100%;
     }
     .graph-headding{
         position: relative;
        font-weight: bold;
        width: 100%;
    }
  
    .graph-label-name {
        float: left;
        position: relative;
        padding: 0px 6px 0 0;
        margin: 0px;
        line-height: 11px;
        text-align: right;
        width: 300px;            
    }

    .bar {
        height: 15px;
        margin-bottom: 6px;
        position: relative;
        width: 100%;
        z-index: 1;
    }
  
    .v-divider {
        border-right: 1px dotted #f0c89d;
        @include position (absolute, $top: 0, $right: 5%, $bottom: 0, $left: 0);
        z-index: 0;
    }
    
    .v-divider.one { right: 50%; }
    .v-divider.two { right: 30%; }

    .idea-score {
        padding-left: 10px;
        @include position (relative, $top: -2px, $right: 5%, $bottom: 0, $left: 0);
    } 

    .vertical-border-right {
        border-right: 1px solid nth($grey-shades,8);
        @include media-breakpoint-down(sm){
            border-right: none;
        }
        position: absolute;
        @include height-width(50%, 95%);
        @include media-breakpoint-down (sm) {
            border: none;
        }
        &.border-right {
            @include media-breakpoint-down (sm) {
                border: none;
            }
        }        
    }
}
  
/***  progress bar start  ***/
   .progress--circle {
    position: relative;
    display: inline-block;
    @include height-width(100px, 100px);
    border-radius: 50%;
    background-color: nth($orange-shades,9);
    display: flex;
    justify-content: center;
    margin: auto;
    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: 10px;
      @include height-width(80px, 80px);
      border-radius: 50%;
      background-color: nth($green-shades,41);
    }
    &:after {
      content: '';
      display: inline-block;
      @include height-width(100%, 100%);
      border-radius: 50%;
      background-color:nth($orange-shades,9);
    }
  }
  
  .progress__number {
    position: absolute;
    top: 50%;
    width: 100%;
    line-height: 1;
    margin-top: -0.75rem;
    text-align: center;
    font-size: 1.5rem;
    color: $white;
  }
  
   $step: 1;
   $loops: round(calc(100 / $step));
   $increment: calc(360 / $loops);
   $half: round(calc($loops / 2));
   @for $i from 0 through $loops {
     .progress--bar[progress=" #{$i * $step}%"]:after {
       width: $i  $step  1%;
     }
     .progress--circle[progress=" #{$i * $step}%"]:after {
       @if $i < $half {
         $nextDeg: 90deg + ($increment * $i);
         background-image: linear-gradient(90deg, nth($grey-shades,8) 50%, transparent 50%, transparent), linear-gradient($nextDeg,nth($orange-shades,9) 50%, nth($grey-shades,8) 50%, nth($grey-shades,8));
       }
       @else {
         $nextDeg: -90deg + ($increment * ($i - $half));
         background-image: linear-gradient($nextDeg,nth($orange-shades,9) 50%, transparent 50%, transparent), linear-gradient(270deg,nth($orange-shades,9) 50%, nth($grey-shades,8) 50%, nth($grey-shades,8));
        }
     }
   }
/***  progress bar end  ***/

/****************************************
       Ideas Pie Chart  End
*****************************************/

/****************************************
       Schools Pie Chart  start
*****************************************/

.school-vertical-graph{
    display: flex;
    align-items: flex-end;
    .rate-text{
        padding: 0;
        margin: 0;
        text-align: center;
    }
    .vertical-graph{
        display: flex;
        align-items: flex-end;
     }
    .bar-one{
        width: 60px;
        background-color: nth($red-shades,12);
        margin: 2px;
    }
    .bar-two{
        width: 60px;
        background-color: nth($green-shades,45);
        margin: 2px;
    }
    .low-bar{
        @include height-width(45px, 50px);
        background-color: nth($graph-bar-colors,3);
    }
    .medium-bar{
        @include height-width(45px, 80px);
        background-color: nth($graph-bar-colors,2);
    }
    .high-bar{
        @include height-width(45px, 110px);
        background-color: nth($graph-bar-colors,1);
    }
}


/****************************************
       Schools Pie Chart  end
*****************************************/



// test donut chart for print screens

.print-result-page{
    .chart-skills1 {
        margin: 0 auto;
        padding: 0;
        list-style-type: none;
      }
      
      .chart-skills1 *,
      .chart-skills1::before {
        box-sizing: border-box;
      }
      
      
      /* CHART-SKILLS1 STYLES
      –––––––––––––––––––––––––––––––––––––––––––––––––– */
      
      .chart-skills1 {
        position: relative;
        width: 350px;
        height: 175px;
        overflow: hidden;
      }
      
      .chart-skills1::before,
      .chart-skills1::after {
        position: absolute;
      }
      
      .chart-skills1::before {
        content: '';
        width: inherit;
        height: inherit;
        border: 45px solid rgba(211, 211, 211, .3);
        border-bottom: none;
        border-top-left-radius: 175px;
        border-top-right-radius: 175px;
      }
      
      .chart-skills1::after {
        left: 50%;
        bottom: 10px;
        transform: translateX(-50%);
        font-size: 1.1rem;
        font-weight: bold;
        color: cadetblue;
      }
      
      .chart-skills1 li {
        position: absolute;
        top: 100%;
        left: 0;
        width: inherit;
        height: inherit;
        border: 45px solid;
        border-top: none;
        border-bottom-left-radius: 175px;
        border-bottom-right-radius: 175px;
        transform-origin: 51% 0;
      }
      
      .chart-skills1 li:nth-child(1) {
        z-index: 4;
        border-color: #888888;
         transform: rotate(51.6deg);
      }
      
      .chart-skills1 li:nth-child(2) {
        z-index: 3;
        border-color: #0d8dbe;
      transform: rotate(110.2deg);
      }
      
      .chart-skills1 li:nth-child(3) {
        z-index: 2;
        border-color: #00346d;
        transform: rotate(180.4deg);
      }
      
      
      .chart-skills1 span {
        position: absolute;
        font-size: .85rem;
        backface-visibility: hidden;
      }
      
      .chart-skills1 li:nth-child(1) span {
        top: 5px;
        left: 10px;
        transform: rotate(-51.6deg);
      }
      
      .chart-skills1 li:nth-child(2) span {
        top: 20px;
        left: 10px;
        transform: rotate(-110.2deg);
      }
      
      .chart-skills1 li:nth-child(3) span {
        top: 18px;
        left: 10px;
        transform: rotate(-180.4deg);
      }

      .card-wages-height {
        height: 133px;
      }
      .wages-graph-width, .ataglance-bargraph {
          width: 130px;
      }
   .wages-salary-rating {
        position: absolute;
        top: 70px;
        right: 55px; 
     } 

     .stacked-bar-graph span {
        border: 1px solid nth($grey-shades, 7);
        border-top: none;
        border-bottom: none;
     }
      
}

.radial-progress-bar-text{
    right: 0 !important;
    left: 0;
    margin-left:-70px;
    padding-left: 70px;
}