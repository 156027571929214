/***** ============ fonts start ============ *****/

$font-family-Arial: arial,
        sans-serif;
$font-bold: bold;
//  $font-sizes: "0.750rem",
//      "0.813rem",
//      "0.875rem",
//      "0.938rem",
//      "1rem",
//      "1.063rem",
//      "1.125rem",
//      "1.250rem",
//      "1.500rem",
//      "1.563rem",
//      "1.625rem",
//      "1.818rem",
//      "1.875rem",
//      "1.778rem",
//      "2.250rem",
//      "2.438rem",
//      "3.333rem",
//      "3.20rem",
//      "0.955rem",
//      "1.29rem",
//      "1.25rem",
//      "0.6rem";
$font-sizes-1: 0.750rem;
$font-sizes-2: 0.813rem;
$font-sizes-3: 0.875rem;
$font-sizes-4: 0.938rem;
$font-sizes-5: 1rem;
$font-sizes-6: 1.063rem;
$font-sizes-7: 1.125rem;
$font-sizes-8: 1.250rem;
$font-sizes-9: 1.500rem;
$font-sizes-10: 1.563rem;
$font-sizes-11: 1.625rem;
$font-sizes-12: 1.818rem;
$font-sizes-13: 1.875rem;
$font-sizes-14: 1.778rem;
$font-sizes-15: 2.250rem;
$font-sizes-16: 2.438rem;
$font-sizes-17: 3.333rem;
$font-sizes-18: 3.20rem;
$font-sizes-19: 0.955rem;
$font-sizes-20: 1.29rem;
$font-sizes-21: 1.25rem;
$font-sizes-22: 0.6rem;
$font-sizes: (
    1: 0.750rem,
    2: 0.813rem,
    3: 0.875rem,
    4: 0.938rem,
    5: 1rem,
    6: 1.063rem,
    7: 1.125rem,
    8: 1.250rem,
    9: 1.500rem,
    10: 1.563rem,
    11: 1.625rem,
    12: 1.818rem,
    13: 1.875rem,
    14: 1.778rem,
    15: 2.250rem,
    16: 2.438rem,
    17: 3.333rem,
    18: 3.20rem,
    19: 0.955rem,
    20: 1.29rem,
    21: 1.25rem,
    22: 0.6rem
);
$font-sizes-large: 7rem;
$font-sizes-small: 1em;
$font-size-large: 7rem;
/***** ============ fonts end ============ *****/

/************All grey shades****************/

$grey-shades: #dcdcdc #E8E7E6 #e3e3e3 #e5e5e5 #D9D9D9 #b5b5b5 #888888 #cccccc #acacac #999 #6d787e #424242 #646262 #777 #7f7f7f #b7b7b7 #d1d1d1 #b3b3b3 #f0f0f0 #D3D3D3 #BBB #555 #898F9A #8F8F8F #c6c6c6 #e0dcdb #ededed #6f6f6f #dfdfde #B8B8B8 #e9e9e9 #9f9f9f #6a6a6a;
/************End of All grey shades****************/

/******************All Blue shades*****************/

$blue-shades: #739ac1 #2b68a3 #0b8cbc #046183 #055f81 #0c8cbd #045978 #1990be #aaa #0d8dbe #004467 #0a579d #518ec4 #011219 #2c68a4 #245b84 #00346d #01a6dc #2196f3 #0000ee #0277bd #00bcd5 #06b0fe #376d77 #002f62 #003EB5 #5C6BC0 #234681 #326E6F #014b76 #000080 #4285F4 #3f51b5;
$sky-blue: #00bcd5 #045f8c #017d86 #386c77 #016d77 #5f93ca #6d94a9 #3F82F9;
/*******************End of All Blue Shades********/

/********Default height and width****************/

$height-value: 100%;
$width-value: 100%;
/********End of height and width****************/
/********All Black shades****************/

$black-shades: #3b3b3b #414141 #2c2a2a #002923 #212121 #2e2e2e #000000 #424242 #161616 #2d2d2d #626262 #1f1f1f #868c91;
/********End of all Black shades****************/

/********All White shades****************/

$white-shades: #fafafa #f2f2f2 #eaeaea #F7F7F7 #BFBFBF #EBEBEB #DDD #d2f0f4 #e7e7e7 #f5f5f5 #d9d9d9 #e9e9e9 #c9c9c9 #ffffff;
/********End of all White shades****************/

/********All Red shades****************/

$red-shades: #b53c29 #ff3c3c red #ff0000 #510556 #ae404b #ff6a7e #e65f70 #eb4960 #e94b5a #a1393a #EF3F42 #C2185C #f33f3e #EB0F0F #d34256 #B0306A;
/********End of All Red shades****************/

/********All Orange shades****************/

$orange-shades: #f46f4c #e57373 #f44336 #ff9800 #ff6646 #dd7c09 #ff7143 #ce6767 #e17680 #e87060 #f74c5c #A4660E #e0119d #ff6347;
/********End of all Orange shades****************/

/********All Green shades****************/

$green-shades: green #17a946 #07737f #017163 #00dd33 #018574 #3FB8AF #1198a7 #018676 #30b210 #3d7983 #0a8f72 #6fa301 #a6cc1f #2f7d32 #546f7a #00796a #018b95 #7fa7cc #0e90a0 #47bf2a #26a59a #4db7ad #014d41 #1098a6 #649301 #76782f #01ae83 #036182 #009899 #10b24e #488790 #1d7478 #10b24e #4cb050 #669601 #cce5a3 #5b8402 #015658 #94A828 #8dc104 #03a37d #038676 #077f64 #00D2FF #5a8500 #70a201 #5A8301 #2c974b;
/********End of  All Green shades****************/

/********All Yellow shades****************/

$yellow-shades :#FFEB3B;
/********End of  Yellow shades****************/

/********All Pink shades****************/

$pink-shades: #ff6a7f #dd6f6e #a80067 #cf43b0 #f06292 #71394c #ba3c9f #80397b #c3185c #b0178b #873975 #E75480 #E75481;
/********End of Pink shades****************/

/********All Purple shades****************/

$purple-shades : #b0006c #AB47BC #6d2b78 #781aae #593a8d #926290 #6A3787 #9370DB #9a40a9;
/********End of All Purple shades****************/

/*********** Gradent Colors **************/

$gradient-shades: #DA790A #834A07 #874444 #7a054d;


$brown-shades: #a4373a;
$graph-bar-colors: #b0e0e6 #bcb3b3 #cdcdcd;

/*********** Military cardsColors **************/

/*********** Default color for the primary,secondary,planhader**************/
$military-color-shades: #414C3E #75792F #929292 #846B4C #717755 #6E7F91;
$default-primary-color: #004467;
$default-secondary-color: #6fa301;
$default-planheader-color: #b53c29;
$primary_color: var(--primary-color, $default-primary-color);
$secondary_color: var(--secondary-color, $default-secondary-color);
$plan_header_color: var(--plan-header-color, $default-planheader-color);




/*********** subState color**************/
$subState: #009999;

$interview_shade: #F17829 #D86E39 #BF5700 #F79802;

/************ icon size*****************/
$headerIconSize: var(--icon-size);
// $fa-style-family: "Fontawesome";