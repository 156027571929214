.icon-ability_landing-sprite {
  width: 40px; height: 40px;
  background: url('/../assets/images/css_sprites.png') -1040px -2110px;
}

.icon-aboveAverage-sprite {
  width: 184px; height: 96px;
  background: url('/../assets/images/css_sprites.png') -2090px -1820px;
}

.icon-academicservices-sprite {
  width: 26px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2473px -156px;
}

.icon-achievement_star_award_symbol-sprite {
  width: 17px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2520px -298px;
}

.icon-activities-sprite {
  width: 28px; height: 25px;
  background: url('/../assets/images/css_sprites.png') -1362px -2336px;
}

.icon-activitieswebsite-sprite {
  width: 361px; height: 130px;
  background: url('/../assets/images/css_sprites.png') -2090px -920px;
}

.icon-adapts_to_change-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -2000px -2110px;
}

.icon-additionalServices-sprite {
  width: 27px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -2473px -255px;
}

.icon-ageofstudents-sprite {
  width: 28px; height: 26px;
  background: url('/../assets/images/css_sprites.png') -1266px -2336px;
}

.icon-aid_type-sprite {
  width: 23px; height: 31px;
  background: url('/../assets/images/css_sprites.png') -2473px -824px;
}

.icon-AirForce-sprite {
  width: 42px; height: 42px;
  background: url('/../assets/images/css_sprites.png') -2408px -1820px;
}

.icon-apple-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -2056px -2110px;
}

.icon-applicationdates-sprite {
  width: 27px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -2473px -302px;
}

.icon-applicationrequirements-sprite {
  width: 27px; height: 26px;
  background: url('/../assets/images/css_sprites.png') -2473px -875px;
}

.icon-Army-sprite {
  width: 42px; height: 42px;
  background: url('/../assets/images/css_sprites.png') -792px -2110px;
}

.icon-athleticMembership-sprite {
  width: 19px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2520px -106px;
}

.icon-average-sprite {
  width: 60px; height: 60px;
  background: url('/../assets/images/css_sprites.png') -10px -2200px;
}

.icon-baby_CCI-sprite {
  width: 40px; height: 40px;
  background: url('/../assets/images/css_sprites.png') -1100px -2110px;
}

.icon-careers-sprite {
  width: 29px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2142px -2280px;
}

.icon-check_box-sprite {
  width: 20px; height: 20px;
  background: url('/../assets/images/css_sprites.png') -2520px -580px;
}

.icon-clu_agriculture-sprite {
  width: 21px; height: 26px;
  background: url('/../assets/images/css_sprites.png') -92px -2444px;
}

.icon-clu_architecture-sprite {
  width: 23px; height: 21px;
  background: url('/../assets/images/css_sprites.png') -1793px -2336px;
}

.icon-clu_arts-sprite {
  width: 23px; height: 19px;
  background: url('/../assets/images/css_sprites.png') -1878px -2336px;
}

.icon-clu_arts_color_small-sprite {
  width: 25px; height: 35px;
  background: url('/../assets/images/css_sprites.png') -62px -2336px;
}

.icon-clu_business-sprite {
  width: 23px; height: 22px;
  background: url('/../assets/images/css_sprites.png') -189px -2396px;
}

.icon-clu_business_color_small-sprite {
  width: 25px; height: 35px;
  background: url('/../assets/images/css_sprites.png') -107px -2336px;
}

.icon-clu_business_white-sprite {
  width: 70px; height: 70px;
  background: url('/../assets/images/css_sprites.png') -1898px -1511px;
}

.icon-clu_education_and_training-sprite {
  width: 24px; height: 15px;
  background: url('/../assets/images/css_sprites.png') -704px -2061px;
}

.icon-clu_education_and_training_color_small-sprite {
  width: 25px; height: 35px;
  background: url('/../assets/images/css_sprites.png') -152px -2336px;
}

.icon-clu_finance-sprite {
  width: 25px; height: 25px;
  background: url('/../assets/images/css_sprites.png') -2473px -2179px;
}

.icon-clu_government-sprite {
  width: 27px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -2473px -349px;
}

.icon-clu_government_color_small-sprite {
  width: 25px; height: 35px;
  background: url('/../assets/images/css_sprites.png') -197px -2336px;
}

.icon-clu_health_science-sprite {
  width: 29px; height: 19px;
  background: url('/../assets/images/css_sprites.png') -2423px -1996px;
}

.icon-clu_health_science_color_small-sprite {
  width: 25px; height: 35px;
  background: url('/../assets/images/css_sprites.png') -242px -2336px;
}

.icon-clu_hospitality_and_tourism-sprite {
  width: 29px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2191px -2280px;
}

.icon-clu_hospitality_and_tourism_color_small-sprite {
  width: 25px; height: 35px;
  background: url('/../assets/images/css_sprites.png') -287px -2336px;
}

.icon-clu_human_services_color_small-sprite {
  width: 25px; height: 35px;
  background: url('/../assets/images/css_sprites.png') -332px -2336px;
}

.icon-clu_information_technology-sprite {
  width: 28px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -782px -2336px;
}

.icon-clu_information_technology_color_small-sprite {
  width: 25px; height: 35px;
  background: url('/../assets/images/css_sprites.png') -377px -2336px;
}

.icon-clu_manufacturing-sprite {
  width: 27px; height: 26px;
  background: url('/../assets/images/css_sprites.png') -2473px -921px;
}

.icon-clu_marketing-sprite {
  width: 26px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -2473px -967px;
}

.icon-clu_marketing_color_small-sprite {
  width: 25px; height: 35px;
  background: url('/../assets/images/css_sprites.png') -422px -2336px;
}

.icon-avgstudentdebt-sprite {
  width: 361px; height: 130px;
  background: url('/../assets/images/css_sprites.png') -2090px -1070px;
}

.icon-baby_mini-sprite {
  width: 16px; height: 16px;
  background: url('/../assets/images/css_sprites.png') -2520px -694px;
}

.icon-bath_tub_box-sprite {
  width: 30px; height: 31px;
  background: url('/../assets/images/css_sprites.png') -2408px -1882px;
}

.icon-artistic-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -2112px -2110px;
}

.icon-cciAdult_landing-sprite {
  width: 64px; height: 60px;
  background: url('/../assets/images/css_sprites.png') -370px -2110px;
}

.icon-asmnt_tick_mark-sprite {
  width: 16px; height: 16px;
  background: url('/../assets/images/css_sprites.png') -2520px -730px;
}

.icon-bath_tub_mini-sprite {
  width: 16px; height: 16px;
  background: url('/../assets/images/css_sprites.png') -2520px -766px;
}

.icon-CCI_20_35-sprite {
  width: 25px; height: 30px;
  background: url('/../assets/images/css_sprites.png') -2473px -205px;
}

.icon-clu_science_technology-sprite {
  width: 25px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -2473px -1391px;
}

.icon-clu_architecture_color-sprite {
  width: 37px; height: 45px;
  background: url('/../assets/images/css_sprites.png') -409px -2200px;
}

.icon-clu_architecture_color_small-sprite {
  width: 25px; height: 35px;
  background: url('/../assets/images/css_sprites.png') -467px -2336px;
}

.icon-clu_agriculture_color_small-sprite {
  width: 25px; height: 35px;
  background: url('/../assets/images/css_sprites.png') -512px -2336px;
}

.icon-clu_agriculture_white-sprite {
  width: 70px; height: 70px;
  background: url('/../assets/images/css_sprites.png') -1988px -1511px;
}

.icon-child_care-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -2168px -2110px;
}

.icon-careerServicesOffered-sprite {
  width: 25px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2473px -726px;
}

.icon-careersJr-sprite {
  width: 60px; height: 50px;
  background: url('/../assets/images/css_sprites.png') -632px -2110px;
}

.icon-clu_arts_color-sprite {
  width: 37px; height: 45px;
  background: url('/../assets/images/css_sprites.png') -466px -2200px;
}

.icon-clu_architecture_white-sprite {
  width: 70px; height: 70px;
  background: url('/../assets/images/css_sprites.png') -1523px -1945px;
}

.icon-brefcase-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -2224px -2110px;
}

.icon-baby_box-sprite {
  width: 30px; height: 31px;
  background: url('/../assets/images/css_sprites.png') -2423px -1945px;
}

.icon-belowAverage-sprite {
  width: 184px; height: 96px;
  background: url('/../assets/images/css_sprites.png') -503px -1945px;
}

.icon-clu_finance_white-sprite {
  width: 70px; height: 70px;
  background: url('/../assets/images/css_sprites.png') -1613px -1945px;
}

.icon-clu_government_color-sprite {
  width: 37px; height: 45px;
  background: url('/../assets/images/css_sprites.png') -523px -2200px;
}

.icon-clu_government_white-sprite {
  width: 70px; height: 70px;
  background: url('/../assets/images/css_sprites.png') -1703px -1945px;
}

.icon-clu_finance_color-sprite {
  width: 37px; height: 45px;
  background: url('/../assets/images/css_sprites.png') -580px -2200px;
}

.icon-clu_finance_color_small-sprite {
  width: 25px; height: 35px;
  background: url('/../assets/images/css_sprites.png') -557px -2336px;
}

.icon-clu_education_and_training_color-sprite {
  width: 37px; height: 45px;
  background: url('/../assets/images/css_sprites.png') -637px -2200px;
}

.icon-clu_education_and_training_white-sprite {
  width: 70px; height: 70px;
  background: url('/../assets/images/css_sprites.png') -1793px -1945px;
}

.icon-clu_finance_black-sprite {
  width: 29px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2240px -2280px;
}

.icon-clu_human_services-sprite {
  width: 28px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -830px -2336px;
}

.icon-clu_human_services_white-sprite {
  width: 70px; height: 70px;
  background: url('/../assets/images/css_sprites.png') -1883px -1945px;
}

.icon-clu_human_services_color-sprite {
  width: 37px; height: 45px;
  background: url('/../assets/images/css_sprites.png') -694px -2200px;
}

.icon-civilian_counterparts-sprite {
  width: 29px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2289px -2280px;
}

.icon-clu_agriculture_color-sprite {
  width: 37px; height: 45px;
  background: url('/../assets/images/css_sprites.png') -751px -2200px;
}

.icon-bright-sprite {
  width: 60px; height: 60px;
  background: url('/../assets/images/css_sprites.png') -90px -2200px;
}

.icon-clu_information_technology_color-sprite {
  width: 37px; height: 45px;
  background: url('/../assets/images/css_sprites.png') -808px -2200px;
}

.icon-clu_information_technology_white-sprite {
  width: 70px; height: 70px;
  background: url('/../assets/images/css_sprites.png') -1973px -1945px;
}

.icon-clu_law-sprite {
  width: 28px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -878px -2336px;
}

.icon-clu_law_color-sprite {
  width: 37px; height: 45px;
  background: url('/../assets/images/css_sprites.png') -865px -2200px;
}

.icon-clu_law_color_small-sprite {
  width: 25px; height: 35px;
  background: url('/../assets/images/css_sprites.png') -602px -2336px;
}

.icon-clu_law_white-sprite {
  width: 70px; height: 70px;
  background: url('/../assets/images/css_sprites.png') -2063px -1945px;
}

.icon-career_clusters_settings-sprite {
  width: 80px; height: 80px;
  background: url('/../assets/images/css_sprites.png') -1698px -1511px;
}

.icon-clu_arts_white-sprite {
  width: 70px; height: 70px;
  background: url('/../assets/images/css_sprites.png') -2153px -1945px;
}

.icon-clu_business_color-sprite {
  width: 37px; height: 45px;
  background: url('/../assets/images/css_sprites.png') -922px -2200px;
}

.icon-clu_manufacturing_color-sprite {
  width: 32px; height: 40px;
  background: url('/../assets/images/css_sprites.png') -10px -2336px;
}

.icon-clu_manufacturing_color_small-sprite {
  width: 25px; height: 35px;
  background: url('/../assets/images/css_sprites.png') -647px -2336px;
}

.icon-clu_manufacturing_white-sprite {
  width: 70px; height: 70px;
  background: url('/../assets/images/css_sprites.png') -2243px -1945px;
}

.icon-clu_marketing_color-sprite {
  width: 37px; height: 45px;
  background: url('/../assets/images/css_sprites.png') -979px -2200px;
}

.icon-applicationfee-sprite {
  width: 363px; height: 132px;
  background: url('/../assets/images/css_sprites.png') -430px -1611px;
}

.icon-cci_image-sprite {
  width: 40px; height: 40px;
  background: url('/../assets/images/css_sprites.png') -1160px -2110px;
}

.icon-assocmembershort-sprite {
  width: 363px; height: 132px;
  background: url('/../assets/images/css_sprites.png') -813px -1611px;
}

.icon-avgtuition-sprite {
  width: 361px; height: 130px;
  background: url('/../assets/images/css_sprites.png') -2090px -1220px;
}

.icon-clu_health_science_color-sprite {
  width: 37px; height: 45px;
  background: url('/../assets/images/css_sprites.png') -1036px -2200px;
}

.icon-clu_hospitality_and_tourism_white-sprite {
  width: 70px; height: 70px;
  background: url('/../assets/images/css_sprites.png') -2333px -1945px;
}

.icon-clu_health_science_white-sprite {
  width: 70px; height: 70px;
  background: url('/../assets/images/css_sprites.png') -10px -2110px;
}

.icon-clu_hospitality_and_tourism_color-sprite {
  width: 37px; height: 45px;
  background: url('/../assets/images/css_sprites.png') -1093px -2200px;
}

.icon-careerwebsite-sprite {
  width: 363px; height: 132px;
  background: url('/../assets/images/css_sprites.png') -1196px -1611px;
}

.icon-clu_marketing_white-sprite {
  width: 70px; height: 70px;
  background: url('/../assets/images/css_sprites.png') -100px -2110px;
}

.icon-clu_science_technology_color-sprite {
  width: 37px; height: 45px;
  background: url('/../assets/images/css_sprites.png') -1150px -2200px;
}

.icon-Applicantsaccepted-sprite {
  width: 363px; height: 149px;
  background: url('/../assets/images/css_sprites.png') -10px -1776px;
}

.icon-clu_science_technology_color_small-sprite {
  width: 25px; height: 35px;
  background: url('/../assets/images/css_sprites.png') -692px -2336px;
}

.icon-clu_science_technology_white-sprite {
  width: 70px; height: 70px;
  background: url('/../assets/images/css_sprites.png') -190px -2110px;
}

.icon-clu_transportation_distribution_and_logistics-sprite {
  width: 27px; height: 17px;
  background: url('/../assets/images/css_sprites.png') -563px -2061px;
}

.icon-clu_transportation_distribution_and_logistics_color-sprite {
  width: 37px; height: 45px;
  background: url('/../assets/images/css_sprites.png') -1207px -2200px;
}

.icon-clu_transportation_distribution_and_logistics_color_small-sprite {
  width: 25px; height: 35px;
  background: url('/../assets/images/css_sprites.png') -737px -2336px;
}

.icon-clu_transportation_distribution_and_logistics_white-sprite {
  width: 70px; height: 70px;
  background: url('/../assets/images/css_sprites.png') -280px -2110px;
}

.icon-clubs-sprite {
  width: 363px; height: 132px;
  background: url('/../assets/images/css_sprites.png') -1579px -1611px;
}

.icon-clus_aidforIndividualswithDisabilities-sprite {
  width: 21px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -10px -2444px;
}

.icon-clus_aidforstudyinArchitectureandCommunityPlanning-sprite {
  width: 17px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -2520px -487px;
}

.icon-clus_aidforstudyinBiomedicalSciences-sprite {
  width: 27px; height: 24px;
  background: url('/../assets/images/css_sprites.png') -2473px -1901px;
}

.icon-clus_aidforstudyinComputerScienceandInformationTechnologies-sprite {
  width: 27px; height: 25px;
  background: url('/../assets/images/css_sprites.png') -2473px -1438px;
}

.icon-clus_aidforstudyinCulturalandEthnicStudies-sprite {
  width: 27px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -2473px -396px;
}

.icon-clus_aidforstudyinEngineering-sprite {
  width: 27px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -2473px -443px;
}

.icon-clus_aidforstudyinEngineeringTechnologies-sprite {
  width: 27px; height: 25px;
  background: url('/../assets/images/css_sprites.png') -2473px -1483px;
}

.icon-clus_aidforstudyinFamilyandConsumerSciences-sprite {
  width: 27px; height: 16px;
  background: url('/../assets/images/css_sprites.png') -657px -2061px;
}

.icon-clus_aidforstudyinHealthcareAdministration-sprite {
  width: 26px; height: 23px;
  background: url('/../assets/images/css_sprites.png') -1699px -2336px;
}

.icon-clus_aidforstudyinInterdisciplinaryandGeneralStudies-sprite {
  width: 21px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -51px -2444px;
}

.icon-clus_aidforstudyinLanguagesLiteratureandLinguistics-sprite {
  width: 26px; height: 24px;
  background: url('/../assets/images/css_sprites.png') -2473px -2314px;
}

.icon-clus_AidforstudyinMathematicsandStatistics-sprite {
  width: 27px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -2473px -490px;
}

.icon-clus_aidforstudyinMedicalTestingandLaboratoryTechnology-sprite {
  width: 27px; height: 26px;
  background: url('/../assets/images/css_sprites.png') -2473px -1014px;
}

.icon-clus_aidforstudyinMedicineDentistryPharmacyandVeterinary-sprite {
  width: 25px; height: 23px;
  background: url('/../assets/images/css_sprites.png') -144px -2396px;
}

.icon-clus_aidforstudyinNaturalResources-sprite {
  width: 19px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -2520px -204px;
}

.icon-clus_aidforstudyinNursing-sprite {
  width: 26px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -2473px -1060px;
}

.icon-clus_aidforstudyinPersonalServices-sprite {
  width: 27px; height: 25px;
  background: url('/../assets/images/css_sprites.png') -2473px -1528px;
}

.icon-clus_aidforstudyinPhysicalSciences-sprite {
  width: 17px; height: 26px;
  background: url('/../assets/images/css_sprites.png') -2520px -534px;
}

.icon-clus_aidforstudyinPrecisionProduction-sprite {
  width: 21px; height: 25px;
  background: url('/../assets/images/css_sprites.png') -133px -2444px;
}

.icon-clus_aidforstudyinProtectiveServices-sprite {
  width: 25px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2473px -775px;
}

.icon-clus_aidforstudyinPsychology-sprite {
  width: 25px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -2473px -1573px;
}

.icon-clus_aidforstudyinSecretarialStudiesandOfficeManagement-sprite {
  width: 19px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -2520px -251px;
}

.icon-clus_aidforstudyinSocialSciences-sprite {
  width: 27px; height: 24px;
  background: url('/../assets/images/css_sprites.png') -2473px -1945px;
}

.icon-clus_aidforstudyinTheologyReligionandPhilosophy-sprite {
  width: 25px; height: 25px;
  background: url('/../assets/images/css_sprites.png') -2473px -2224px;
}

.icon-clus_aidforstudyinTherapyandRehabilitationServices-sprite {
  width: 22px; height: 21px;
  background: url('/../assets/images/css_sprites.png') -1836px -2336px;
}

.icon-clus_aidRequiringAffiliationwithaBusiness-sprite {
  width: 27px; height: 19px;
  background: url('/../assets/images/css_sprites.png') -2473px -2405px;
}

.icon-clus_CommunicationsTechnologies-sprite {
  width: 27px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2473px -10px;
}

.icon-clusters_setting-sprite {
  width: 40px; height: 40px;
  background: url('/../assets/images/css_sprites.png') -1220px -2110px;
}

.icon-CoastGuard-sprite {
  width: 42px; height: 42px;
  background: url('/../assets/images/css_sprites.png') -854px -2110px;
}

.icon-contact-sprite {
  width: 24px; height: 25px;
  background: url('/../assets/images/css_sprites.png') -100px -2396px;
}

.icon-conventional-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -2280px -2110px;
}

.icon-costwebsite-sprite {
  width: 363px; height: 132px;
  background: url('/../assets/images/css_sprites.png') -776px -1776px;
}

.icon-cross_mark-sprite {
  width: 16px; height: 16px;
  background: url('/../assets/images/css_sprites.png') -2520px -802px;
}

.icon-declining-sprite {
  width: 60px; height: 60px;
  background: url('/../assets/images/css_sprites.png') -170px -2200px;
}

.icon-degreeoffered-sprite {
  width: 25px; height: 25px;
  background: url('/../assets/images/css_sprites.png') -2473px -2269px;
}

.icon-degrees_large-sprite {
  width: 372px; height: 408px;
  background: url('/../assets/images/css_sprites.png') -1698px -10px;
}

.icon-degrees_offered-sprite {
  width: 30px; height: 30px;
  background: url('/../assets/images/css_sprites.png') -1792px -2280px;
}

.icon-degrees_replacement-sprite {
  width: 363px; height: 225px;
  background: url('/../assets/images/css_sprites.png') -1698px -1266px;
}

.icon-disabledwebsite-sprite {
  width: 363px; height: 132px;
  background: url('/../assets/images/css_sprites.png') -1159px -1776px;
}

.icon-doct-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -2336px -2110px;
}

.icon-ear-sprite {
  width: 144px; height: 144px;
  background: url('/../assets/images/css_sprites.png') -175px -1945px;
}

.icon-earn-sprite {
  width: 28px; height: 25px;
  background: url('/../assets/images/css_sprites.png') -1410px -2336px;
}

.icon-employment_outlook-sprite {
  width: 29px; height: 24px;
  background: url('/../assets/images/css_sprites.png') -1650px -2336px;
}

.icon-enterpreneurial_icon-sprite {
  width: 40px; height: 40px;
  background: url('/../assets/images/css_sprites.png') -1280px -2110px;
}

.icon-enterprise-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -2392px -2110px;
}

.icon-entrancedifficulty-sprite {
  width: 19px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2520px -155px;
}

.icon-exams-sprite {
  width: 28px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -1122px -2336px;
}

.icon-factorsconsidered-sprite {
  width: 27px; height: 24px;
  background: url('/../assets/images/css_sprites.png') -2473px -1989px;
}

.icon-fafsa-sprite {
  width: 361px; height: 130px;
  background: url('/../assets/images/css_sprites.png') -2090px -1370px;
}

.icon-financial_aid_landing-sprite {
  width: 40px; height: 40px;
  background: url('/../assets/images/css_sprites.png') -1340px -2110px;
}

.icon-financialaidprogramsatthisschool-sprite {
  width: 28px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -926px -2336px;
}

.icon-first_aid_kit_box-sprite {
  width: 30px; height: 31px;
  background: url('/../assets/images/css_sprites.png') -1242px -2280px;
}

.icon-first_aid_kit_mini-sprite {
  width: 16px; height: 16px;
  background: url('/../assets/images/css_sprites.png') -2520px -838px;
}

.icon-food-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -1319px -2200px;
}

.icon-Gradrate-sprite {
  width: 363px; height: 130px;
  background: url('/../assets/images/css_sprites.png') -2090px -770px;
}

.icon-gradshired-sprite {
  width: 363px; height: 132px;
  background: url('/../assets/images/css_sprites.png') -1542px -1776px;
}

.icon-graduate_student_avatar-sprite {
  width: 23px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2473px -1803px;
}

.icon-gradution_cap_box-sprite {
  width: 30px; height: 31px;
  background: url('/../assets/images/css_sprites.png') -1292px -2280px;
}

.icon-gradution_cap_mini-sprite {
  width: 16px; height: 16px;
  background: url('/../assets/images/css_sprites.png') -2520px -874px;
}

.icon-hand_stop-sprite {
  width: 144px; height: 144px;
  background: url('/../assets/images/css_sprites.png') -339px -1945px;
}

.icon-has_a_good_network-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -1375px -2200px;
}

.icon-has_a_need_to_achieve-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -1431px -2200px;
}

.icon-has_an_internal_locus_of_control-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -1487px -2200px;
}

.icon-has_a_self_employed_parent_as_a_role_model-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -1543px -2200px;
}

.icon-has_business_experience-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -1599px -2200px;
}

.icon-has_determination-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -1655px -2200px;
}

.icon-has_family_support-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -1711px -2200px;
}

.icon-has_integrity-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -1767px -2200px;
}

.icon-has_self_confidence-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -1823px -2200px;
}

.icon-heart-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -1879px -2200px;
}

.icon-helpful_Attributes-sprite {
  width: 29px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2338px -2280px;
}

.icon-helpful_fields_of_Study-sprite {
  width: 30px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2092px -2280px;
}

.icon-helpful_high_school_courses-sprite {
  width: 22px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2473px -2081px;
}

.icon-hiring_practice-sprite {
  width: 30px; height: 30px;
  background: url('/../assets/images/css_sprites.png') -1842px -2280px;
}

.icon-home_box-sprite {
  width: 30px; height: 31px;
  background: url('/../assets/images/css_sprites.png') -1342px -2280px;
}

.icon-home_mini-sprite {
  width: 16px; height: 16px;
  background: url('/../assets/images/css_sprites.png') -2520px -910px;
}

.icon-hot_jobs-sprite {
  width: 20px; height: 28px;
  background: url('/../assets/images/css_sprites.png') -2520px -10px;
}

.icon-housing-sprite {
  width: 23px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2473px -1852px;
}

.icon-housingwebsite-sprite {
  width: 363px; height: 132px;
  background: url('/../assets/images/css_sprites.png') -2090px -10px;
}

.icon-ideas_image-sprite {
  width: 40px; height: 40px;
  background: url('/../assets/images/css_sprites.png') -1400px -2110px;
}

.icon-imageofhouse-sprite {
  width: 363px; height: 132px;
  background: url('/../assets/images/css_sprites.png') -2090px -162px;
}

.icon-independent-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -1935px -2200px;
}

.icon-interest_profiler_mini_icon-sprite {
  width: 60px; height: 50px;
  background: url('/../assets/images/css_sprites.png') -712px -2110px;
}

.icon-Interest_Profiler_icon-sprite {
  width: 40px; height: 40px;
  background: url('/../assets/images/css_sprites.png') -1460px -2110px;
}

.icon-investigative-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -1991px -2200px;
}

.icon-Is_Energetic-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -2047px -2200px;
}

.icon-job_list-sprite {
  width: 20px; height: 28px;
  background: url('/../assets/images/css_sprites.png') -2520px -58px;
}

.icon-jrAboveAverage-sprite {
  width: 184px; height: 96px;
  background: url('/../assets/images/css_sprites.png') -707px -1945px;
}

.icon-jrBelowAverage-sprite {
  width: 184px; height: 96px;
  background: url('/../assets/images/css_sprites.png') -911px -1945px;
}

.icon-jrWagesAverage-sprite {
  width: 184px; height: 96px;
  background: url('/../assets/images/css_sprites.png') -1115px -1945px;
}

.icon-keyboard-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -2103px -2200px;
}

.icon-knowledge-sprite {
  width: 22px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2473px -2130px;
}

.icon-lastyearsapplicants-sprite {
  width: 27px; height: 26px;
  background: url('/../assets/images/css_sprites.png') -2473px -1107px;
}

.icon-licensing_certification-sprite {
  width: 23px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -2473px -2358px;
}

.icon-light_box-sprite {
  width: 30px; height: 31px;
  background: url('/../assets/images/css_sprites.png') -1392px -2280px;
}

.icon-light_mini-sprite {
  width: 16px; height: 16px;
  background: url('/../assets/images/css_sprites.png') -2520px -946px;
}

.icon-loan-sprite {
  width: 27px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -2473px -537px;
}

.icon-lss_image-sprite {
  width: 40px; height: 40px;
  background: url('/../assets/images/css_sprites.png') -1520px -2110px;
}

.icon-major_employers_icon-sprite {
  width: 27px; height: 26px;
  background: url('/../assets/images/css_sprites.png') -2473px -1153px;
}

.icon-man_work-sprite {
  width: 28px; height: 25px;
  background: url('/../assets/images/css_sprites.png') -1458px -2336px;
}

.icon-MarineCorps-sprite {
  width: 40px; height: 40px;
  background: url('/../assets/images/css_sprites.png') -1580px -2110px;
}

.icon-mick-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -2159px -2200px;
}

.icon-military_careers-sprite {
  width: 25px; height: 28px;
  background: url('/../assets/images/css_sprites.png') -2473px -1199px;
}

.icon-military_header_icon-sprite {
  width: 40px; height: 40px;
  background: url('/../assets/images/css_sprites.png') -1640px -2110px;
}

.icon-moredescription-sprite {
  width: 29px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2387px -2280px;
}

.icon-css_sprites-sprite {
  width: 1668px; height: 1581px;
  background: url('/../assets/images/css_sprites.png') -10px -10px;
}

.icon-music-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -2215px -2200px;
}

.icon-Navy-sprite {
  width: 42px; height: 42px;
  background: url('/../assets/images/css_sprites.png') -916px -2110px;
}

.icon-nlx_logo-sprite {
  width: 40px; height: 20px;
  background: url('/../assets/images/css_sprites.png') -503px -2061px;
}

.icon-occresize-sprite {
  width: 40px; height: 40px;
  background: url('/../assets/images/css_sprites.png') -1700px -2110px;
}

.icon-Occupation_Sort_icon-sprite {
  width: 40px; height: 40px;
  background: url('/../assets/images/css_sprites.png') -1760px -2110px;
}

.icon-opportunities-sprite {
  width: 28px; height: 25px;
  background: url('/../assets/images/css_sprites.png') -1506px -2336px;
}

.icon-orgnization-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -2271px -2200px;
}

.icon-otheradmissionsinformation-sprite {
  width: 27px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -2473px -584px;
}

.icon-overview-sprite {
  width: 27px; height: 17px;
  background: url('/../assets/images/css_sprites.png') -610px -2061px;
}

.icon-pen-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -2327px -2200px;
}

.icon-personal_Qua_1-sprite {
  width: 106px; height: 107px;
  background: url('/../assets/images/css_sprites.png') -1962px -1611px;
}

.icon-personal_Qua_2-sprite {
  width: 106px; height: 107px;
  background: url('/../assets/images/css_sprites.png') -1925px -1776px;
}

.icon-physical_demands-sprite {
  width: 27px; height: 25px;
  background: url('/../assets/images/css_sprites.png') -2473px -1620px;
}

.icon-pig-sprite {
  width: 30px; height: 31px;
  background: url('/../assets/images/css_sprites.png') -1442px -2280px;
}

.icon-pig_box-sprite {
  width: 30px; height: 31px;
  background: url('/../assets/images/css_sprites.png') -1492px -2280px;
}

.icon-pig_mini-sprite {
  width: 16px; height: 16px;
  background: url('/../assets/images/css_sprites.png') -2520px -982px;
}

.icon-plate_fork_and_knife_box-sprite {
  width: 30px; height: 31px;
  background: url('/../assets/images/css_sprites.png') -1542px -2280px;
}

.icon-plate_fork_and_knife_mini-sprite {
  width: 16px; height: 16px;
  background: url('/../assets/images/css_sprites.png') -2520px -1018px;
}

.icon-plp_4_1-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -2383px -2200px;
}

.icon-plp_4_2-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -10px -2280px;
}

.icon-plp_4_3-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -66px -2280px;
}

.icon-plp_4_4-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -122px -2280px;
}

.icon-plp_4_5-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -178px -2280px;
}

.icon-plp_4_6-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -234px -2280px;
}

.icon-plp_4_7-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -290px -2280px;
}

.icon-plp_4_8-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -346px -2280px;
}

.icon-plp_4_9-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -402px -2280px;
}

.icon-poor-sprite {
  width: 60px; height: 60px;
  background: url('/../assets/images/css_sprites.png') -250px -2200px;
}

.icon-preparation-sprite {
  width: 20px; height: 25px;
  background: url('/../assets/images/css_sprites.png') -2520px -396px;
}

.icon-prog_degrees_offered-sprite {
  width: 400px; height: 145px;
  background: url('/../assets/images/css_sprites.png') -10px -1611px;
}

.icon-programs_of_study-sprite {
  width: 27px; height: 25px;
  background: url('/../assets/images/css_sprites.png') -2473px -1665px;
}

.icon-progs_apprenticeship-sprite {
  width: 28px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -1170px -2336px;
}

.icon-progs_concentrations-sprite {
  width: 22px; height: 28px;
  background: url('/../assets/images/css_sprites.png') -58px -2396px;
}

.icon-progs_graduate-sprite {
  width: 28px; height: 26px;
  background: url('/../assets/images/css_sprites.png') -1314px -2336px;
}

.icon-progs_internship-sprite {
  width: 28px; height: 21px;
  background: url('/../assets/images/css_sprites.png') -1745px -2336px;
}

.icon-progs_portfolio-sprite {
  width: 28px; height: 25px;
  background: url('/../assets/images/css_sprites.png') -1554px -2336px;
}

.icon-progs_practicum-sprite {
  width: 23px; height: 28px;
  background: url('/../assets/images/css_sprites.png') -2473px -2033px;
}

.icon-progs_preperation-sprite {
  width: 24px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2473px -1247px;
}

.icon-progs_program_topics-sprite {
  width: 30px; height: 31px;
  background: url('/../assets/images/css_sprites.png') -1592px -2280px;
}

.icon-progs_residency-sprite {
  width: 28px; height: 28px;
  background: url('/../assets/images/css_sprites.png') -10px -2396px;
}

.icon-progs_schools-sprite {
  width: 24px; height: 28px;
  background: url('/../assets/images/css_sprites.png') -2473px -1755px;
}

.icon-progs_specialties-sprite {
  width: 28px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -1218px -2336px;
}

.icon-progs_things_to_know-sprite {
  width: 19px; height: 26px;
  background: url('/../assets/images/css_sprites.png') -2520px -441px;
}

.icon-progsresize-sprite {
  width: 26px; height: 26px;
  background: url('/../assets/images/css_sprites.png') -2473px -1345px;
}

.icon-questin_mark-sprite {
  width: 35px; height: 45px;
  background: url('/../assets/images/css_sprites.png') -1264px -2200px;
}

.icon-quick_pic-sprite {
  width: 70px; height: 52px;
  background: url('/../assets/images/css_sprites.png') -454px -2110px;
}

.icon-rc_icon-sprite {
  width: 40px; height: 40px;
  background: url('/../assets/images/css_sprites.png') -1820px -2110px;
}

.icon-rc_occs_location-sprite {
  width: 94px; height: 94px;
  background: url('/../assets/images/css_sprites.png') -2294px -1820px;
}

.icon-realistic-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -458px -2280px;
}

.icon-recognition-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -514px -2280px;
}

.icon-relationships-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -570px -2280px;
}

.icon-sacrifices_employment-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -626px -2280px;
}

.icon-schools-sprite {
  width: 59px; height: 59px;
  background: url('/../assets/images/css_sprites.png') -330px -2200px;
}

.icon-schoolsetting-sprite {
  width: 26px; height: 28px;
  background: url('/../assets/images/css_sprites.png') -2473px -678px;
}

.icon-schools_index-sprite {
  width: 30px; height: 30px;
  background: url('/../assets/images/css_sprites.png') -1892px -2280px;
}

.icon-Schoolsize-sprite {
  width: 363px; height: 132px;
  background: url('/../assets/images/css_sprites.png') -2090px -314px;
}

.icon-Self_Employment-sprite {
  width: 30px; height: 30px;
  background: url('/../assets/images/css_sprites.png') -1942px -2280px;
}

.icon-service-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -682px -2280px;
}

.icon-servicesforwomenandfamilies-sprite {
  width: 27px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -2473px -631px;
}

.icon-sitting_dog_box-sprite {
  width: 30px; height: 31px;
  background: url('/../assets/images/css_sprites.png') -1642px -2280px;
}

.icon-sitting_dog_mini-sprite {
  width: 16px; height: 16px;
  background: url('/../assets/images/css_sprites.png') -2520px -1054px;
}

.icon-skills_abilities-sprite {
  width: 24px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2473px -1296px;
}

.icon-social-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -738px -2280px;
}

.icon-SpaceForce-sprite {
  width: 42px; height: 42px;
  background: url('/../assets/images/css_sprites.png') -978px -2110px;
}

.icon-sports_car_box-sprite {
  width: 30px; height: 31px;
  background: url('/../assets/images/css_sprites.png') -1692px -2280px;
}

.icon-sports_car_mini-sprite {
  width: 16px; height: 16px;
  background: url('/../assets/images/css_sprites.png') -2520px -1090px;
}

.icon-sportswebsite-sprite {
  width: 361px; height: 130px;
  background: url('/../assets/images/css_sprites.png') -2090px -1520px;
}

.icon-star-sprite {
  width: 16px; height: 16px;
  background: url('/../assets/images/css_sprites.png') -2520px -1126px;
}

.icon-stem-sprite {
  width: 30px; height: 30px;
  background: url('/../assets/images/css_sprites.png') -1992px -2280px;
}

.icon-studentfactratio-sprite {
  width: 363px; height: 132px;
  background: url('/../assets/images/css_sprites.png') -2090px -466px;
}

.icon-studentsoncampus-sprite {
  width: 363px; height: 149px;
  background: url('/../assets/images/css_sprites.png') -393px -1776px;
}

.icon-study_tips-sprite {
  width: 80px; height: 80px;
  background: url('/../assets/images/css_sprites.png') -1798px -1511px;
}

.icon-sun-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -794px -2280px;
}

.icon-t_shirt_black_silhouette_box-sprite {
  width: 29px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -1073px -2336px;
}

.icon-t_shirt_black_silhouette_mini-sprite {
  width: 15px; height: 15px;
  background: url('/../assets/images/css_sprites.png') -2520px -1198px;
}

.icon-takes_risks-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -850px -2280px;
}

.icon-teammascot-sprite {
  width: 363px; height: 132px;
  background: url('/../assets/images/css_sprites.png') -2090px -618px;
}

.icon-testtube-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -906px -2280px;
}

.icon-thumbs_down_hover-sprite {
  width: 16px; height: 17px;
  background: url('/../assets/images/css_sprites.png') -2520px -657px;
}

.icon-thumbs_up_hover-sprite {
  width: 17px; height: 17px;
  background: url('/../assets/images/css_sprites.png') -2520px -620px;
}

.icon-tools-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -962px -2280px;
}

.icon-top_5_salaries-sprite {
  width: 17px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2520px -347px;
}

.icon-Topics-sprite {
  width: 30px; height: 27px;
  background: url('/../assets/images/css_sprites.png') -1023px -2336px;
}

.icon-training_provided-sprite {
  width: 29px; height: 28px;
  background: url('/../assets/images/css_sprites.png') -974px -2336px;
}

.icon-transferStudents-sprite {
  width: 27px; height: 25px;
  background: url('/../assets/images/css_sprites.png') -2473px -1710px;
}

.icon-tuition_replacement-sprite {
  width: 363px; height: 394px;
  background: url('/../assets/images/css_sprites.png') -1698px -852px;
}

.icon-Tutions-sprite {
  width: 30px; height: 30px;
  background: url('/../assets/images/css_sprites.png') -2042px -2280px;
}

.icon-tutionsLarge-sprite {
  width: 372px; height: 394px;
  background: url('/../assets/images/css_sprites.png') -1698px -438px;
}

.icon-tv-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -1018px -2280px;
}

.icon-tv_box-sprite {
  width: 30px; height: 31px;
  background: url('/../assets/images/css_sprites.png') -1742px -2280px;
}

.icon-tv_mini-sprite {
  width: 16px; height: 16px;
  background: url('/../assets/images/css_sprites.png') -2520px -1162px;
}

.icon-typicalaidaward-sprite {
  width: 361px; height: 130px;
  background: url('/../assets/images/css_sprites.png') -2090px -1670px;
}

.icon-visual-sprite {
  width: 145px; height: 145px;
  background: url('/../assets/images/css_sprites.png') -10px -1945px;
}

.icon-wagesAverage-sprite {
  width: 184px; height: 96px;
  background: url('/../assets/images/css_sprites.png') -1319px -1945px;
}

.icon-Wants_Financial_Success-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -1074px -2280px;
}

.icon-wes_image-sprite {
  width: 68px; height: 50px;
  background: url('/../assets/images/css_sprites.png') -544px -2110px;
}

.icon-wesjr_card_icon-sprite {
  width: 40px; height: 40px;
  background: url('/../assets/images/css_sprites.png') -1880px -2110px;
}

.icon-whoReceivedFinancialAid-sprite {
  width: 27px; height: 29px;
  background: url('/../assets/images/css_sprites.png') -2473px -59px;
}

.icon-wil_image-sprite {
  width: 40px; height: 40px;
  background: url('/../assets/images/css_sprites.png') -1940px -2110px;
}

.icon-working_location-sprite {
  width: 28px; height: 25px;
  background: url('/../assets/images/css_sprites.png') -1602px -2336px;
}

.icon-working_conditions-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -1130px -2280px;
}

.icon-work_setting-sprite {
  width: 27px; height: 28px;
  background: url('/../assets/images/css_sprites.png') -2473px -108px;
}

.icon-works_hard-sprite {
  width: 36px; height: 36px;
  background: url('/../assets/images/css_sprites.png') -1186px -2280px;
}