
body {
    font: {
        family: $font-family-Arial!important;
        size: $font-size-base;
        color: $gray-light;
    }
    background: nth($white-shades, 2) !important;
    @include media-breakpoint-down(sm) {
        font-size: $font-size-sm;
    }
    .print-result-page{
       font-size: $font-size-base; 
    }// avoiding for PDF mobile view
}


.font-default {
    font-size: $font-sizes-5;
}

.font-large {
    font-size: $font-sizes-15;
}
 a:hover {
    color: #014c8c;
    text-decoration: none;
  }
.sub-list li:before{ margin-top: 2px !important; }
.text-dark { color: nth($black-shades, 8)!important; }
.text-green { color: nth($green-shades, 13)!important; }
.text-blue { color: nth($blue-shades,3)!important;}
.text-grean-shades { color: nth($green-shades, 20)!important;}
.overflow-visible { overflow: visible!important; }
.align-items-flex-end {
    align-items: flex-end;
}
.pointer {
    cursor: pointer;
}
.pointer-events-none{
    cursor: context-menu;
}
.overflow-hidden{
    overflow: hidden;
}

.anchorclass u{
    word-break: break-word;
}

.three-dots {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    text-decoration: none!important;
}

@include media-breakpoint-up(sm) {
    .w-sm-50 {
       width: 50%!important;
    }
}

@include media-breakpoint-down(sm) {
.w-sm-100 {
    width: 100%;
 }

 .w-sm-50 {
    width: 50%;
    height: auto!important;
 }

}

@include media-breakpoint-up(md) {
.w-md-50 {
    width: 50%!important;
}

}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    //I made a simple sass fallback for IE to use fade effect instead of slide.
    //start code
    .lss-card-height {
        .carousel-item {
          display: block;
          position: absolute;
          opacity: 0;
          transition: opacity .4s ease-in-out;
          &.active {
            opacity: 1;
          }
        }
      }//end code
    .three-coloumns-ip-result {
        padding-top: 7px!important;
    }
    .progress--circle {
        &.hidden-graph {
            background-color: transparent;
            &::before {
                background-color: transparent;
            }
            &::after {
                background-color: transparent;
                background-image: none !important;
            }
            .progress__number{
                color: nth($green-shades,41);
            }
        }
    }    
    .result-ability {
        @include media-breakpoint-only(md) {
        label {
            width: 205px;
        }
    }
    }

    .wil-assessment-page .wil-card-block ul li .wil-circle-notAllowed {
        top: 5px!important;
    }
    .rating-in-tabs p {
        padding: 18px 0px 20px!important;
        @include media-breakpoint-only(md) {
            padding: 19px 0px 22px!important
        }
    }
    input[type=text]::-ms-clear {
        display: none;
    }
    input[type=password]::-ms-reveal {
        display: none;
    }
    .common-enable-ques-arrow {
        top: 0px!important;
    }
    .common-disable-ques-arrow {
        top: -10px!important;
        right: 8px!important;
    }
    .card-group-2 .card {
        margin: 3px!important;
    }
    .result-list {
        &.height {
            @media (min-width: 768px) {
                min-height: 140px!important;
            }
        }
    }
    @media (min-width: 768px) {
        .fillter-search-pb {
            padding-bottom: 0 !important; //In IE browser, height is set to be increased because the previous styles are added to it. So that's the reason we have used "!important" to overcome this issue.
        }
    }

    @include media-breakpoint-down(sm){
        .menu-icon-button {
            width: auto !important;
        }
    }
}
@supports (-ms-ime-align: auto) {
    /* Microsoft EdgeV13&14 CSS styles go here */
    /* The below code is for only printing the progress value in Edge browser(in print page)
   i.e, while printing the values, the shield shape is removed because in Edge browser the graphical circle is not supported */
    //start code
    .progress--circle {
        &.hidden-graph {
            background-color: transparent;
            &::before {
                background-color: transparent;
            }
            &::after {
                background-color: transparent;
                background-image: none !important;
            }
            .progress__number{
                color: nth($green-shades,41);
            }
        }
    }//end code 
}
/***************Start body scroll****************/

#wrapper-body {
    @include height-width(100%, 100%);
    min-height: 100%;
    @include position (absolute, $top: 0, $left: 0);
    display: inline-block;
}

#main-wrapper-body {
    height: 100%;
    @include media-breakpoint-up(md) {
        padding: 90px 0 72px;
    }
    @include media-breakpoint-only(md) {
        padding-bottom: 0;
    }
    @include media-breakpoint-down(sm) {
        padding: 95px 0 0px;
    }
    #main-body {
        @include position (relative);
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

/***************End of body scroll****************/

$colors-list: nth($green-shades, 12) nth($orange-shades, 2) nth($blue-shades, 16) nth($pink-shades, 1) nth($green-shades, 28) nth($green-shades, 24) nth($green-shades, 27) nth($pink-shades, 4) nth($green-shades, 26) nth($green-shades, 11) nth($blue-shades, 17) nth($green-shades, 18) nth($pink-shades, 7) nth($pink-shades, 8) nth($blue-shades, 11)  nth($green-shades, 38) nth($blue-shades, 24) 
nth($purple-shades, 4) nth($red-shades, 6) nth($red-shades, 7) nth($purple-shades, 5) nth($sky-blue, 3) nth($orange-shades, 8) nth($blue-shades, 25) nth($green-shades, 39) nth($red-shades, 8) nth($pink-shades,9) nth($purple-shades, 2) nth($red-shades, 5) nth($blue-shades, 27) nth($green-shades, 13) nth($red-shades, 9) nth($green-shades, 42) nth($orange-shades, 10) 
nth($orange-shades, 11) nth($brown-shades, 1) nth($sky-blue, 4) nth($pink-shades, 11) nth($green-shades, 43) nth($sky-blue, 5) nth($green-shades, 44) nth($red-shades, 10) nth($red-shades, 11) nth($red-shades, 13)  nth($green-shades, 17) nth($blue-shades, 29)  nth($purple-shades, 6) nth($purple-shades, 7) nth($purple-shades, 8) nth($sky-blue, 8) 
nth($green-shades, 21) nth($blue-shades,32) nth($purple-shades,9) nth($orange-shades, 14) nth($interview_shade, 1) nth($interview_shade, 2) nth($interview_shade, 3) nth($interview_shade, 4) nth($red-shades, 17);
@each $current-color in $colors-list {
    $i: index($colors-list, $current-color);
    .cardcolor-#{$i} {
        background-color: $current-color;
    }
}
$military-colors-list: nth($military-color-shades, 1) nth($military-color-shades, 2) nth($military-color-shades, 3) nth($military-color-shades, 4) nth($military-color-shades, 5) nth($military-color-shades, 6);

@each $current-color in $military-colors-list {
    $i: index($military-colors-list, $current-color);
    .military-cardcolor-#{$i} {
        background-color: $current-color;
    }
}
@for $i from 1 through  6 {
    $step: 0.25em;
      $value: 2.50 - ($i)*$step;
      .header_#{($i)} {  font-size: $value!important; font-weight: 500; color: nth($grey-shades, 12); }
  }


  /***************gutter****************/

.gutter-10 .card .icon {
    float: right;
}

.gutter-10.row {
    margin-right: -5px;
    margin-left: -5px;
}

.gutter-10>[class^="col-"],
.gutter-10>[class^=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
}


/***************end of gutter****************/


/****************common for careers,clusters,emerging careers************/

.Occupation-Sort-card {
    @extend .Ideas-card1;
}

.mobile-container {
    @include media-breakpoint-down(sm) {
        width: $width-value;
        margin: 0px;
        padding: 0px!important;
        overflow-x: hidden;
    }
}

.mobie-width {
    width: $width-value!important;
}

.bodypadding {
    min-height: $height-value;
}

.tab-content {
.scholarship-card {
        @include common-border(1px, 1px, 1px, 2px, nth($green-shades, 13));
        border-radius: 2px;
         padding: 10px 20px;
         a {
            color: nth($green-shades, 20)!important;
        }
     }
h4 {
    padding-top: 20px!important;
    line-height: 18px;
    @include common-header(none, $font-sizes-8, none, bold);
    @include media-breakpoint-down(sm) {
        padding-top: 15px;
    }
}
}

.graphs-plp3 .card-block {
    padding: 3px 0!important;
}

.Ideas-card1 {
    background: nth($orange-shades, 2)!important;
}

.Occupation-Sort-card {
    @extend .Ideas-card1;
}


/*********End of both careers and clusters***********/


/*********both careers and emercareers***********/

.heading-plp3 {
    span {
        display: table-cell;
        vertical-align: middle;
    }
    span:nth-child(2) {
        line-height: 1.25;
        @include position (relative, $top: 2px, $left:5px);
    }
}

.card-header-slider {
    padding: 10px 5px 5px 5px;
    border-bottom: 1px solid nth($grey-shades, 8);
}

.card-block-slider-filter {
    div ul{
    margin-bottom: 0;
    }
    .statelist{
        column-count: 3;
        margin-top: 10px;
        @include media-breakpoint-down(sm){
            column-count: 2;
        }
        .statelist-items{
            display: inline-block;
            width: 100%;
        }
    }
    ul {
        margin-bottom: 0px;
    }
.header_5 {
        padding: 10px 0px;
        align-items: center;
        display: flex;
        font-size: $font-sizes-5 !important;
        line-height: 1.1;
        font-weight: bold;
    }
    // padding: 4px 15px;
    // @include media-breakpoint-up(md) {
    //     padding: 0px 15px 4px;
    // }

.column-count-2 {
column-count: 2;
ul {
    // width: 110px;

    li {

        height: auto;
    }
}
    }

.readiness-titles {
    display: none;
    padding-left: 2rem;
    @include media-breakpoint-up(md) {
        display: flex;
    }
    p {
        flex: 1;
        text-align: left;
    }
}
    .level-check-list  ul {
        height: 30px;
    }
}
 .order-change-list
        ul {    display: flex;
            flex-wrap: wrap;

         li {
            flex: 1 1 35%;
        }
        li:nth-of-type(1) {
            order: 1;
                 }
          li:nth-of-type(2) {
            order: 3;
          }
         li:nth-of-type(3) {
            order: 5;
          }
         li:nth-of-type(4) {
            order: 2;
          }
         li:nth-of-type(5) {
            order: 4;
          }
  }



.card-footer-slider {
    margin: 10px 10px 3px;
    @media only screen and (min-width:320px) and (max-width:325px) {
        margin: 13px 7px 3px;
    }
    // padding: 0px 16px 7px 0px;
    // // height: 100px;
    // @include media-breakpoint-down(xs) {
    //     padding: 0px 16px 7px 0px;
    // }
 
    .btn {
        margin-left: 10px;
    }
    .common-button.red {
        width: auto!important;
    }
}

.career-footer-slider{
    padding: 0px 16px 7px 0px;
     @include media-breakpoint-up(lg) {
        padding: 0px 16px 11px 0px;
    } 
}

.military-footer-slider{
    padding: 0px 16px 3px 0px;
}

.eo-location-card {
    padding: 10px 12px;
    background: $white;
    margin: 0 0 6px 0;
    color: $black;
    .cmpr-crd-graph-text {
        display: flex;
        justify-content: space-between;
        padding-top: 10px;
    }
}

.slider-drop-down-2 .card {
    @include common-border(2px, 2px, 2px, 2px, nth($blue-shades, 19));
    top: 12px;
    box-shadow: 0 0 3px nth($grey-shades, 8);
    margin-bottom: 20px;
    &:after {
        content: "";
        border-bottom: 10px solid nth($blue-shades, 19);
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
        @include position (absolute, $top: -10px, $left: 6px);
        z-index: 6;
    }
    &:before {
        content: "";
        border-bottom: 11px solid $white;
        border-right: 10px solid transparent;
        border-left: 11px solid transparent;
        @include position (absolute, $top: -8px, $left: 7px);
        z-index: 7;
    }
}


/****************End of common for careers,clusters,emerging careers************/


/***************common Asessment  scss****************/

.cmpr-cap-letr {
    text-transform: capitalize;
}

@include media-breakpoint-up(md) {
    .columned {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: column wrap;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
    }
    .space-between {
        -ms-flex-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        @include custom-device(768px, 991px) {
            .wes-result-page & {
                justify-content: inherit;
            }
        }
    }
    .div {
        -webkit-flex: 0 0 2em;
        -ms-flex: 0 0 2em;
    }
}

@include media-breakpoint-down(sm) {
    .columned {
        flex-flow: column wrap;
        display: block;
    }
    .space-between {
        -ms-flex-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
    }
}


.text-icon-header {
    vertical-align: middle;
    display: table-cell;
    list-style: none;
    padding: 3px 10px 0 2px;
    .top-three-careers & {
        font-size: $font-size-base;
    }
    .cci-result-page & {
        padding: 0px 5px 0 2px;
        a {
            font-size: $font-sizes-3;
        }
    }
    h5 {
        font-size: $font-size-sm;
    }
    .lss-result-page & span {
        position: relative;
        top: -3px;


    }
}

html,
body {
    overflow: hidden;
}


/***************End of Common assessment scss****************/


/**scss for loading**/

.loader {
    border: 8px solid nth($grey-shades, 9);
    border-radius: 50%;
    border-top: 8px solid nth($blue-shades, 11);
    @include height-width(60px, 60px);
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    @include position (absolute, $top: 0, $right: 0, $bottom: 0, $left: 0);
    margin: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@include media-breakpoint-down(sm) {
    .loading-responsive {
        width: 100% !important;
    }
}


/** loading end**/

.ip-result-icn-text,
.wes-result-page .card-media-left ul li,
.eq-result-page .well-p2 {
    .header_6 {
        color: nth($grey-shades, 13);
        font-weight: bold;
        text-align: center;
        font-size: $font-sizes-19 !important;
        // @media (max-width: 767px) {
        //     font-size: $font-size-xs!important;
        // }
    }
}


.anchorclass {
     color: nth($white-shades, 1);
     text-decoration: underline;
     word-break: break-word;
     &:hover {
        color: nth($white-shades, 1);
     }
}
.text-secondary {
    .anchorclass {
        color: nth($black-shades, 8);
        text-decoration: underline;
        // border-bottom: 1px solid nth($black-shades, 8);
        display: inline-block;
        word-break: break-all;

   }
}
.school-card-headding-print{
    .anchorclass {
        color: nth($green-shades, 20);
        text-decoration: none;
        border-bottom: 1px solid nth($green-shades, 20);
    }
}
.cardcolor-15 .anchorclass {
    color: nth($yellow-shades,1)
}

.cardcolor-15 .aat-scholarship .anchorclass {
    color: nth($white-shades,1);
}

.cardcolor-15 .aat-scholarship .startedlink a {
text-decoration: none;
}

    // .card-block p {
    // //  padding: 0px 16px;
    //  @include media-breakpoint-down(sm){
    //     padding: 0px 0px;
    //  }
    // }
    .pt-35 {
        padding-top: 35px;
        @media (max-width: 767px) {
            padding-top: 25px;
        }
    }
    .mt-30 {
        margin-top: -30px;
        @media (max-width: 767px) {
            margin-top: -15px;
        }
    }
    @media (max-width: 767px) {
        h6, .h6 {
            font-size: 0.98em;
        }
    }
    .border  {
        @include common-border(2px,2px,2px,2px,nth($white-shades,7));
    }

    .border-top  {
        @include common-border(2px,0px,0px,0px,nth($white-shades,7));
    }

    .border-left  {
        @include common-border(0px,0px,0px,2px,nth($white-shades,7));
    }

    .border-right  {
        @include common-border(0px,2px,0px,0px,nth($white-shades,7));
    }

    .border-bottom  {
        @include common-border(0px,0px,2px,0px,nth($white-shades,7));
         margin: auto;
    }
    @include media-breakpoint-up(md) {
        .border-md-none {
            border-width: 0;
        }
    }
    //For Small resolution- Added vertical line in between Application Closing Date and Need Based inside FIlter
    .bottom-top-bottom{
        @include common-border(0px,0px,2px,0px,nth($white-shades,7));
          @media(min-width: 768px){
          @include common-border(0px,2px,0px,0px,nth($white-shades,7));
          }
    }
    @media (max-width: 767px) {
        .border-sm-none  {
            @include common-border(0px,0px,0px,0px!important,nth($white-shades,7));
        }
    }

    .border-md-right {
        @media (min-width: 768px) {
        @include common-border(0px,2px,0px,0px,nth($white-shades,7));
    }

 }

 .border-sm-bottom {
    @media (max-width: 767px) {
        @include common-border(0px,0px,2px,0px,nth($white-shades,7));
    }
 }

    .tooltip-id .tooltip {
       width:  180px;
    
    }


    // .modal-open .modal {

    //     z-index: 9999999!important;
    // }
/** custom tooltip add  Start **/
/**Dashboard tooltip css **/
.favourate-card-body{
    .tooltip.bs-tooltip-top {
            bottom: -15px !important;
            // opacity: 1;
        }
    
        li a {
            color: nth($green-shades, 20 );
        }
}

.tooltip.bs-tooltip-top, .tooltip.bs-tether-element-attached-bottom {
    padding: 0;
    margin-top: -3px;
    width: 135px;
    margin-right: 2.5em;
    bottom: 7px !important;
    }
    @media (min-width: 770px) and (max-width: 779px){
        .tooltip.bs-tooltip-top {
            width: 180px;
        }
    }
    .tooltip.bs-tooltip-top .tooltip-inner::before, .tooltip.bs-tether-element-attached-bottom .tooltip-inner::before {
    bottom: -10px;
    left: 50%;
    margin-left: -5px;
    content: "";
    border-width: 5px 5px 0;
    border-top-color: #000;
    }

    .tooltip.bs-tooltip-right, .tooltip.bs-tether-element-attached-left {
    padding: 0 5px;
    margin-left: 15px;
    }

    .tooltip.bs-tooltip-right .tooltip-inner::before, .tooltip.bs-tether-element-attached-left .tooltip-inner::before {
    top: 50%;
    left: 0;
    margin-top: -5px;
    content: "";
    border-width: 5px 5px 5px 0;
    border-right-color: #000;
    }

    .tooltip.bs-tooltip-bottom, .tooltip.bs-tether-element-attached-top {
    padding: 0;
    margin-top: 7px !important;
        top: 20px;
        z-index: 9999;
    // border: 1px solid red;
    }

.tooltip.bs-tooltip-bottom .tooltip-inner::before,
.tooltip.bs-tether-element-attached-top .tooltip-inner::before {
    left: 50%;
    margin-left: -5px;
    content: "";
    border-width: 0 5px 5px;
    border-bottom-color: #000;
    }

    .tooltip.bs-tooltip-left, .tooltip.bs-tether-element-attached-right {
    padding: 0 5px;
    margin-left: -3px;
    }

    .tooltip.bs-tooltip-left .tooltip-inner::before, .tooltip.bs-tether-element-attached-right .tooltip-inner::before {
    top: 50%;
    right: 0;
    margin-top: -5px;
    content: "";
    border-width: 5px 0 5px 5px;
    border-left-color: #000;
    }

    .font-weight-bold {

        font-weight: bold!important;
    }


    .custom-font {

        font-size: $font-sizes-1;
    }

     /** custom tooltip add  End **/
     .disableddiv {
        opacity: 0.2;
        pointer-events: none;
         cursor:not-allowed;
        }

        .bar-result-mobile{
            height: 20px;
            margin-top: 5px;
        }

 .skip-maincontent {
       position: relative;
        z-index: 99999;
        padding: 11px;
       }

/** Print Result Page Code Start **/
.print-result-page {
    margin: 20px;
    .common-headding{
        width: 100%;
        font-size: $font-sizes-7;
        span{
            margin-left: 10px
        }
    }
    .print-header-logo{
        img{
            @include height-width(60px, 60px);
            margin-bottom: 5px;
        }
    }
      
    p{
        font-size: $font-sizes-5;
        white-space: normal;
    }
    .table-text{
        font-size: $font-sizes-5;
    }
    .eo-bar-chart {
        table-layout: fixed;
        max-width: 95px;
        float: right;
    } 
    
    .main-body{
        white-space: normal;  
        &.career-jr {
            .career-card {
                margin: 0;
                .career-card-body,
                .cluster-at-glance,
                .education-card-height {
                    height: 300px;
                }
            }
        }
        .career-card {
            .career-card-body {
                height: 343px;
            }
        } 
    }
  
    .score-section-pdf{
        position: relative;              
        .canvas-chart {
                height: 80px!important;// to override the canvas auto styles 
                width: 80px!important;// to override the canvas auto styles
                right: 10px;
        }  
      }
    p{
        font-size: $font-sizes-5;
        white-space: normal;
    } 
    .main-body{
        white-space: normal;
    p{
        font-size: $font-sizes-5;
        white-space: normal;
    }
    span{
        white-space: normal;
    }

    .ae-text-print{
        width: 180px;
    }
    .school-ataglance-lineht{
        line-height: 1.3;
    }
 
    .interpersonal-text{
        font-size: $font-sizes-7;
    }
    .ae-font-list{
        font-size: $font-sizes-4;
    }
    .chart-result{
        .square-box{
            @include height-width(14px, 14px);
        }
        .highlight-text{
            font-size: $font-sizes-5;
        }
        .result-text{
          width: 180px;
        }
    }
    .header_6 {
        font-size: $font-sizes-19 !important;
    }

    .ip-result-score{
        font-size: $font-sizes-10;
    }
    .ip-result-level{
        font-size: $font-sizes-5;
    }

 .header-text{
    font-size: $font-sizes-11;
 }
.cluster-icn {
    font-size: $font-sizes-16;
} 
 .square-box {
    @include height-width(14px, 14px);
    padding: 0.5rem;
 }
 .chart-result{
    width: 100%;
 .result-score{
font-size: 1.25rem;
 }
 .highlight-text{
    font-size: $font-sizes-5;
 }
 .result-text{
     width: 130px;
 }
}
.print-overall-padding{
    padding: 10px!important; //Important is used to override the default styles for the alignment in print view.
}
.result-values{
    font-size: $font-sizes-5;
 }
    .header {
        padding-bottom: 5px;
        .common-headding {
            margin-bottom: 0;
        }
        .sprite-asses-title{
            height: auto;
        }
    }

    .career-list .career-list-table{
        font-size: $font-sizes-5;
        padding: 5px 0 5px 10px;
        &.powderblue{
          padding: 10px 15px;
          height: auto;
        font-size: $font-sizes-5;
        }               
    }
    .clu-table_box {
        .border-bottom {
            &:last-child {
                border-bottom: none;
            }
        }
    } 
    .chart-result {
         .result-text.list-wil {
             width: 160px;
             line-height: initial;
             overflow: initial;
         }
         .result-list {
             .three-coloumns-ip-result {
                 columns: 2;
             }
         }
    }
    .result-cards {
        .html2pdf__page-break{
          page-break-before: always;
        }
        .card-ip-rsult {
         box-shadow: 0 0 5px nth($grey-shades, 8);
        }
        .ip-result-card-score {
             width: 100px;
             background: nth($orange-shades, 7);
        }
    }
    .rc-check-result-score {
        height: 63px;
    }
    .small-cards {
        .cci-quick-icon{
            padding-right: 2rem;
            padding-bottom: 3rem;
        }
        .text-icon-header {
          font-size: 14px;
          padding: 0 0 0 5px;
        }
    }
    .career-card {
        .career-card-header{
            justify-content: flex-start;
            height: 50px;
            font-size: $font-sizes-5;
            padding: 0px 16px;
            .header_6 {
            font-size: $font-sizes-4;
              line-height: 18px;
            }
            .flex-1 {
                flex: 1;
            }
        }
        &.top-three-career-header {
            height: 70px;
        }
        .program-of-study-card-body {
            height: 475px;
        }
    }  
    .bg-none {
        background: none !important;
    }
    
    /*lss print boxes width*/
    .w-30 {
        width: 30%;
    }
    .w-70 {
        width: 70%;
        
    }
    .score-text {
        font-size: 14px;
        width: 100%;
        text-align: right;
    }
    .careers-title {
        color: nth($blue-shades, 12);
        padding: 10px 0;
        font-size: $font-sizes-7;
        font-weight: bold;
    }
    .flex-even {
        flex: 1 1 auto;
    }
   
    .eo-bar-chart {
        li {
            height: 85px;
            span {
                animation: none;
            }
        }
    }
    .flex-fill {
        flex: 1 1 auto;
        display: flex;
    }
    .cluster-noun-text {
        i {
            font-size: $font-size-base;
        }
            @include padding-height(15px, 220px);
            h2 {
                font-size: 1.42rem;
            }
            h2,
            ol {
                margin-bottom: 0;
            }
    }   
    .career-card {
        .cluster-noun-text {
            height: 186px;
            ol {
                margin-bottom: 0;
            }
        }
    }
    .white-space {
        white-space: break-spaces; /** This style property supports and removes the text overlapping in Mac OS **/
    }
    .wt-space {
        white-space: normal; /** extension for white-space This style property also supports and removes the text overlapping in Mac OS **/
    }
    .word-wrap {
        word-wrap: break-word;
    }
    .text-indent-course{
        text-indent: -19px;
    }
    .text-underline {
        text-decoration: underline;
    }
    .school-card-headding {
        .anchorclass {
            word-break: break-all; /** This code is to overcome the issues like when the links are present in <p> tag so that no more text-overlapping such issues occur.  */
            text-decoration: none;
            color: nth($black-shades, 7);
        }
    }    
    .card-content {
        /** This code is to overcome the issues like when the links are present in <p> tag so that no more text-overlapping such issues occur.  */
        overflow-wrap: break-word;
        word-wrap: break-word;
    }
    .pre {
        white-space: pre-wrap; /** This style property supports to add space between words in print pages. For example,Degrees Offered **/
    }
    .detail-list {          
        padding-left: 0px;     
        li {
            list-style-type: none;
            &.no-list-type {
                list-style-type: none;
                padding-left: 20px;
            }
            &::before {
                display: none;
            }
            .detail-sub-list{
                padding-left: 20px;
                position: relative;
                line-height: 2.3rem;
                li {
                    list-style-type: none;
                }
                p{
                    margin-left: 10px;
                    width: 90%;
                    padding: 0;
                    margin-bottom: 0;
                    &::before {
                        content: '\f00c';
                        font-family: 'Fontawesome';
                        font-size: $font-sizes-1;
                        margin: 2px 8px;
                        position: absolute;
                        left: 0;
                    }
                }
              
            }
        }  
    }

    .pq-list{
        font-size: 2.8em;
        height: 28px;
        position: relative;
        left: 30px;
        
    a{
        font-size: 17px;
        display: inline-flex;
        vertical-align: middle;
        position: relative;
        right: 8px;
        bottom:2px; 
    }
    a:before{
        display:none;
    }   
    
    }
    .pq-list-first:first-child{
        color: rgb(255, 113, 67);
    }
    .pq-list-first:last-child{
        color: rgb(4, 89, 120)
    }
    .pq-list-second:first-child{
        color: rgb(14, 144, 160);
    }
    .pq-list-second:last-child{
        color: rgb(10, 143, 114);
    }
    .text-secondary {
        .anchorclass {
            line-height: 1;
            /* In print page, the hyper link comes in one single line */
            // width: max-content;
       }
    }

    .school-sizelist 
    {
    span.circle-pink {   
        @include height-width(10px, 10px);
        background-color: nth($pink-shades,3);
        border-radius: 50%;
        margin: 5px 5px;
        display: inline-block;
        float: left;
        padding: 6px; 
    }
}
.wes-result-page {
    .score-card { 
        font-weight: bold;
    }
    .card-media-right{
        display: flex;
        align-items: stretch;
        @include common-border(1px, 1px, 1px, 1px, nth($grey-shades, 8));
        border-radius: 5px;
    }
}
.word-space{  
    letter-spacing: 0.1px;
    text-align: justify;
}

.list { 
    li {
        :before {
            content: '\f054';
            font-family: 'Fontawesome';
            float: left;
            font-size: $font-sizes-1;
            margin: 2.5px 5px 0 0;
        }
        // list-style-type: disc;
        &.no-list-type {
            list-style-type: none;
        }
        // &::before {
        //     display: none;
        // }
    }

    }
    .progress--circle1 {
        position: relative;
        display: inline-block;
        @include height-width(100px, 100px);
        border-radius: 50%;
        background-color: nth($orange-shades,9);
        display: flex;
        justify-content: center;
        margin: auto;
        -webkit-print-color-adjust: exact;
        .before-circle{
            position: absolute;
          top: 10px;
          left: 10px;
          @include height-width(80px, 80px);
          border-radius: 50%;
          background-color: nth($green-shades,41);
          -webkit-print-color-adjust: exact;
        }
        .after-circle{
            display: inline-block;
            @include height-width(100%, 100%);
            border-radius: 50%;
            background-color:nth($orange-shades,9);
            -webkit-print-color-adjust: exact;
        }
    }
    
    $step: 1;
   $loops: round(calc(100 / $step));
   $increment: calc(360 / $loops);
   $half: round(calc($loops / 2));
    @for $i from 0 through $loops {
        .progress--bar[progress=" #{$i * $step}%"] .after-circle {
          width: $i  $step  1%;
        }
        .progress--circle1[progress=" #{$i * $step}%"] .after-circle {
          @if $i < $half {
            $nextDeg: 90deg + ($increment * $i);
            background-image: linear-gradient(90deg, nth($grey-shades,8) 50%, transparent 50%, transparent), linear-gradient($nextDeg,nth($orange-shades,9) 50%, nth($grey-shades,8) 50%, nth($grey-shades,8));
           }
          @else {
            $nextDeg: -90deg + ($increment * ($i - $half));
            background-image: linear-gradient($nextDeg,nth($orange-shades,9) 50%, transparent 50%, transparent), linear-gradient(270deg,nth($orange-shades,9) 50%, nth($grey-shades,8) 50%, nth($grey-shades,8));
           }
        }
      }
      .school-tabs {
        .career-card {
            color: nth($black-shades,8);
        }
        .graph-card .card-block-print {
            height: 140px;
        }
      }
      .alternative-list-color .sport-sub-list-color {
        font-size: smaller;
        .custom-image-size {
            height: 410px;
        }
        .canvas-chart2 {
            position: relative;
            right: 0;
            top: 0;
            left: 24px;
        }
        @include media-breakpoint-down(md) {
            .alternative-list-color {
                font-size: $font-sizes-4;
            }
            .detail-sub-list p {
                margin-bottom: 4px;
            }
        }
      }
      .cards-heigh {
          height: 22em;
        @include media-breakpoint-up(md) {
            height: 21em;
        }  
    }
    .career-ataglance {
        .card-block {
            height: 130px;
        }
    }
    .wes-graph-body {
        .horizontal-bar-graph dl .graph-block {
            min-height: auto;
        }
    } 
    .employment-outlook {
        .card-block p {
        font-size: $font-sizes-6;
        }
        .states-box {
            height: 99px;
        }
    }
    .donut-shart-schools {
        .career-card .career-card-header {
            height: 50px;
        }
    }
    .chart-size {
        width: 450px !important;
        height: 100px !important; //important tag for these styles set to unique graph size in prints for all devices
    }

    .ip-occ .chart-size {
        width: 250px !important;
        height: 135px !important; //important tag for these styles set to unique graph size in prints for all devices
    }
    .wil-occ-list {
        .chart-size {
            width:  200px !important; //important tag for these styles set to unique graph size in all devices print view
            height: auto !important; //important tag for these styles set to unique graph size in prints for all devices
        }
    }
    .chart-result.reality-check-result .result-list ul {
        column-count: 2;
    }
    .reality-check-box {
        column-count: 2;
    }
    .card-block.career-glance-box {
        height: 175px;
        font-size: 1rem;
    }
    .reality-check-result {
        .result-text {

            width: 135px !important; //if we remove important tag the ending letters of the occ-list are missing in result print page.
            &.list-wil {
                white-space: inherit;
            }
        }
    }    
    .lss-result-page {
        .card-block p {
            padding: 0px 14px;
        }
    }
    .font-small {
        font-size: $font-sizes-3;
    }
}
.findjobs-text .anchorclass {
    border-bottom: none;
}
.career-wages .main-table-row-header  {
    padding: 5px;
    font-size: 1rem;
}
.wes-graph-body .horizontal-bar-graph dl .horizontal-bar .horizontal-bar-score {
    top: 3px;
}
.career-wages {
    .main-table-row-header,
    .wages-row-bg1,
    .wages-row-bg2,
    .wages-table .wages-table-header-bg {
        padding: 5px;
        
    }
}
@include media-breakpoint-down(sm) {
    .career-wages {
        .main-table-row-header,
        .wages-row-bg1,
        .wages-row-bg2,
        .wages-table .wages-table-header-bg {
            padding: 5px;
            font-size: $font-sizes-5;
            
        }
    }
}
.occ-emerging{
    margin-left: -18px;
    margin-right: -18px;
}
.career-list .thumbcolor i{
    font-size: $font-sizes-9;
}
.cci-result-page .top-three-careers .card-block .header_1_print {
    height: 130px;
}
// Cluster icons width size-Print page
.clu_title {
    width: 100px;
}
}


//For hiding print page
.hidden-page {
    visibility: hidden;
    height: 0;
    overflow: hidden;
}
.custom-print-body{
    margin: 20px;
    font-size: 9px;
     .white-space {
        white-space: break-spaces;
    }
    .html2pdf__page-break{
        page-break-before: always;
    }
}
.w-40 {
    width: 40%;
}

.w-60 {
    width: 60%;
}
.donute-chart {
    width: 260px;
}
.careers-title {
    color: nth($blue-shades, 12);
    padding: 5px 0 15px;
    font-weight: bold;
    font-size: $font-sizes-7;
}
/** Print Result Page Code End **/
.fx-20-width {
    flex: 1 0 20%;
}
 /** Dashboard header Start **/
 .dashboard-bg {
    background: $secondary_color;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin: 0 0 10px;   
    p {
        @include common-header($white, $font-size-sm, left, 700);
    }
    @include media-breakpoint-up(md) {
        height: 60px;
        margin: 10px 0 15px;
        padding: 0 15px 0 20px;
        p {
            font-size: $font-size-base;
        }
    }    
    .user-photo {
        height: 35px;
        width: 35px;
        @include media-breakpoint-up(md) {
            height: 42px;
            width: 42px;
        }
    }
    .avatar {
        position: relative;
        display: inline-block;
        .rounded-circle {
            border-radius: 50%;
            border: 2px solid $white;
        }
        img {
            vertical-align: middle;
            @include height-width(100%, 100%);
            display: block;
        }
    }
    .account-user-name {
        display: block;
        color: $white;
    }
    .dashboard-mobile-username{
        max-width: 300px;
        padding-right: 1em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    @include media-breakpoint-down(sm){ 
    .dashboard-mobile-username{
        max-width: 120px;
        padding-right: 5px;
    }
}
}/** Dashboard header End **/
.box-middle-center {
    @include position (absolute, $top: 0, $left: 0);
}
.pre {
    white-space: pre-wrap; /** This style property supports to add space between words in print Degrees Offered list **/	        white-space: pre-wrap; /** This style property supports to add space between words in print Degrees Offered list **/
}
     /** Dashboard header End **/

/** Account-Settings page styles start **/
.account-settings-block {
    @include media-breakpoint-up(xl) {
        width: 80%;
        margin: auto;
    }
    .account-form-inner-block {
        background-color: nth($white-shades, 1);
        border: 2px solid nth($white-shades, 11);
        border-radius: 5px;
        padding: 16px 16px 0;
        margin-top: 20px;
        color: nth($black-shades, 12);
        legend {
            font-size: $font-sizes-8;
            font-weight: bold;
        }
        label {
            margin: 0;
            font-size: $font-sizes-3;
        }
        input {
            box-shadow: 0px 0px 5px 0px rgba(240,240,240,1);
        }
        input.form-control {
            margin-bottom: 16px;
            font-size: $font-sizes-4;
            @media(max-width:350px){
            font-size: 0.820em;    
            }
        }
        .custom-control-input:checked ~ .custom-control-indicator {            
            border: 1px solid nth($grey-shades, 32);
            background-image: none;
            &::before {
                position: absolute;
                content: "";
                left: 2px;
                top: 2px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: nth($grey-shades, 33);
            }
        }
        .custom-control-input:focus ~ .custom-control-indicator {
            box-shadow: none;
        }
        .custom-control-indicator {
            border: 1px solid nth($grey-shades, 32);
            background: transparent;
            top: 3px;
        }
        .btn-modal-popup {
            background-color: transparent;
            border: 2px solid nth($red-shades, 15);
            color: nth($red-shades, 15);
            border-radius: 50px;
            font-weight: 700;
            padding: 10px 50px;
            cursor: pointer;
            @media(max-width:400px){
                padding: 10px;
            }
        }
        .select-emoji {
            width: 60px;
            height: 60px;
            margin: 5px 10px 5px 0;
            &.active {
                box-shadow: 0 8px 6px -6px nth($black-shades, 12);
            }
        }
        .dropdown-menu {
            min-width: inherit;
            height: 265px;
            .dropdown-item {
                white-space: inherit;
                font-size: $font-sizes-4;
            }
        }
        .dropdown-toggle {
            text-overflow: ellipsis;
            overflow: hidden;
            padding-right: 30px;
            position: relative;
            &:after {
                position: absolute;
                top: 16px;
                right: 14px;
            }       
        }
        .required {
            color: nth($red-shades, 3);
            font-size: $font-sizes-8;
        }
    }
    .text-required{
        color: nth($red-shades, 15);
    }    
}/** Account-Settings page styles end **/

.modal-header {
    color: #0b8cbc;
    word-break: break-word;
}

/** Vertical scroll bar**/
.custom-scroll{
    overflow-y: auto;
       overflow-x: hidden;
       &::-webkit-scrollbar {
           width: 7px;
           height: 15px;
        background: url('/../assets/images/line.png') repeat;
           background-size: 100% 100%;
       }
       &::-webkit-scrollbar-thumb {
           background-color: nth($grey-shades, 7);
           border-radius: 50px;
       }
}
/*Custom Print For Icap styles*/
.print-view-header {
    .dropdown-menu {
        min-width: inherit;
    }
}
/*Realitycheck maps responsive styles*/
.location-map{
    max-width: 100%;
    height: 100%;
    path.shape,
	text {
		font-size: 11px;
		font-family: arial,sans-serif;
		cursor: pointer;
		transition: 0.2s ease-in;
    }
    text {
        &:hover{
            font-size: 16px;
            filter:url(#solid);
            transition: 0.2s ease-in;
            font-weight:bold;
        }
        }
	path.shape {
        &:hover{
            fill: #2388d6;
            stroke-width:5;
        }
    }
    polygon.shape {
        &:hover{
            fill: #2388d6;
        }
    }
   rect.shape {
    &:hover{
        fill: #2388d6;
        }
    }
}
  /*formio*/
 /* Below code is for "submission complete" text under submit button i.e,
  ** If the user submitting the form by clicking submit button then 'submission complete' text will be display.
  ** After clicking submit button if the user try to edit the same form then 'submission complete' text gets hidden.*/

   .formio-component-button .btn-success + .has-success{
      display: block !important;
    }
   .formio-component-button .btn-primary + .has-success{
      display: none;
    }
    /** Formio tooltip styles */
    /** In formio, for tooltip we have added black arrows */
    .formio-component {
        .tooltip .tooltip-arrow {
        bottom: 100%;
        display: block;
        position: absolute;
        border-width: 5px !important;
        border: solid transparent;
        @include height-width(0px, 0px);
        }
        .tooltip[x-placement="right"] {
        .tooltip-arrow {
        left: -2px;
        border-right-color: $black;
        }
      }
        .tooltip[x-placement="left"] {
        .tooltip-inner {
        margin-right: 8px;
        }
        .tooltip-arrow {
        right: -2px;
        border-left-color: $black;
        }
      }
    }
    
/*resume builder styles*/
.rb-content-block span {
    color: #FF0000;
    font-size: 14px;
    }
    :focus{
        outline: dotted 1px;
    }
         /* Tooltip css for portrait layouts only */
    @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    .tooltip {
        display: none!important;
    } 
        }
        /* Tooltip css for Landscape layouts only */
        @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
        .tooltip {
            display: none!important;
        }
            }
     /* Tooltip hidden css for touch screens, i.e when the mobile screens tooltip is hidden */
      @media (hover: none) and (pointer: coarse) {       
        .tooltip {
            display: none!important;
        } 
       
    }

    /* Without below style the closed captions menu will display outside the modal in an unexpected part of the page. */
    .modal.show .modal-dialog{
        transform: none;  /* Caption menu css added for video */
        margin-bottom: 3em;
        padding-bottom: 3em;
        margin-top: 8em;
    }
    .header-tooltip{
         /* Tooltip hidden css for ipads, i.e when the mobile screens tooltip is hidden */
    
        @media (max-width: 769px) {
            display: none;
        }
 
     /* Tooltip hidden css for touch screens, i.e when the mobile screens tooltip is hidden */
      @media (hover: none) and (pointer: coarse) {     
            display: none;
    }
}
    .heading-border {
        text-decoration: underline;
        -webkit-text-underline-position: under;
        -ms-text-underline-position: below;
        text-underline-position: under;
    }
   
    /*In Formio form, after clicking submit button tha top of the error message is hidden*/
    formio-alerts{
        .alert-danger{
            display: none;
        }
    }
    /* Add style for custom radio buttons in AccountSettings and guest AccountSetting form*/
    .custom-radio-input{
    input[type="radio"] {
        /* remove standard background appearance */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /* create custom radiobutton appearance */
        display: inline-block;
        width: 1rem;
        height: 1rem;
        padding: 1px;
        /* background-color only for content */
        background-clip: content-box;
        border: 1px solid #9f9f9f;
        border-radius: 50%;
        position: relative;
        top: 3px;
        right: 7px;
      }
      
      /* appearance for checked radiobutton */
      input[type="radio"]:checked {
        background-color: #6a6a6a;
      }
    }
 /* This style for list of table when click the filter button */
    .hide-search-results{
        display: block;
     @include custom-device(768px, 1280px) {
        display: none;
        }
    }

    .youtube-videos iframe{
        width: 100%;
    }

 .custom-form .formio-form input{
   text-overflow: ellipsis;
 }
 .wt-space {
    white-space: normal; /** extension for white-space This style property also supports and removes the text overlapping in Mac OS **/
 }
 .career-listed-disc {
    position: relative;
    font-size: 16px;
    li {
       list-style-type: none;
       &::before{
        content: "";
        position: absolute;
        @include height-width(10px, 10px);
        background-color:nth($white-shades, 1);
        border-radius: 50px;
        left: 1em;
        margin-left: 9px;
        margin-top: 6px;
       }
    }
}
.cluster-card-border{
    border: 1px solid nth($green-shades, 21);
    .cluster-text{
        color: nth($green-shades, 21)!important; 
    }
}
.header-text-color{
    color:nth($blue-shades, 10);
}
 
//Dashboard pdf logo
 .print-header-logo{
    img{
        @include height-width(60px, 60px);
        margin-bottom: 5px;
    }
}
//Cluster icons styles
.cluster-icons i{
    font-size: 25px;
}

//pdf Cards width
.cards-width{
    max-width: 48.6%;
    margin-left: 10px;
}


//helpingClass
.margin-l{
    margin-left: 5px;
}
.padding-r{
    padding-right: 0;
    @include media-breakpoint-down(sm){
        padding: 0;
    }
}
.dashboard-tab .nav-menu:focus{
    outline: none;
    box-shadow: none;
}
.dollar-padding{
    padding-top: 2em;
}

.dashboard-tab {
    .nav-menu:focus{
        outline: none;
        box-shadow: none;
    }
    .abilities-title {
        margin-bottom: 12px;
    }
}

.pos-progdesc .anchorclass {
    color: nth($black-shades, 1);
    text-decoration: underline;
    word-break: break-word;
}

.special-schools{
    height: 394px;
}

// .filter-icons-size{
//     @include custom-device(320px, 540px) {
//         width: 20px;
//         height: 20px;
//     }
// }

.filter-items{  //class for filter icons alignment
    flex-basis: 331px;
}
.cross-icon{
    width: 10px;
    height: 10px;
}
.cross-white{
    width: 15px;
    height: 15px;
}
.clu_title {
    width: 100px;
}

.shadow-none{
box-shadow: none !important;
}
.card-img-height{
    height: 136px;
}
.card-img-align{
    position: relative;
    bottom: 4px;
}
.bg-transparent{
    background-color: transparent;
}
.btn-width-sm {
    width: 120px;
}

.btn-width-lg {
    width: 150px;
}
.thumbsdown-tooltips {

    .tooltip.bs-tooltip-top,
    .tooltip.bs-tether-element-attached-bottom {
        padding: 0;
        margin-top: -3px;
        width: 135px;
        margin-right: 2.5em;
        bottom: 20px !important;
            border: 1px solid red;
    }
}
.rc-salry-value {
    text-align: center;
    position: relative;
    font-size: $font-sizes-3;
    top: -30px;

    &.at-glance-salry-value {
        @include media-breakpoint-only(sm) {
            right: 0;
        }
    }

    @include media-breakpoint-only(sm) {
        right: -145px;
        top: 48px;
    }

    @media (min-width: 768px) {
        position: absolute;
        top: 65px;
        right: 55px;
    }
}