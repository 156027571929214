.resume-cover-letter{
    #CoverLetter {
    width: 100%;
    max-width: 833px;
    background: white;
    margin: 30px auto;
    box-shadow: 0 0 10px #333;
    padding-bottom: 20px;
    font-family: arial, sans-serif;
    font-weight: normal;
    line-height: 1.5;
    }
    #CoverLetterContent {
    width: 100%;
    padding-top: 30px;
    padding-left: 40px;
    padding-bottom: 20px; 
    font-family: arial, sans-serif;
    font-weight: normal;
    line-height: 1.5;
    color: #424242;
    font-size: 15px;
    }
    .recipient-name{
        margin-top: 4px;;
    }
    .valediction p{
        margin-bottom: 0;
    }
    .body p{
        margin-bottom: 10px;
    }
    p {
        font-size: 1em;
        line-height: 1.4em;
        margin-bottom: 20px;
        color: #444;
    }
    .clear-both {
    clear: both;
    } 
    .cover-letter-date { 
    padding-top: 30px; 
    }
    .cover-intro, .body {
        width: 95%;
    }

    /*
    ######################################
            Chronological Basic
    ######################################
    */

    .chronological-basic .sender-name{
        font-size: 27px;
        line-height: 35px;
    }

    .chronological-basic {
        margin: 40px 0 0 0;
    }


    /*
    ###################################### 
            Functional Basic 
    ######################################
    */
    .functional-basic .sender-name{
        font-size: 27px;
        line-height: 35px;
    }

    .functional-basic {
        margin: 40px 0 0 0;
    }
    /*
    ######################################
            Chronological Contemporary
    ######################################
    */

    .chronological-contemporary .sender-name{
        font-size: 27px;
        line-height: 35px;
    }

    .chronological-contemporary .valediction {
        margin: 40px 0 40px 0;
    }
    /*
    ###################################### 
            Functional Contemporary 
    ######################################
    */
    .functional-contemporary .sender-name{
        font-size: 27px;
        line-height: 35px;
    }

    .functional-contemporary .valediction {
        margin: 40px 0 40px 0;
    }
    .hidden {
        display: none !important;
    }
}
@media only screen and (max-width: 767px) {      
    .ql-editor.ql-blank::before{
        align-items: center;
        display: flex;
        bottom: 0;
        font-size: 0.875rem;
        top: 0;
    }
}
