/*****  dynamic plp screens   *****/
.dynamic-side-padding{
    padding-bottom: 60px;
    @include custom-device(992px, 1042px){
        padding-left: 80px;
    }

    @include custom-device(1043px, 1125px){
        padding-left: 50px;
    }
    @include custom-device(768px, 991px){
        padding-left: 70px;
    }
    @include custom-device(1200px, 1280px){
        padding-left: 60px;
    }
    .btn-hidden-wil, .btn-visible-wil {
        margin-bottom: 12px;
    }
}
/* The chart and the legend text alignments for dashbord plan report printouts are same for small screens(mobile view) and larger screens(desktop view) as well */
@include media-breakpoint-down(md){
.dashboard-tab{
    div, h6, span{
        font-size: 1rem;
    }
    .Profile-user-name{
        font-size: 1.5rem;
    }
    .dynamic-pf-page{
    .pf-score-card{
        .card{
            min-height: 245px;
            height: 245px;
            border: 1px solid nth($blue-shades,16);            
        } 
        .total-score{
            h1 {
                font-size: 7em;
            }
            p{
                font-size: 3.2em;
            }
          } 
          @include media-breakpoint-down(sm) {
            .dashboard-text_alignment{
             position: relative;
            //  bottom: 100px;
            }
           }
        /*   To override the canvas-chart inline style, we used important here. */
          .score-section{            
                .canvas-chart{
                    height: 120px !important; 
                    width: 240px !important;    
            }
        }
      }
      
    }
}
}
.dynamic-pf-page{
    .score-rate li{
        p span{
            font-size: $font-sizes-7;
            color: nth($black-shades,7);
            font-weight: 700;
        }
        margin: 15px 0;
        padding: 0 10px;
        &.green{border-left: 6px solid #008000;}
        &.orange{border-left: 6px solid #dd7c09;}
        &.gray{border-left: 6px solid  #646262;}
    }
    .pf-plan .score-rate li{
        margin: 5px 0;
    }
    .pf-score-card{
        .card{
            min-height: 245px;
            height: 245px;
            border: 1px solid nth($blue-shades,16);
            @media (max-width:767px){
                height: auto;
                min-height: 50px;
            }
        } 
        .total-score{
            h1{
                font-size: $font-sizes-large;
                line-height: 0.9;              
            }
            p{
                font-size: $font-sizes-18;
                line-height: 0.9;
                color: nth($grey-shades,13);
            }
            @media (max-width:767px){
            h1{ 
                font-size: $font-sizes-18;
                }
                p{
                font-size: $font-sizes-15;
                }
            }
        }

        .score-section{
            position: relative;              
                .canvas-chart{
                    height:120px ;
                @media (max-width:767px){    
                    height: 80px;
                }                
            }
            .pf-graph-percent{
                @include position (absolute, $top: 0, $right: 0, $bottom: 0, $left: 0);                  
                margin: auto;
                padding: 0;
                font-size: $font-sizes-10;
            }
        }
    }


      .pf-plan{
        .card{
            border: 1px solid nth($blue-shades,16);
            h5{
                border-bottom: 1px solid nth($blue-shades,16);
                color: nth($black-shades,12);
                font-weight: 700;
                padding: 15px 0 15px 15px;
            }
            .chart-section{
                position: relative;
                p{
                    @include position ($position:absolute, $top:0, $right:0, $bottom:0, $left:0);
                    @include height-width(50px, 25px);
                    margin: auto !important;
                }
            }
        }
    }
}
.chartjs-render-monitor {
    position: relative;
    z-index: 9;
} 

.pie-chart_alignment{
    // position: relative;
    // @include  media-breakpoint-up(md){
    //     @include height-width(450px!important, 170px!important);
    //     right: calc(100% - 33%);
    //     z-index: 0;
    // }
    // @include  media-breakpoint-up(lg){
    //   right: calc(100% - 60%);
    // }
    // @include  media-breakpoint-up(xl){
    //   right: calc(100% - 80%);
    // }
}    
/*** Side Menu Start ***/
.side-menu.plans-menu {
    .fa-micon{
        top:10px !important;
        line-height: 1 !important;
        width: 25px;
    }
    .main-menu li{
        border:1px solid #c6c6c6 !important;       
    }
    .main-menu .menu-active{
        box-shadow: 0 0 5px 3px rgba(0,0,0,0.3) !important;
    }
    .menu-icon-button{
        background: transparent;
        padding: 0;
        border: 0;
        cursor: pointer;
        //Rebuilding the PR
        @include media-breakpoint-down(sm){
            width: 69px;   
            height: 38px;         
        }
    }
     .main-menu .svg-images {
        position: relative;
        display: flex;
        align-items: center;
        .menu-incomplete {
            width: auto!important;
            height: 40px;
            border: solid #739ac1;
            border-width: 0 0px 1px 0;
            background: #646262;
            color: $white;
            img{
                position: relative;
                top: 7px;
                width: 25px;
            }
            @include media-breakpoint-down(sm) {
                padding: 0 15px;
            }
         }
         .nav-text {
            position: relative;
            color: $black;
            padding-left: 5px;
            min-width: 200px;
         }
         .fa-micon.fa-check {
            width: 20px;
            margin-right: 10px;
            top: 0!important;
         }
     }
     @include custom-device(768px, 1767px) {
        .menu-incomplete {
            padding: 0 34px 0 25px;
        }         
    }
    @media (min-width:1768px){
        .menu-icon-button {
            display: none; /* This line code for hiding the three lines menu icon button upto particular resolution */
        }
        .menu-incomplete {
            padding: 0 18px;
            img {
                left: -1px;
            }
        }        
    }
 }
/*** Side Menu End ***/
.pf-navigation{ 
    @media (max-width: 767px){
        display: block !important;
        padding-top: 30px;
    }
    .pf-navigation-arrows{
        color: nth($blue-shades, 15);
        background-color: nth($white-shades,1);
        width: 100%;
        border:1px solid #ccc;
        border-radius: 10px;
        .caret-icon{
            font-size: $font-sizes-11;
            color: nth($blue-shades,11);
        }
        @media (max-width: 767px){
            width: 100%;  
            .caret-icon{
                font-size: $font-sizes-7;
            }
        }      
        .arrow-button{
            min-height: 50px;
            z-index: 9;        
            padding: 15px 5px;
            background-color:nth($grey-shades,31);
            color: nth($blue-shades,11);
            .btn-next {
                font-size: 0;
            }
            @include media-breakpoint-down(sm) { 
                span {
                    display: none;
                }
            }
            &.next-button{            
                border-top-right-radius: 10px;
                border-top-left-radius: 0;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 0
            }
        }
        .pf-navigation-text{
            width: 300px;
            .next-act{
                @media (min-width:768px){
                    width: 100%;
                } 
                @media (max-width:767px){
                    width: auto;
                }                 
            }
            .three-dots{
                width: 200px;
                @media (max-width:767px){
                width: 110px!important;
                }
            }
        }
        .prev-button{            
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            // background-color: transparent;
            // border: 0;
        }
    }
    .navigation-chart{
    // @include position ($position: absolute, $right: 110px, $top: 105px);
        // @media (max-width: 991px){
        //     @include position ($position:absolute, $right: 13px, $top: 105px);
        // }
        @media (max-width: 767px) {
        margin: auto;
        border-radius: 50%;
        // margin-bottom: 20px;
        // @include position ($position: absolute, $right: 0px, $top: 4px);
        }
        .chart-section{
            display: block;
            position: relative;
            margin-left: 15px;
                width: 80px;
                right: 20px;
            @include media-breakpoint-down(md) {
                    margin-left: 30px;
                }
            
                @include media-breakpoint-up(lg) {
                    margin-left: 10px;
                }
            p{
            @include position ($position:absolute, $top:0, $right:0, $bottom:0, $left:0);
            color:nth($blue-shades,11);
            @include height-width(10px, 25px);
            margin: auto;
            }
        }
    }
}
.plans-title {
    color: nth($blue-shades,11);
    .plans-number{
        background-color: nth($blue-shades,11);
        border-radius: 50%;
        color: nth($white-shades,1) !important;
        @include height-width(24px ,24px);
        font-size: $font-sizes-1;
            position: relative;
            bottom: 13px;
            left: 5px;
    }
    .plans-title-text {
            width: 90%;
            margin: 0px 24px 0px 0px;
    }
}
.cp-complete-circle {
    width: 100px;
    margin-left: 45%;
    margin-top: -15px;

    @include media-breakpoint-between(md, lg) {
        margin-top: 2px;
    }

    @include media-breakpoint-down(md) {
        margin-top: 2px;
        margin-left: 60%;
    }
}