/****Accessibility*******/

@include media-breakpoint-up(lg) {
    a,
    area,
    .btn,
    button,
    .radio-custom-label,
    .npmradio-custom-plp4,
    [type="radio"],
    [type="button"],
    [role="button"],
    input,
    select,
    summary,
    textarea,
    .occ-sort-circle
    {
        &:focus,  
        &:active {
            outline: 1px dotted;
          /* Here remove this style stop the blue focus in tab navigation. */
            // outline: 5px auto -webkit-focus-ring-color!important;
            z-index: 99;
            // box-shadow: none!important;
            text-decoration: none;
            box-shadow: 0px 0px 5px rgba(0,0,0,.8);
        }
    }

     

    .wil-card-block ul li button:focus, .result-ability-card  .ability-button:focus, .noUi-handle-lower:focus::after, .noUi-handle-upper:focus::after {
        outline: 1px dotted black!important;
      z-index: 99;
                box-shadow: none!important;
                text-decoration: none;    
      
      }

.common-checkbox input[type="checkbox"]:focus+label::before {
    outline: 1px dotted!important;
        // margin-top: 5px;
}

.common-radio-btn input[type="radio"]:focus+label::before {
    outline: 1px dotted!important;   
}

// .common-checkbox input[type="checkbox"]:focus+label::after {
//     outline: 1px solid !important;
//     // margin-top: -5px;
// }
button.loc-button-color:focus {
    outline: none!important;
}


}