.career-index-searchbox {
    border-color:  nth($green-shades,15)!important;
     @include common-border-radius(3px!important,1px);
     margin: 0 -14px 0 0;
    padding: 0 36px 0px 10px !important;
    height:40px;
}
.career-index-searchicon {
	background-color: transparent;
    padding: 0px !important;
    @include position (relative, $left: -16px);
     z-index: 7;
 i {
	color: nth($green-shades,15);
    font-size: $font-sizes-7;
}
}
.career-index-filter-icon {
    background-color: $white;
    padding: 0px !important;
    @include common-border(1px,1px,1px,1px,nth($blue-shades,3));   
    @include common-border-radius(3px!important,1px);
    @include  height-width(40px,40px);
    i{
        color: nth($blue-shades,3);
        font-size: $font-sizes-9;
        padding: 8px 10px;
    }
    .fa-times{
        color: nth($red-shades,3);        
    }
}
.careers-filter {
    display: flex;
    align-items: center;
    height: 55px;
    margin-left: 12px;
    
}
/* The below code for Filter selected items close cross mark */
/* Start */
.remove-filter-icon {
    color: nth($red-shades,3);
    cursor: pointer;
    position: relative;
    top: 8px;
    font-size: $font-size-base;
    font-weight: 700;
    &:focus{
        outline: none;
        border: 1px dotted red;
        padding: 0px 5px !important;
    }
}
/* End */

.slider-drop-down {  
    .card {
        border: 2px solid nth($blue-shades,19);
        top: 12px;
        box-shadow: 0 0 3px nth($grey-shades,8);
    }
    .occ-index-fillter-height {
        li.d-table-cell {
            img {
                width: 30px;
                height: 30px;
            }
        }
    }
}

.slider-drop-down .card:after {
content: "";
border-bottom: 10px solid nth($blue-shades,19);
border-right: 12px solid transparent;
border-left: 12px solid transparent;
@include position(absolute, $top: -10px, $right:6px);
z-index: 6;
}

.slider-drop-down .card:before {
content: "";
border-bottom: 8px solid $white;
border-right: 10px solid transparent;
border-left: 11px solid transparent;
@include position(absolute, $top: -8px, $right:8px);
z-index: 7;
}

// Assessment-filter styles here.. //
.assessment-filter {
    img{
        object-fit: contain;
        @include height-width(30px, 30px);
                        @include position (absolute);
                        left:-10px;
}
}
//check img size
.assessment-filter-icons{
    position: relative;
}
.img-fit{
    object-fit: contain;
}

.filter-search {
    width: 95%;
    opacity: .5;
}
.filter-search:focus {
    outline: none;
    box-shadow: none;
}
#selectMajorsList {
    max-height: 20rem;
    max-width: 28rem;
    overflow-y: overlay;
    overflow-x: hidden;
    padding-right: .5rem;
}
.selectedItem {
    button{
        white-space: normal;
    }
    @include media-breakpoint-down(md) {
        padding: .25rem;
    }
}
.filter-item-button{
    min-height: 2.5rem;
}