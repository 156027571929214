/** checkbox start *****/
.common-checkbox {
    label {
        margin-bottom: 0rem;
        display: inline-block;
        word-break: normal;
        @include position(relative);
        padding-left: 5px;

        &::before {
            content: "";
            @include common-border(1px, 1px, 1px, 1px, $gray);
            display: inline-block;
            margin-left: 10px;
            @include position(relative, $top: 1px);
            @include common-border-radius(3px, 1px);
            background-color: $white;
            -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
            -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
            transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
            @include height-width(15px, 15px);
            //@include media-breakpoint-up(md){ @include position(absolute, $top: 4px );}
        }

        &::after {
            display: inline-block;
            @include position(absolute, $top: 0px);
            margin-left: -18px;
            font-size: $font-sizes-1;
            color: $gray;
            @include height-width(15px, 15px);
        }
    }

    input[type="checkbox"] {
        opacity: 0;
        position: absolute;
        z-index: 9999;

        &:focus + label::before {
            outline: thin dotted;
            outline-offset: -2px;
        }

        &:checked + label::after {
            @include height-width(17px, 17px);
            font-family: 'Fontawesome';
            content: "\f00c";
        }
    }

    p {
        line-height: 17px
    }

    @include media-breakpoint-up(md) {
        margin-left: 20px;
    }

    @include media-breakpoint-down(sm) {
        margin-left: 17px;

        p {
            margin-bottom: 10px;
        }
    }

    @include custom-device (320px, 359px) {
        p {
            font-size: $font-sizes-3;
        }
    }

    &.filter-block-checkbox {
        p {
            padding-left: 4px;
            width: 100%;
        }

        @include media-breakpoint-down(sm) {
            label::before {
                margin-left: -17px !important;
            }

            label::after {
                margin-left: -17px !important;
                margin-top: -3px !important;
            }
        }

        @include media-breakpoint-up(sm) {
            label::before {
                margin-left: -17px !important;
            }

            label::after {
                margin-left: -22px !important;
                margin-top: -3px !important;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .checkbox-align {
        label::before {
            margin-left: -1px !important;
        }

        label::after {
            margin-left: -1px !important;
            margin-top: -4px !important;
        }
    }
}

@include media-breakpoint-down(md) {
    .checkbox-align {
        label::before {
            margin-left: -17px !important;
        }

        label::after {
            margin-left: -17px !important;
            margin-top: -4px !important;
            // border: 1px solid red;
        }
    }
}

/** checkbox end *****/
.slider-drop-down {
    .card {
        @include common-border(2px, 2px, 2px, 2px, nth($blue-shades, 19));
        top: 12px;
        box-shadow: 0 0 3px nth($grey-shades, 8);
    }

    .card:after {
        content: "";
        border-bottom: 10px solid nth($blue-shades, 19);
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
        @include position(absolute, $top: -10px, $right: 6px);
        z-index: 6;
    }

    .card:before {
        content: "";
        border-bottom: 8px solid $white;
        border-right: 10px solid transparent;
        border-left: 11px solid transparent;
        @include position(absolute, $top: -7px, $right: 8px);
        z-index: 7;
    }
}

.checkbox-lg {
    .form-check-input {
        top: 0.8rem;
        scale: 1.1;
        margin-right: 0.5rem;
        border: 1px solid #000;
    }
input[type="checkbox"]:hover {
    outline: 2px solid #00346d;
    
        @include media-breakpoint-down(sm) {
            outline: 1px solid #00346d;
        }
}
    
}

.checkbox-lg .eduIndex-check-label {
    padding-top: 13px;
    
}
.filter-block-Label {
    display: inline-block;
    vertical-align: top;

    @include media-breakpoint-between(md, xl) {
        width: 150px;
    }

    @include media-breakpoint-up(xl) {
        width: 250px;
    }
}
.filter-block-Deadline-Label {
    display: inline-block;
    vertical-align: top;
    // @include media-breakpoint-between(md, xl) {
    //     width: 100px;
    // }
}
.checkbox-xl {
    .form-check-input {
        top: 0.8rem;
        scale: 2.0;
        margin-right: 0.5rem;
        border: 1px solid black;
        &:hover {
                border: 2px solid black;
            }
    }
div {
    width: 235px;

    @include media-breakpoint-down(md) {
        width: 275px;
    }


    float: right;
    padding-left: 5px;
}
}

.dashboard-block-Label {
    display: inline-block;
    vertical-align: top;
    font-weight: bold;
    padding-left: 5px;
    @include media-breakpoint-up(md) {
        width: 270px;
    }
@include media-breakpoint-between(md, lg) {
    width: 280px;
}
}
.form-check-input {
    border: 1px solid #023;
}