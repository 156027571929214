/***********  Start Desktop tabs   ***********/
.career-tabs {
    .ngb-tabset .nav {
        background: nth($blue-shades, 12);
        @include height-width(100%, 60px);
        margin-bottom: 23px;
        display: flex;
        @include media-breakpoint-down(md) {
                display: none;
        
            }
        align-items: center;
        li {
            padding: 0px 5px;
            flex: 1;
            a {
                padding: 0 2px;
                color: $white;
                text-align: center;
                height: 49px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                margin-top: -8px;
                &:hover {
                    background-color: $white;
                    margin-top: -8px;
                    color: nth($green-shades,20);
                    @include common-border-radius (0, 0);                    
                }
            }
        }
                                                                                                                                                                                                                                                                @include media-breakpoint-down(md) {
            li {
                padding: 0px 3px;
                font-size: $font-sizes-3;
                margin-left: 0px;
                a {
                    color: $white;
                    padding: 0;
                }
            }
        }
    }
    .rating-in-tabs {
        background: nth($blue-shades, 19);
        p {
            margin: 0px!important;
            padding: 18px 0px;
            //font-size: 14px;
            @include media-breakpoint-only(md) {
                padding: 18px 0px 21px;
                
            }
            @media (max-width: 991px) and (min-width: 768px){
                font-size: 0.75em;
            }
        }
        .rating-down-2 {
            //margin: 9px 0px 0px 5px!important;
            margin: 7px 0 8px 5px !important;
        }
    }
    .ngb-tabset .nav-pills .nav-link.active {
        background-color: $white;
        color: nth($green-shades,20);
        border-radius: 0;
        margin-top: 5px;
    }
    .nav-link.active + .tab-down-arrow {
        @include height-width(0, 0px);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid nth($blue-shades, 12);
        margin: 4px auto 0;
    }
    /*********** End of Desktop tabs   ***********/
    /***********  Mobile tabs   ***********/
    .ngb-tabset-mobile {
        .dropdown-menu {
                background: nth($blue-shades, 12);
            }
        .nav {
            display: table;
            background: nth($blue-shades, 12);
            color: $white;
            line-height: 25px;
            @include height-width(75%, 40px);
            a {
                color: $white;
                border-bottom: 1px solid white;
            }
            .dropdown-menu a.nav-link {
                background: nth($blue-shades, 12);
                }

            .show>.dropdown-menu {
                width: 100%;
                background: nth($blue-shades, 12);
                border-radius: 0;
                top: -10px;

            }
            .dropdown-menu a:hover {
                color: nth($blue-shades, 12);
                background: nth($green-shades, 19)!important;
                color: nth($blue-shades, 12);
                cursor: pointer;
            }
            .dropdown-toggle:after {
                display: inline-block;
                @include height-width(0px, 0px);
                margin-left: 0.3em;
                vertical-align: middle;
                content: "";
                border-top: 0.3em solid;
                border-right: 0.3em solid transparent;
                border-left: 0.3em solid transparent;
                float: right;
                margin-top: 10px;
                font-size: $font-sizes-14;
            }
        }
    }
    .nav-link {
        &.font-size {
            font-size: $font-size-base;
        }
    }
    &.career-jr-tabs {
        .ngb-tabset .nav {
            background-color: nth($green-shades, 8);
        }
        .nav-link.active + .tab-down-arrow {
            border-top: 5px solid nth($green-shades, 8);
        }
        .ngb-tabset-mobile .nav {
            background-color: nth($green-shades, 8);
            .show > .dropdown-menu {
                background-color: nth($green-shades, 8);
            }
        }
    }
}


/*********** End of  Mobile tabs   ***********/


/*********** Start Waves tabs   ***********/

#wages {
    .nav-link {
        background: nth($grey-shades, 8);
        padding: 6px 9px;
        @include common-border(2px, 2px, 2px, 2px, nth($grey-shades, 8));
        margin-left: 12px;
        @include position (relative, $top: 0px, $left:1px);
        -webkit-transition: background-color .17s linear;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        cursor: pointer;
        color: $black;
    }
    .nav-link.active {
        background: $white!important;
        @include common-border(2px, 2px, 2px, 2px, nth($grey-shades, 8));
        border-bottom: none;
        z-index: 2;
        margin-top: 3px;
    }
    .nav-tabs {
        border: none;
    }
}
/*********** End of Waves tabs   ***********/


 /** Dashboard tabs start **/
 .dashboard-tab {  
    .nav-menu {
        padding: 5px 3px; 
        margin-right: 10px;
        @include custom-device(320px,480px){
        margin-right: 0px;
        text-align: left;
        }           
        color: nth($blue-shades, 11);
        border: none;
        background-color: transparent;
        cursor: pointer;
        &.active { 
            position: relative;           
            &::after {
                width: 100%;
                position: absolute;
                content: "";
                height: 3px;
                background-color: $secondary_color;
                left: 0;
                bottom: 0;
            }
        }
     }       
    .card {        
        @include media-breakpoint-only (md) {
            flex-basis: 48.8%;
        }
        .favourate-card {
            border-bottom: 1px solid transparent;  
            height: 70px;
            padding: 4px 10px;
@include custom-device(320px,480px){
    padding: 4px 4px;
}
            .custom-control {
                margin: 0;
                height: 40px;
                .custom-control-indicator {
                    width: 30px;
                    height: 30px;
                    border-radius: 0;
                    background-color: transparent;
                    border: 1px solid nth($black-shades, 7);
                }
                .custom-control-description {
                    font-size: $font-size-sm;
                    padding-left: 12px;
                }
                &.disabledp2 {
                    cursor: not-allowed;
                    pointer-events: all;
                }
            }
            .custom-control-input:checked ~ .custom-control-indicator {
                background-color: transparent;
                border: 1px solid nth($black-shades, 7);
                background-image: url(/assets/images/rc-tick-mark.png);
            }
            .custom-control-input:active ~ .custom-control-indicator {
                background-color: transparent;
                border: 1px solid nth($white-shades, 11);
            }
            .custom-control-input:focus ~ .custom-control-indicator {
                box-shadow: none;
            }
            .card-text-tiles {
                display: flex;
                align-items: flex-end;
                i {
                    font-size: $font-sizes-12;
                    color: nth($green-shades,20);
                    line-height: 30px;
                }
            }
        }   
        .favourate-card-body {         
            margin-bottom: 15px; 
            position: relative;               
            h4 {
                font-size: $font-size-sm;
                font-weight: $font-weight-bold;
                span {
                    letter-spacing: 0.25px;
                }
            } 
            .score-box_value {
                font-size: $font-size-base + $font-size-xs;
                font-weight: $font-weight-bold;
                line-height: inherit;
                color: nth($black-shades, 7);
            }           
            .score-box {
                background-color: nth($white-shades, 11);
                text-align: center;                
                width: 110px;        
                border-radius: 0 5px 5px 0;
                font-weight: $font-weight-bold;
                border: 1px solid #6f6f6f;  
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;                     
                .score-box_status {
                    font-size: $font-size-xs;
                    color: nth($black-shades, 9); 
                    line-height: 14px;
                    display: inline-table;
                    @include position (relative, $top: -5px);  
                }
            }
            
            .card-edit {                
                position: absolute; /* This style for edit button(Icon) particular exact position to dispay the corner in that card-body */
                width: 21px;
                height: 39px;
                transform: rotate(135deg);                
                top: -17px;
                right: -4px;
                border-top: 26px solid transparent;
                border-bottom: 26px solid transparent;
                border-right: 26px solid nth($grey-shades, 17);            
                cursor: pointer;
                .card-edit_btn {
                    position: absolute;  /* This style for edit button(Icon) particular exact position to dispay the corner in that card-body */
                    height: 8px;
                    width: 23px;
                    top: -5px;
                    left: 5px;                    
                    cursor: pointer;
                    background: transparent;
                    border: none;
                    &:after {
                        position: absolute;  /* This style for edit button(Icon) particular exact position to dispay the corner in that card-body */
                        content: "\E07B";
                        font-family: plpicons;
                        color:  nth($blue-shades, 30);
                        transform: rotate(225deg);
                        top: -7px;
                        font-size: $font-size-sm;
                        left: 3px;
                    }
                    &:focus {
                        outline: none;
                        box-shadow: inherit;
                    } 
                }                               
            }
            .budget-view {
                font-size: $font-size-sm;
            }
            .custom-scroll {
                height: 290px;
                margin: 32px auto 0;
                padding: 0 5px;
                @include media-breakpoint-up(sm) {
                    width: 96%;
                    padding: 0;
                }
                hr {
                    border-top: 1px solid nth($orange-shades,5);
                }
            }
            .bordered {
                @include height-width(150px, 150px);
                line-height: 130px;
                .inner-border {
                    @include position(absolute, $top: 8px, $left:8px);
                    height: 89%;
                    width: 89%;
                    font-size: 38px;
                }
            } 
            // .rc-select-career {
            //     width: 120px;
            //     display: inline-table
            // }
            .border-color {
                border: 1px solid nth($blue-shades,11);
                border-radius: 5px 0 0 5px;
                border-right: none;
                width: 80%;
                @include media-breakpoint-up(sm) {
                    min-height: 72px;
                }
                img {
                    width: 30px;
                }
                i {
                    font-size: $font-size-xs + $font-size-xs;
                }
                .card-text {
                    font-size: $font-size-sm;
                    @include media-breakpoint-up(sm) {
                        width: 80%;
                    }
                    span a{
                        color: nth($green-shades ,20);
                    }
                }
            }    
        }
    }
    .best-career-tab {
        .card {
            flex-basis: 48.8%;
            border: 2px solid #cedae3;
            padding-bottom: 60px;
        }
        .best-career-card {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            align-items: center;
            border-bottom: 1px solid #b2c2ce;
            .count {
                width: 40px;
                height: 40px;
                background: nth($green-shades, 20 );
                text-align: center;
                line-height: 40px;
                border-radius: 50%;
                font-size: 20px;
                font-weight: bold;
                color: white;
                @include media-breakpoint-down(lg){
                    position: absolute;
                }
            }
            .title {
                font-size: 20px;
                font-weight: bold;
                color: nth($green-shades, 20 );
                cursor: pointer;
                @include media-breakpoint-down(lg){
                    padding-left: 45px;
                }
            }
        }
        .best-career-card-body{
            .career-icon {
                align-items: center;
                display: flex;
               i{
                font-size: $font-sizes-16
                   
               }
           }
            a{
                text-decoration: underline;
                color: nth($green-shades, 20 );
            }
        }
    }
    .report-title-header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        img {
            width: 30px;
            // margin-right: 10px;
        }
        .ideas-icon{
            width: 20px;
            height: 32px;
          }
        span {
            color: nth($black-shades, 8);
            font-weight: $font-weight-bold;
            padding-top: 3px;            
        }
        .plan-name {
            font-size: $font-size-sm;
        }
    }
    .abilities-title {
        font-size: $font-size-sm;
        font-weight: $font-weight-bold;
        letter-spacing: 0.25px;
    }
    .saved-card {
        border: 1px solid nth($black-shades, 13);
    }
    .completed {
        border-bottom: 5px solid green;
    }
    .started {
        border-bottom: 5px solid orange;
    }
    .started1 {
        border-bottom: 5px solid #dd7c09
    }
    .incomplete {
        border-bottom: 5px solid #646262
    }
    .description {
        font-size: 14px;
    }
    .close-career-list {
        color: nth($red-shades,4);
        cursor: pointer;
        font-size: $font-size-lg + $font-size-sm;
    }
    .no-data-align {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .actions li:nth-child(1) {
        background: $secondary_color;
    }
    &.report-pdf {
        .card {
            .favourate-card-body {
                .rc-select-career {
                    width: auto;
                    font-size: $font-size-sm;
                }
            }
        }
    }
}

.dashboard-data-modal {
    .modal-dialog {
        max-width: 500px;       
        margin: 0 auto;
        .modal-content {
            border: 2px solid nth($sky-blue, 6);
            border-radius: 10px;
            
            .modal-header {
                background-color: nth($grey-shades, 3);
                border-radius: 10px 10px 0 0;
                border-bottom: 2px solid nth($sky-blue, 6);
                padding: 5px 10px;     
            }
            .modal-title {
                font-size: $font-size-sm;
                font-weight: $font-weight-bold;
            }   
            .modal-body {
                padding: 0 5px 0 10px;
                margin: 10px 5px 10px 0;
                .card {
                    background-color: nth($white-shades, 12);
                    border-radius: 5px;
                    margin: 0 5px 10px 0;
                    .card-delete-block {
                        background-color: $white;
                        border-radius: 10px;
                        color: nth($black-shades, 10);
                        .card-delete-title {
                            border-bottom: 1px solid nth($white-shades, 13);
                            text-align: center;
                            padding: 0px 13px;
                            width: 55%;
                            border-right: 2px solid nth($white-shades, 13);
                            justify-content: left;
                            @media(max-width:575px){
                                width: 100%;
                                padding: 10px 13px;
                                border-right: none;
                                justify-content: center;
                            }
                        }                        
                        .card-delete-block__date {
                            font-size: $font-size-sm;
                            color: nth($black-shades, 11);
                            text-align: center;
                            // padding: 0 10px;
                            // float: left;
                            width: 25%;                            
                            border-right: 2px solid nth($white-shades, 13);
                            }
                        .card-delete-block__delete {
                            font-size: $font-size-sm;
                            color: nth($black-shades, 11);                            
                            text-align: center;
                            // line-height: 16px;
                            // padding-top: 4px;
                        }
                        .form-control {
                            border: none;
                            border-radius: 10px;
                            resize: none;
                            height: 75px; /* This style for set the perfect height to textarea field for support the all browsers and textarea is not overlay on the modal box at one answer set */ 
                        }
                        .card-delete-block__date{
                            @include custom-device(320px, 575px){
                                width: 100%;
                                border-right: none;
                                border-bottom: 1px solid nth($white-shades, 13);
                            }
                        }
                        .card-delete-block__delete {
                            &.restore{
                                @include custom-device(320px, 375px){
                                    float: left;
                                    border-right: 1px solid nth($white-shades, 13);
                                    padding-left: 8px;
                                    padding-right: 8px;
                                    .saved-restore-button img{
                                        width: 14px;
                                    }
                                }
                                @include custom-device(320px, 575px){
                                    float: left;
                                    border-right: 1px solid nth($white-shades, 13);
                                    padding-left: 16px;
                                    padding-right: 16px;
                                    width: 50%;
                                    .saved-restore-button img{
                                        width: 14px;
                                    }
                                }
                            }
                        }
                        @include media-breakpoint-up(sm) {
                            display: flex;
                            // align-items: center;
                            justify-content: flex-start;
                            .card-delete-title {
                                border-bottom: none;
                                text-align: left;
                                // padding: 0 0 0 10px;
                                // width:200px;
                                overflow-wrap: anywhere;
                                // width: 170px;//Removed as it was causing button allignment issue.
                            } 
                            .card-delete-block__date {
                                // padding: 0 24px;
                                // float: none;
                                // width: inherit;
                                // border-left: 1px solid nth($white-shades, 13);
                            }
                            .card-delete-block__delete {
                                // position: relative;
                                // left: -26px;
                                // padding-top: 0;
                                margin: 0px auto;
                                padding: 0px 14px;
                                &.restore{
                                    border-right: 1px solid nth($white-shades, 13);
                                    padding: 0px 14px;
                                    left: -14px;
                                }
                                .saved-restore-button img{
                                    width:16px;
                                }
                            }
                        }                   
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                &.custom-scroll {
                    height: 300px;
                }
                @include media-breakpoint-up(sm) {
                    &.custom-height{
                        height: 100%;
                    } 
                }                                           
            }
        }
    }   
    button {
        background: transparent;
        border: none;
        cursor: pointer;
        &:focus {
            outline: none;
            box-shadow: inherit;
        }
    }
    .modal{
        overflow-y: hidden;        
        &.fade.show {
            display: flex !important;
            align-items: center;
        }
    }
    &.modal{
        overflow-y: hidden;        
        &.fade.show {
            display: flex !important;
            align-items: center;
        }
    }
}
.modal-backdrop {
    z-index: 1040 !important;
}
   /** Dashboard tabs End **/
   @include media-breakpoint-down(md) {
.bestcareer-list-dropdown {
        border: none;
    width: 100%;
    background: $secondary_color;
    color: $white;
    font-weight: 300;
    border-top: 1px solid;
    cursor: pointer;
    padding-left: 0px;

   }
}
.bestcarrer-footer{
    position: absolute;
    bottom: 5px;
}
.dashboard-dropdown{
    position: absolute;
    width: 100%;
    z-index: 100;
    display: none;
    top: -3px !important;
    
    a div:hover {
            background-color: rgb(2, 117, 216)
        }
}

.show > .dashboard-dropdown{
    display: block;
}
 .dashboard-toggle::after {
    display: inline-table;
    margin-top: 8px;
    margin-right: 10px;
    content: "";
    border-top: 8px solid;
    border-right: 8px solid transparent;
    float: right;
    border-left: 8px solid transparent;
}
.dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;

    a:hover {
        background-color: rgb(2, 117, 216)
    }
}

.details-dropdown-menu {
    margin-top: -2px !important;
}