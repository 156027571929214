/****************************
* Add and Reset circle button test score start
****************************/
.blue-color {
    color: nth($blue-shades, 2);
}

.test-score {
    .circle-btn-testScore {
        @include height-width(30px, 30px);
        @include common-border-radius(50%!important, 0px);
        text-align: center;
        vertical-align: middle;
        padding: 0px!important;
        z-index: 3;
        margin-left: 10px!important;
        box-shadow: 1px 2px 2px nth($grey-shades, 7);
        i {
            padding-top: 8px;
            color: $white;
        }
    }
    .testScoreBtnDisable{
        background-color: #ccc;
        pointer-events: none;
       // box-shadow: 1px 2px 2px #888888;
    }
    .testScore-table {
        word-wrap: break-word;
        @include height-width(90%, auto);
    }
    .mobile-cards {
        height: 100px;
        .form-control { width:90%; }
    }
}

.common-button {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    padding: 8px 8px 6px;    
    @include common-border-radius(2px, 0);
    cursor: pointer;
    @include common-header($white, $font-sizes-3, center, 700);
    letter-spacing: 0;
    margin: 0 0 10px 5px;
    outline: 0 none;
    position: relative;
    text-decoration: none;
    // text-transform: capitalize;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1) 0s, background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    will-change: box-shadow, transform;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    min-width: 79px;
    &.green {
        background: $secondary_color;
    }
    &.blue {
        background: nth($blue-shades, 5);
    }
    &.red {
        background-color: nth($red-shades, 1);
        border-color: nth($grey-shades, 25);
        font-size: $font-sizes-4;
        margin: 0;
        i {
            color: $white;
        }
        @include media-breakpoint-down(sm) {
            width: $width-value;
            min-width: 75px;
        }
    }
    &.orange {
        background: nth($orange-shades,12);
    }
    &.btn-primary1 {
        color: nth($grey-shades, 27)!important;
        background-color: nth($blue-shades, 17)!important;
        border-color: nth($blue-shades, 17)!important;
        box-shadow: none!important;
        font-size: $font-sizes-3;
        height: 37px;
    }
    &.default {
        border: none;
        box-shadow: none;
        background: none;
        min-width: 0;
    }
    &.select-state-btn {
        background: nth($blue-shades, 12);
        height: 66px;
        @include common-border-radius(0px, 0);
        border: none;
        color: $white;
    }
    .btn-group & {
        @include media-breakpoint-down (sm) {
            margin: 5px 0px;
        }
    }
    &.btn-green-plp3 {
        height: 36px;
        line-height: 36px; //The height, and line-height both are more specific to set any text in a particular box (i.e. button, etc..) to support all browsers line-height is helping to align exactly.
        padding: 0;
        text-transform: none; // To lower case the button text on header section while translating into Spanish
        a {
                color: #fff;
            }
    }
    @media(max-width:340px){
        font-size: 0.820em;
    }
    
}
@include media-breakpoint-down(sm){
.plp-common-button {
    margin: 2px 0px 3px 0px;
}
}

//the ExportPdf button on the mydashboard page is disabled till nothing is checked. Previously the button even though in disabled mode was not
//different from active mode(appearance).
//In order to bring out the difference, I have used opacity for the button. So, when the button is in disabled mode the style makes sure that not 
//only the functionality is disabled(due to disabled attribute), it appears to be disabled.
.common-button:disabled {
    cursor: not-allowed;
    opacity: .35;
}

.btn-common {
    padding: 0.5rem 1rem;
    line-height: 1.45;
    border: none;
    &.btn-success {
        background-color: $secondary_color;
        color: white;
    }
}

.btn-common:disabled {
    cursor: not-allowed;
    opacity: .65;
}

.btn-group-styles {
    @include common-border(1px, 1px, 1px, 1px, nth($blue-shades, 17));
    @include common-border-radius(2px, 1px);
}


/***************Justified button groups*******************/

@include media-breakpoint-down (sm) {
    .btn-group-justified {
        display: flex;
        width: $width-value;
        @media(max-width:500px){
            display: inline-block;
        }
        .btn,
        .btn-group {
            flex: 1;
            box-shadow: none;
            margin-right: 0.4em;
            // @include common-border(0px, 0.2em, 0px, 0px, $white);
           
            .btn {
                font-size: none;
                width: $width-value;
            }
            // &:hover {
            //     @include common-border(0px, 1px, 0px, 0px, $white);
            // }
        }
    }
    // .btn {
    //     white-space: normal!important;
    // }
}


/******************************************
* Add and Rest circle button test score end
******************************************/


/***************
* fab button
****************/

.float-btn-plp3 {
    margin: auto;
    z-index: 999;
    @include position(fixed, $left: 20px, $bottom: 52px);
    @include media-breakpoint-down(xs) {
        left: 50px;
    }    
    &.left-scroll-top-btn {
        @include position(fixed, $left: 130px, $bottom: 130px);
        @include media-breakpoint-down(xs) {
            left: 20px;
            bottom: 60px;
        }
    }
}

.action-button {
    @include height-width(45px, 45px);
    @include position(absolute);
    @include common-border-radius(50%, 1px);
    border: 0;
    outline: 0!important;
    background: nth($orange-shades, 3);
    font-size: $font-size-sm;
    color: $white;
    z-index: 2;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    -webkit-transition: all .3s;
    transition: all .3s;
    &.occ-list-arrow{
        position: fixed;
        bottom: 57px;
        right: 25px;
        margin: 0 20px;
        span{
            font-size: 16px;
            padding: 0;
        }
    }
}

.actions {
    @include position(absolute, $top: 8px, $right: 0px, $left:0px,);
    list-style: none inside none;
    margin: 0;
    padding: 0;
    float: left;
    background-color: transparent;
    z-index: 1;
    // @include height-width(40px, 40px);
    @include common-border-radius(50%, 1px);
    & li {
        position: absolute;
        display: block;
        @include height-width(45px, 45px);
        @include common-border-radius(50%, 1px);
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        margin: 0;
        background: nth($black-shades, 5);
        color: $white;
        -webkit-transition: all .3s;
        transition: all .3s;
        -webkit-transform: scale(0.3);
        transform: scale(0.3);
        &:nth-child(1) {
            background: nth($orange-shades, 4);
        }
        &:nth-child(2) {
            background: nth($blue-shades, 18);
        }
        &:nth-child(3) {
            background: nth($yellow-shades, 1);
        }
    }
}

.actions li a {
    background: inherit;
    color: inherit;
    display: block;
    @include height-width(45px, 45px);
    @include common-border-radius(50%, 1px);
    line-height: 40px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    button {
        border: 50px;
        background: transparent;
        outline: none;
    }
}

.actions li:active {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19), 0 8px 17px 0 rgba(0, 0, 0, 0.2);
}

.action-button.active {
    box-shadow: 0 17px 50px 0 rgba(0, 0, 0, 0.19), 0 12px 15px 0 rgba(0, 0, 0, 0.24);
}

.action-button.active~.actions li {
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.action-button.active~.actions li:nth-child(1) {
    margin-top: -56px;
}

.action-button.active~.actions li:nth-child(2) {
    margin-top: -104px;
}

.action-button.active~.actions li:nth-child(3) {
    margin-top: -152px;
}

.action-button span {
    -webkit-transition: all .3s;
    transition: all .3s;
    // padding-top: 6px;
}

.action-button.active:not(.no-rotate) span {
    -webkit-transform: scale(1.5) rotate(-45deg);
    transform: scale(1.5) rotate(-45deg);
    // padding-top: 2px;
    font-size: $font-sizes-1;
}

// .common-enable-ques-arrow {
//     background: none!important;
//     border: none;
//     right: 15px;
//     width: 25px;
//     position: relative;
//     height: 25px;
//     top: 2px;
// }

// .common-disable-ques-arrow {
//     @extend .common-enable-ques-arrow;
//     height: 0px;
//     top: -16px;
//     right: 18px;
// }
.common-enable-ques-arrow {
    background: none!important;
    border: none;
    right: 5px;
    width: 22px;
    position: relative;
    height: 22px;
    &:focus{
        box-shadow: none;
    }  
}

.common-disable-ques-arrow {
    @extend .common-enable-ques-arrow;  
    display: flex;
    align-items: center; 
}
.btn-hidden-wil {
    @extend .btn-visible-wil;
    pointer-events: none;
    opacity: 0.4;
    outline: none!important;

}

.btn-visible-wil {
    border: none;
    //right: 15px;
    position: relative;
    height: 37px;
    top: 2px;
}

.btn-primary-outline {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
}

.school-compare-btn {
    height: 43px;
}

/***** floating point plp3 end ****/
