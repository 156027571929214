/* Top navigation  */
.logo-navbar {
  background-color: $primary_color;
  height: 55px;
  @include media-breakpoint-up(lg) {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;    
  }
  /* To display the header names bold **/
  a {
    font-weight: bold
}
  .navbar-toggleable-md {
    a {
      color: $white;
    }
    
    /* @media lg desktop **/
    @include media-breakpoint-up(lg) {
      .navbar-menu {
        display: flex !important;
      }
      .navbar-nav {
        flex-direction: row;
        
        .nav-link {
          cursor: pointer;
          padding: 0;
          margin: 0;
          font-size: $font-sizes-4;
          &:last-child {
            margin-right: 0;
            padding-right: 0;
          }          
        }
        .login-menu-desktop p {
          line-height: 20px;
        }
      }
      .nav-list-active {
        @include common-border(0, 0, 3px, 0px !important, $secondary_color);
        @include common-border-radius(0, 0);
      }
      .navbar-toggler {
        display: none;
      }
      .navbar-menu-mobile {
        display: none;
      
      }
    }
    /* @media xl desktop **/
    @include media-breakpoint-up(xl) {
      .navbar-nav .nav-link {
        padding: 0 5px;
        margin: 0 5px;
      }
    }
    @include media-breakpoint-only(lg) {
      .navbar-nav {
        .nav-link {
          margin-right: 14px;
        }
      }      
    }
                /* @media lg **/
                @include media-breakpoint-down(lg) {
      height: auto;
      padding: 7px 0px 23px 0;
      .navbar-menu-mobile {
        display: flex;
        position: absolute;
        top: 18px;
        .mobile-icon-align {        
          z-index: 3;
          cursor: pointer;
          background-color: transparent;
          box-shadow: none;
          border: transparent;
          outline: none;
          padding: 0;
          i {          
            color: $white;
            font-size: 20px;
            margin-top: 40px;
          }
        }
      }
      .navbar-menu {
        z-index: 2;
        background: $primary_color;
        @include position (absolute, $top: 10, $right: 0);
        @include height-width(100%, auto);
        
        a {
          padding: 14px 20px;
          @include common-border(0, 0, 1px, 0, $white);
          &:last-child {
            border: 0;
          } 
&:hover {
  background-color: rgb(2, 117, 216)
}
        }
        .login-menu-desktop {
          display: none !important; //This style for hide the logout button only in mobile view through LTI login
        }
      }
    } /*End  @media sm **/
  }
}
/* Start  Side-menu  */
.side-menu {
  @include position (fixed, $top: 95px, $left: 0);
  // overflow: hidden;
  z-index: 1020;
  @include media-breakpoint-up(lg) {
    z-index: 1050;
    top: 95px;
  }
    .btn-light {
      --bs-btn-bg: none;
      border: none;
    }
  .widget-side-menu{
  background-color: $plan_header_color;
  @include height-width(55px, 46px);
  line-height: 20px;
  z-index: 1050;
  @media (max-width: 767px){
    @include height-width(40px, 40px);
    }
  /*Start  @media xl **/
    @include large-device(1768px) {
    overflow: visible!important;
    @include height-width(320px, 50px);
    .sidemenu-t {
      width: 320px;
    }
    .side-menu-text {
      color: $white;
      border: 1px solid blue;
    }
    .main-menu {
      white-space: normal !important;
    }
  }
}
.side-menu-text{
  .dropdown-toggle {
    &:after {
      position: absolute;/* For arrow position set with these styles in that child boundary with stright horizontical right place because of this down arrow created by pseudo css element */
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      border-top: 0.5em solid;
      border-right: 0.5em solid transparent;
      border-left: 0.5em solid transparent
    }
  }
  .dropdown-menu {
    background-color: $plan_header_color;
    margin-top: 6px !important;
      margin-left: -5px !important;
    //height: 242px;
    @include media-breakpoint-up(md){
    margin-top: 5px !important;
    }
  }
  .dropdown-menu,
  .btn{    
    white-space: normal;
    color: $white;
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;
    z-index: 999;
    
  }
  .dropdown-menu button{
    box-shadow: none; 
    border: none;
    background-color: none;
  }
  .grade-plan {
    background-color: transparent;
    border: none;
    color: $white;
    width: 95%;
    height: auto;
    //overflow-y: auto;
    white-space: normal;
    @media (max-width: 767px){
      font-size: $font-sizes-4;
    }    
  } 
}
  /*End  @media xl **/
  .fa-micon-menu {
    @include common-header($white, $font-sizes-7, center, inherit);
    width: 83px;
    @media (max-width: 570px) {
        width: 40px;
        font-size: 1em;
        margin-top: 10px;
      }
    @media (max-width: 767px){
      width: 40px;
      font-size: 1em;
    }
    @media (min-width:1768px){
      width: 62px;
    }
  }
  .fa-micon {
    @extend .fa-micon-menu;
    line-height: 38px;
    &.fa-check {
      width: 20px;  
    }
  }
  .main-menu {
    white-space: nowrap;
    background: $white;
    @include position (fixed, $top: 137px, $left:0, $bottom:0);
    overflow: hidden;
    -webkit-transition: width .2s linear;
    transition: width .2s linear;
    -webkit-transform: translateZ(0) scale(1, 1);
    @include common-border-shadow(5px, 0px, 15px, -6px, $black, 0);
    opacity: 1;
    z-index: 0;
    height: auto;
    @include media-breakpoint-only(md){
      top: 140px;
    }
    @media (max-width: 767px){
      top: 134px;   
    }
    ul {
      @include common-border(0, 1px, 0, 0, nth($white-shades, 7));
      margin: 0px;
      padding: 0px;
    }
    li {
      display: block;
      border-bottom: 1px solid nth($grey-shades, 25) !important;
      @include position (relative);
      height: 40px;
      &:first-child {
        border-top: 1px solid nth($grey-shades, 25) !important;
      }
    }
    a {
      @include position (relative);
      @include common-header($white, $font-sizes-3, none, none);
      cursor: pointer;
      text-decoration: none;
      -webkit-transform: translateZ(0) scale(1, 1);
      -webkit-transition: all .14s linear;
      transition: all .14s linear;
    }
    .nav-text {
      color: $black;
      padding-left: 5px;
      width: 100%;
      padding-right: 10px;
      line-height: 18px;
    }
    .fa-check::before {
      color: nth($green-shades, 2);
    }
    .menu-active {
      @include common-border-shadow (0px, 1px, 6px, 1px, nth($blue-shades, 17), 0);
      @include position (relative);
      z-index: 99;
      &.svg-images {
        &:before {
          @include position (absolute, $top: 50%, $left:0);
          border-bottom: 10px solid transparent;
          border-left: 10px solid $white;
          border-top: 10px solid transparent;
          content: " ";
          display: block;
          @include height-width(0px, 0px);
          margin-top: -11px;
          z-index: 99;
        }
      }      
    }
    .menu-span-bg-box {
      @include height-width(55px, 40px);
      display: block;
      @include common-border(0, 0px, 1px, 0, nth($green-shades, 2));
      background: nth($green-shades, 1);
      color: $white;
    }
    .menu-filled {
      background: nth($grey-shades, 27);
      border-bottom: nth($grey-shades, 25);
    }
    .menu-not-filled {
      background: nth($blue-shades, 4) !important;
      display: block;
      @include common-border(1px, 0px, 1px, 0, nth($blue-shades, 1));
      @include height-width(55px, 40px);
    }
	
    .menu-completed {
      @include height-width(55px, 40px);
      display: block;
      @include common-border(0, 0px, 1px, 0, nth($blue-shades, 1));
      background: nth($green-shades, 47) !important;
      color: $white;
    }
    .menu-incomplete {
      @include height-width(55px, 40px);
      display: block;
      @include common-border(0, 0px, 1px, 0, nth($blue-shades, 1));
      background: nth($grey-shades, 13);
      color: $white;
      position: relative;
    }
    .menu-inprogress {
      background: nth($orange-shades, 6) !important;
      display: block;
      color: $white;
      @include common-border(0, 0px, 1px, 0, nth($blue-shades, 1));
      @include height-width(55px, 40px);
    }
  }
  nav.main-menu.expanded, .widget-side-menu.expanded {
    overflow: visible!important;
    opacity: 1;
    width: 325px;
    white-space: normal;    
    @media (max-width:767px){
      width: 300px;
    }
    .side-menu-text {
      opacity: 1;
    }
  }
  a.widget-side-menu {
    text-decoration: none;
    display: block;
    height: 49px;
  }
    @include media-breakpoint-down(md) {
    .main-menu {
      width: 0px;
    }
    .widget-side-menu {
      font-size: $font-sizes-5;
    }
  }
  @include custom-device(768px, 1767px) {    
    .main-menu,
    .widget-side-menu {
      width: 85px;
    }
  }
  @include custom-device(992px, 1767px) {    
    .main-menu {
      margin-bottom: 70px;
    }
  }
  @media (max-width:1767px) {
    .side-menu-text {
      opacity: 0;
    } 
  /* Style for Menu hover */  
  .main-menu  {
      li {
      &.white-color {       
        &:hover {
          z-index: 6;
        }
      }
    }
      &:hover {
        width: 325px;
        white-space: normal; 
      }
    } 
 /* End style for Menu hover */   

  }
  @media (min-width: 1768px){
    .main-menu {
      overflow: hidden;
      opacity: 1;
      width: 320px;
      white-space: normal;      
    }
  }
  .side-menu-text span {
    color: $white;
    @media (max-width: 767px){
      font-size: $font-sizes-4;
    } 
  }
  /* Start scrolling  */
  .side-menu-scroll-bar,
  .side-menu-scroll-bar:hover {
    @include position (relative);
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 10px;   
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }                    
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 10px;    
    }
  }
  .side-menu-scroll-bar {
    height: 100%;
  }
  .scroll-innter {
    height: auto;
  }
  @media (min-width: 1768px){
    .main-menu {
      height: calc(100vh - 215px);
    }
  } /* End of scrolling  */

}
/********************************************/