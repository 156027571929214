/* RESET STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* CHART-SKILLS STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */



.loc-button-color {
	padding:10px;
	border:2px solid white;
	color:white;
	border-radius:5px;
}
.eo-location-button {
	list-style-type: none;
	margin:0px;
	padding:0px;
}
.eo-location-card-data {
	display: flex;
	justify-content: space-between;
	font-size: 15px;
}
.eo-location-card-graph li {
	height:48px!important;/** This style for adjust the inline bar-graphs with no gap at bottom place **/
	padding:0 42px;
}
 .eo-location-card-data li:nth-child(2) img {
 float: right;
}


/* ANIMATIONS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@keyframes rotate-one {
 100% {
 transform: rotate(56.6deg);
/**
     * 12% => 21.6deg
     */
  }
}
 @keyframes rotate-two {
 0% {
 transform: rotate(56.6deg);
}
 100% {
 transform: rotate(135.2deg);
/**
     * 32% => 57.6deg 
     * 57.6 + 21.6 => 79.2deg
     */
  }
}
 @keyframes rotate-three {
 0% {
 transform: rotate(135.2deg);
}
 100% {
 transform: rotate(180.4deg);
/**
     * 34% => 61.2deg 
     * 61.2 + 79.2 => 140.4deg
     */
  }
}
 @keyframes rotate-four {
 0% {
 transform: rotate(140.4deg);
}
 100% {
 transform: rotate(180deg);
/**
     * 22% => 39.6deg  
     * 140.4 + 39.6 => 180deg
     */
  }
}
 @keyframes fade-in {
 0%,  90% {
 opacity: 0;
}
 100% {
 opacity: 1;
}
}
/* End desktop half circle end */

/* Start desktop  chart end */



.chart-mini {
	display: table;
	table-layout: fixed;
	width: 100%;
	max-width: 50px;
	margin: auto;
	padding: 0px;
	position: relative;
	float:right;
	overflow: hidden;
}
.chart-mini-1 {
	display: table;
	table-layout: fixed;
	width: 100%;
	max-width: 44px;
	margin: 0;
	padding: 0px;
	right: 0px;
	position: relative;
	float: right;
}

.chart-mini li {
	position: relative;
	display: table-cell;
  vertical-align: bottom;
	height: 92px;
	padding: 0px;
	margin: 0;
}
.chart-mini-1 li {
	position: relative;
	display: table-cell;
    vertical-align: bottom;
	height: 92px;
	padding: 0px;
	margin: 0;
}

.chart-mini span {
	margin: 0px;
	display: inline-table;
	animation: draw 1s ease-in-out;
	width: 100% !important;
}

.chart-mini-1 span {
	margin: 0px;
	display: block;
	animation: draw 1s ease-in-out;
	width: 15px !important;
}


 .chart-mini li:nth-child(1) span {
 background: #d4d4d4;
 height:20%;
 width:15px;
}
 .chart-mini-1 li:nth-child(1) span {
 background: #d4d4d4;
 height:50%;
 width: 21px;
}

.chart-mini li:nth-child(2) span {
 background: #a2a2a2;
 height:40%;
 width: 15px;
}
.chart-mini-1 li:nth-child(2) span {
 background: #a2a2a2;
 height:70%;
 width: 15px;
}

.chart-mini li:nth-child(3) span {
 background: #747474;
 height:100%;
}
.chart-mini-1 li:nth-child(3) span {
 background: #747474;
 height:100%;
}


.activeChartClass0 {
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	margin-top: 8px !important;
	margin: auto;
	top:14px!important;
	left:0px!important;
	transform:rotate(-113.6deg)!important;
	border-top:10px solid #888888;
}
.activeChartClass1 {
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	margin-top: 8px !important;
	margin: auto;
	top:30px!important;
	left:9px!important;
	// transform: rotate(-90.6deg) !important;
	border-top:10px solid #0d8dbe;
}
.chart-mini li:nth-child(3) span {
 background: #d4d4d4;
 height:60%;
 width:15px;
}
.chart-mini li:nth-child(4) span {
 background: #a2a2a2;
 height:80%;
 width:10px;
}
.chart-mini li:nth-child(5) span {
 background: #d4d4d4;
 height:100%;
 width:7px;
}
.chartColor {
	background: #0d47a1;
}

.bar-1 {
	background: #a2a2a2;
	height: 26px;
}
.bar-2 {
	background: #0d47a1;
	height: 26px;
}
.chart-mini span:before {
	position: absolute;
	left: 0;
	right: 0;
	top: 100%;
	/*! padding: 5px 1em 0; */
  display: block;
	text-align: center;
	content: attr(title);
	word-wrap: break-word;
}
@keyframes draw {
 0% {
 height: 0;
}
}
/* End desktop  chart end */



 @media only screen and (max-width: 767px) {
 .eo-location-card {
 border:1px solid #ccc;
 border-top: none;
 box-shadow: none;
}

}      


 @media only screen and (min-width: 768px) and (max-width: 1199px) {


.chart-mini {
 margin: 0px 0px;
}
}
tab-content h4 {
	padding-top: 20px!important;
	line-height: 24px;
}

 
 .plp3-model-close-align{
margin-left:auto;
outline:0!important;
}
.borderBottom{
	border-bottom: 2px solid red;
}