/* Color code variables */
/* Start */
$cp-green-shades: (
    1: #0c6b2d,
    2: #00796a,
    3: #2bb15a,
    4: #327f4c,
    5: #0e6c30,
    6: #1d763c,
    7: #0d6d2e,
    8: #04825d
);
$cp-gray-shades: (
    1: #bababa,
    2: #b7b7b7,
    3: #ccd0d3,
    4: #d9d9d9,
    5: #e3e3e3,
    6: #e4e2e3,
    7: #ebebeb,
    8: #f4f0f1,
    9: #f4f4f4,
    10: #595959,
    11: #545454,
    12: #535353,
    13: #515151,
    14: #5e5e5e,
    15: #505050,
    16: #696969,
    17: #9c9c9c,
    18: #929292,
    19: #989898,
    20: #969696,
    21: #c3c3c3,
    22: #555555,
    23: #6c6c6c,
    24: #c5c5c5,
    25: #cecece,
    26: #919191,
    27: #a5a5a5,
    28: #464a4c
);
$cp-red-shades: (
    1: #ce552a,
    2: #cc4d00,
    3: #d0542a,
    4: #dd7315,
    5: #f83a3a,
    6: #fe5400,
    7: #dc3545
);
$cp-blue-shades: (
    1: #006a92,
    2: #016891,
    3: #1784ad,
    4: #428ac9,
    5: #3e87a4,
    6: #027faf,
    7: #3382a1,
    8: #7AD7F0
);
$cp-light-shade: #fff; /* End */
$cp-radius: 5px;
@include media-breakpoint-down(sm) {
    .gutter-5 {
        padding: 0 5px;
    }
    .mob-hide {
        display: none;
    }
}
/* The below mixins code for using @include */
/* Start */
@mixin grid ($col, $mgn) {
    float: left;
    margin-right: $mgn;
    margin-bottom: $mgn;
    width: calc((100% - (($col - 1)*$mgn))/$col);
    &:nth-child(#{$col}n) {
        margin-right: 0;
    }
}
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
      @content;
    }
    @-moz-keyframes #{$animation-name} {
      @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
      }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
      @content;
    }
}  
@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
}
@include media-breakpoint-up(sm) {
    @include keyframes(slide-up) {
        from {right:-200px; opacity:0} 
        to {right:0; opacity:1}
    }
}/* End */
.no-more-data {
    height: 100vh;
    h2 {
        height: 100%;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $cp-light-shade;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.7);
        text-shadow: map-get($cp-gray-shades, 10) 4px 3px 4px;
        padding: 0 10px 0 10px;
    }
}
.course-planner {
    font-size: 14px;
    @include media-breakpoint-down(sm) {
        border: 2px solid #d9d9d9;
        border-radius: $cp-radius;
    }
    /* The below code for using @extend */
    /* Start */
    .text-ellipsis {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .border-with-radius {
        border: 2px solid map-get($cp-gray-shades, 4);
        border-radius: $cp-radius;
    }
    .scroll-y {       
        overflow-y: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            width: 7px;
            height: 15px;   
            background: url('/../assets/images/line.png') repeat;
            background-size: 100% 100%;
        }                    
        &::-webkit-scrollbar-thumb {
            background-color: map-get($cp-gray-shades, 27);    
            border-radius: 50px;
        }
    }/* End */
    
    /* The below code for percentage graph using @for scss functionality method */
    /* Start */
    $step: 1;
                $loops: round(calc(100 / $step));
                $increment: calc(360 / $loops);
                $half: round(calc($loops / 2));
    @for $i from 0 through $loops {
        .progress--bar[progress=" #{$i * $step}%"]:after {
        width: $i  $step  1%;
        } 
        .progress--circle[progress=" #{$i * $step}%"]:after {
        @if $i < $half {
            $nextDeg: 90deg + ($increment * $i);
            background-image: linear-gradient(90deg, #ccc 50%, transparent 50%, transparent), linear-gradient($nextDeg,#29b25a 50%, #ccc 50%, #ccc);
        }
        @else {
            $nextDeg: -90deg + ($increment * ($i - $half));
            background-image: linear-gradient($nextDeg,#29b25a 50%, transparent 50%, transparent), linear-gradient(270deg,#29b25a 50%, #ccc 50%, #ccc);
        }
        }
        .progress--circle[progress=" #{$i * $step}%"].inner:after {
            @if $i < $half {
                $nextDeg: 90deg + ($increment * $i);
                background-image: linear-gradient(90deg, #e8e8e8 50%, transparent 50%, transparent), linear-gradient($nextDeg,#29b25a 50%, #e8e8e8 50%, #e8e8e8);
            }
            @else {
                $nextDeg: -90deg + ($increment * ($i - $half));
                background-image: linear-gradient($nextDeg,#29b25a 50%, transparent 50%, transparent), linear-gradient(270deg,#29b25a 50%, #e8e8e8 50%, #e8e8e8);
            }
        }
    }/* End */
    .collapse.show,
    .collapse,a,button,a:hover,button:hover {
        transition: all 0.4s ease-in-out 0s;
    }
}
.course-planner-page-header {
    .graduation-block {   
        justify-content: space-around;
        @include media-breakpoint-up(md) {
            justify-content: center;
            border: 2px solid #d9d9d9;
            border-radius: $cp-radius;
        }        
        .credits-plan {
            .title {
                display: none;
                @include media-breakpoint-up(md) {
                    color: map-get($cp-gray-shades, 12);
                    font-weight: bold;
                    display: block;
                } 
            }
            .plans-list {
                font-weight: bold;
                @include media-breakpoint-down(sm) {
                    position: relative;
                    top: 5px;
                    left: -3px;
                }
                .list-credits {
                    color: map-get($cp-gray-shades, 13);
                    @include media-breakpoint-up(md) {
                        border: 2px solid #d9d9d9;
                        border-radius: $cp-radius;
                    }
                    .core-count {
                        font-size: 12px;                          
                        @include media-breakpoint-up(sm) {
                            font-size: 14px;
                            width: 85px;
                        }                                              
                        @include media-breakpoint-up(md) {
                            border-right: 2px solid map-get($cp-gray-shades, 4);
                            padding: 8px 0;
                        }
                    }
                    .elective-count {                                                
                        @include media-breakpoint-up(sm) {
                            font-size: 14px;
                            width: 85px;
                            padding: 0;
                        }                        
                        @include media-breakpoint-down(xs) {
                            font-size: 12px;
                        }
                        @media (max-width: 575px) {
                            padding: 0 20px;
                        }
                        @media (max-width: 479px) {
                            padding: 0 10px;
                        }
                        @media (max-width: 413px) {
                            font-size: 11px;
                            padding: 0 7px;
                        }                        
                    }
                    span {
                        color: map-get($cp-gray-shades, 10);                                                
                    }
                }
                .total-credits {                    
                    color: map-get($cp-green-shades, 7);  
                    @include media-breakpoint-up(sm) {
                        font-size: 14px;
                    }
                    @include media-breakpoint-down(xs) {
                        font-size: 12px;
                    }
                    @media (max-width: 413px) {
                        font-size: 11px;
                    }    
                    @include media-breakpoint-only(md) {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 6px 7px 7px !important;
                        span {
                            top: 0;
                        }
                    }                                                      
                    @include media-breakpoint-up(md) {
                        border: 2px solid map-get($cp-green-shades, 7);
                        border-radius: $cp-radius;
                        padding: 14px 7px;
                        margin-left: 7px;
                    }
                }                
                span {
                    font-size: 14px;
                    @include media-breakpoint-up(sm) {
                        font-size: 16px;
                    }                        
                    @include media-breakpoint-down(md) {                            
                        position: relative;
                        top: -4px;
                    }
                }
            }                
        }
        &.header-left {      
            @include media-breakpoint-down(md) {
                background-color: map-get($cp-gray-shades, 9);
                border-bottom: 2px solid map-get($cp-gray-shades, 4);
            } 
            @include media-breakpoint-up(md) {                
                height: 110px;        
            }            
            @include media-breakpoint-only(lg) {
                height: 151px;
                padding: 0 10px;         
            }
            @include media-breakpoint-up(xl) {
                padding: 3px 10px 5px;       
            }
            .progress-meet-bar {
                width: 145px;                
                padding-right: 5px;
                margin-right: 10px;
                font-size: 14px;
                line-height: 12px;
                padding-top: 5px;
                @media (max-width: 414px) {
                    padding-right: 0;
                    margin-right: 0;
                } 
                .title {
                    color: map-get($cp-green-shades, 7);
                    font-size: 12px;
                    font-weight: 700;
                    width: 94px;
                    line-height: 12px;
                    @media (max-width: 374px) {
                        font-size: 10px;
                        line-height: 11px;
                        width: 48px;
                        padding-left: 5px;
                    }
                }                           
                @include media-breakpoint-up(md) {
                    border-right: 2px solid map-get($cp-gray-shades, 4); 
                    .title {
                        width: auto;
                        span {
                            display: block;
                        }
                    }
                }
                @include media-breakpoint-up(lg) { 
                    .title {
                        line-height: 8px;
                    }
                }
                @include media-breakpoint-down(md) {
                    width: auto;
                    display: flex;
                    align-items: center;  
                    padding-right: 0;                  
                }
                @include media-breakpoint-only(md) {
                    padding-top: 16px; 
                    height: 100%;  
                    padding-right: 25px;
                    margin-right: 30px;                 
                }
                @include media-breakpoint-only(lg) {
                    height: 100%;
                    padding-top: 25px;
                }
                .progress--circle {
                    position: relative;
                    display: inline-block;
                    height: 70px;
                    width: 70px;
                    border-radius: 50%;
                    background-color: transparent;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;
                    &:before {
                        content: '';
                        position: absolute;                        
                        top: 14px;
                        left: 14px;
                        width: 60%;
                        height: 60%;
                        border-radius: 50%;
                        background-color: #fff;
                        border: 1px solid #b3aaa9;
                    }
                    &:after {
                        content: '';
                        display: inline-block;
                        width: 75%;
                        height: 75%;
                        border-radius: 50%;
                        background-color: #29b25a;
                        border: 1px solid #b3aaa9;
                    }                    
                    .progress__number {
                        position: absolute;
                        top: 28px;
                        text-align: center;
                        font-size: 13px;
                        color: #595959;	
                        left: 0;
                        margin: auto;
                        .percentage{
                            font-size: 14px;
                            font-weight: 700;
                        }
                        .text-complete {
                            display: none;                                                        
                        }                        
                    }
                    @media (min-width: 414px) {
                        height: 80px;
                        width: 80px;
                        &:before {
                            top: 11px;
                            left: 11px;
                            width: 72%;
                            height: 72%;
                        }
                        &:after {
                            width: 87%;
                            height: 87%;
                        }
                        .progress__number {
                            .text-complete {
                                font-size: 9px;
                                display: block;
                            } 
                        }                                                   
                    }                    
                }                
            } 
            @include media-breakpoint-down(md) {
                &.no-data-progress{
                    background: #1784AD ;
                    b{            
                        color: white;
                        padding: 5px;
                        text-align: center;
                    }
                }
            } 
            @include media-breakpoint-up(lg) {
                &.no-data-progress{
                    width: 98%;
                    b{            
                        width: 98%;
                    }
                }
            }   
        }
        &.header-right {            
            @include media-breakpoint-only(md) {
                margin-top: 10px;
            }   
            @include media-breakpoint-up(xl) {
                height: 110px;        
            }        
            .block-title {
                display: none;
                @include media-breakpoint-up(md) {
                    background-color: map-get($cp-gray-shades, 6);
                    font-weight: bold;
                    text-align: center;
                    color: map-get($cp-gray-shades, 22);
                    padding: 5px 0; 
                    display: block;  
                }                         
            }
            .block-body {
                padding: 0 10px 6px;  
                @include media-breakpoint-up(sm) {
                    padding: 10px;
                } 
                @include media-breakpoint-only(lg) {
                    padding: 10px 16px;
                }                            
                @include media-breakpoint-up(md) {
                    background-color: map-get($cp-gray-shades, 9); 
                    display: flex;                    
                    align-items: flex-end;
                } 
                .dropdown-block {
                    @include media-breakpoint-only(md) {
                        width: 100%;
                    }
                    .d-flex {
                        display: block !important;
                        @media (min-width: 375px) {
                            display: flex !important;
                        }
                    } 
                }
                @include media-breakpoint-only(lg) {
                    display: block;
                }          
                .dropdown {
                    @include media-breakpoint-only(md) {
                        width: 50%;
                    }
                    @include media-breakpoint-up(xl) {
                        width: 210px;
                    }
                    .label-title {
                        color: map-get($cp-gray-shades, 14);
                        font-weight: bold;
                        display: block;                        
                        @include media-breakpoint-up(xs) {
                            padding-top: 0; 
                        }
                        @include media-breakpoint-down(xs) {
                            padding-top: 10px;
                        }
                    }
                    @include media-breakpoint-only(lg) {
                        .dropdown-toggle {
                            margin-right: 20px;
                        }                        
                        &:last-child {
                            .dropdown-toggle {
                                margin-right: 0;
                            }
                        }
                    }
                    @media (min-width: 375px) and (max-width: 479px) { 
                        width: 50%;
                    }                
                    .dropdown-toggle {
                        width: 100%;
                        font-size: 14px;
                        border: 2px solid map-get($cp-gray-shades, 4);
                        background-color: $cp-light-shade;
                        border-radius: $cp-radius;
                        color: map-get($cp-gray-shades, 14);
                        text-align: left;
                        padding: 6px 20px 6px 8px;
                        position: relative;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap !important;
                        @media (min-width: 480px) {
                            width: 200px;
                        }
                        @media (min-width: 375px) and (max-width: 479px) { 
                            width: 98%;
                        }                                               
                        &:after {
                            position: absolute;
                            top: 50%;
                            right: 10px;
                            transform: translateY(-50%);
                            border-top: 6px solid;
                            border-right: 5px solid transparent;
                            border-left: 5px solid transparent;
                        }
                    }
                    .dropdown-menu {
                        width: 98%;
                        border: 2px solid map-get($cp-gray-shades, 4);
                        min-width: auto;
                        @include media-breakpoint-only(md) {
                            width: 82%;
                        }
                        .dropdown-item {
                            font-size: 13px;
                            padding: 3px 5px;
                            white-space: normal;
                            &.default-pathway {
                                background-color: map-get($cp-blue-shades, 8);
                                color: $cp-light-shade;
                            }
                            &.selected-pathway {
                                background-color: map-get($cp-blue-shades, 6);
                                color: $cp-light-shade;
                            }
                            a {   
                                padding: 5px;            
                                color: $gray-dark;
                                display: block;                                   
                                &:focus {
                                    background-color: nth($white-shades, 7); 
                                    outline: 1px dotted!important;
                                    z-index: 99;
                                }
                            }
                         }
                  
                         
                    }
                }
                .graduation-toggeler {
                    text-align: center;                    
                    .btn-group {
                        border: 2px solid map-get($cp-gray-shades, 4);
                        border-radius: $cp-radius;
                        @media (max-width: 413px) {
                            display: block;
                            border: none;
                        }
                        .btn {
                            background-color: map-get($cp-gray-shades, 25);
                            font-size: 12px;
                            font-weight: bold;
                            padding: 7px 20px 6px;
                            width: 50%;
                            cursor: pointer;
                            @media (min-width: 414px) {
                                width: 100%;
                            }
                            @include media-breakpoint-up(md) {
                                background-color: map-get($cp-gray-shades, 7);                                
                            }
                            &.active,
                            &:active{
                                color: $cp-light-shade;
                                background-color: map-get($cp-red-shades, 1);
                            }
                        }
                    }                
                }            
            }
        }
    }
}

.course-planner-page-body {
    background-color: $cp-light-shade;    
    @include media-breakpoint-up(md) {
        border-radius: $cp-radius;
        border: 2px solid #d9d9d9;
    }
    .bg-gray {
        @include media-breakpoint-up(md) {
            background-color: map-get($cp-gray-shades, 8);
        } 
        .no-data{
            text-align: center;
            @media (max-width: 768px) {
                width: 100%;
                img{
                    width: 100%;
                }
            }
        }
    }
    .grade-tab-list {   
        padding: 0 8px;
        @include media-breakpoint-up(md) {
            padding-left: 8px;
        }
        @include media-breakpoint-only(md) {
            width: 230px;
            padding-right: 10px;
        }
        @include media-breakpoint-up(lg) {
            width: 280px;
        }     
        @include media-breakpoint-up(xl) {
            width: 300px;
        }
        .nav-tabs {
            border: 0;
            list-style: none;
            padding-left: 0;            
            .nav-item{
                &:last-child .nav-link{
                    margin-bottom: 0;
                }
                @media (min-width: 375px) and (max-width: 767px) {
                    @include grid (2, 3%);                    
                }
                @media (max-width: 374px) {
                    margin-bottom: 10px;
                }
            }
            .nav-link {
                height: 50px;
                font-size: 13px;
                box-shadow: 0 0px 10px 0 rgba(0,0,0,0.5);
                border: none;
                border-radius: $cp-radius;
                color: map-get($cp-gray-shades, 10);
                background-color: $cp-light-shade; 
                font-weight: bold;
                padding: 0 5px;                
                position: relative;
                display: flex;
                align-items: center;
                cursor: pointer;
                &.planned {
                    .plan-status {
                        span {
                            color: map-get($cp-blue-shades, 3);
                        }
                    }
                }
                &.completed {
                    .plan-status {
                        span {
                            color: map-get($cp-green-shades, 5);
                        }
                    }
                }                                
                @include media-breakpoint-up(md) {
                    &.planned {
                        &::before {
                            background-color: map-get($cp-blue-shades, 3); 
                        }
                        &.active, &:focus {
                            border: 2px solid map-get($cp-blue-shades, 3);
                            &::after {
                                background-color: $cp-light-shade;
                                border-top: 2px solid map-get($cp-blue-shades, 3);
                                border-right: 2px solid map-get($cp-blue-shades, 3);
                            }                            
                        }                        
                    }
                    &.un-planned {
                        &::before {
                            background-color: map-get($cp-red-shades, 2); 
                        }                                                
                        &.active, &:focus {
                            border: 2px solid map-get($cp-red-shades, 2);
                            &::after {
                                background-color: $cp-light-shade;
                                border-top: 2px solid map-get($cp-red-shades, 2);
                                border-right: 2px solid map-get($cp-red-shades, 2);
                            }
                        }
                    }
                    &.completed {                        
                        &::before {
                            background-color: map-get($cp-green-shades, 5); 
                        }
                        &.active, &:focus {
                            border: 2px solid map-get($cp-green-shades, 5);
                            &::after {
                                background-color: $cp-light-shade;
                                border-top: 2px solid map-get($cp-green-shades, 5);
                                border-right: 2px solid map-get($cp-green-shades, 5);
                            }
                        }                        
                    }
                    &.summary-tab {
                        &.active, &:focus {
                            border: 2px solid map-get($cp-blue-shades, 3);
                            &::after {
                                background-color: $cp-light-shade;
                                border-top: 2px solid map-get($cp-blue-shades, 3);
                                border-right: 2px solid map-get($cp-blue-shades, 3);
                            }
                        }
                    }
                    border: 2px solid map-get($cp-gray-shades, 4);
                    padding: 8px 4px 6px 15px;
                    height: 70px;
                    box-shadow: none;
                    margin-bottom: 6px;
                    &:before {
                        position: absolute;
                        content: "";
                        left: -1px;
                        top: 0;
                        width: 8px;
                        height: 100%;
                        border-radius: 4px 0 0 4px; 
                    }                
                    &:after {
                        position: absolute;
                        content: "";
                        top: 39%;
                        right: -8px;
                        width: 14px;
                        height: 14px;                        
                        transform: rotate(45deg);                        
                    }
                }
                @include media-breakpoint-down(sm) {
                    &.planned {
                        &.active, &:focus {
                            border-left: 4px solid map-get($cp-blue-shades, 3);
                            transition: all 0.4s ease-in-out 0s;
                        }
                    }
                    &.un-planned {
                        &.active, &:focus {
                            border-left: 4px solid map-get($cp-red-shades, 2);
                            transition: all 0.4s ease-in-out 0s;
                        }
                    }
                    &.completed {
                        &.active, &:focus {
                            border-left: 4px solid map-get($cp-green-shades, 5);
                            transition: all 0.4s ease-in-out 0s;
                        }
                    }
                    &.summary-tab {
                        &.active, &:focus {
                            border-left: 4px solid map-get($cp-blue-shades, 3);
                            transition: all 0.4s ease-in-out 0s;
                        }
                    }
                } 
            }                               
                .plan-status {                    
                    @include media-breakpoint-up(md) {
                        padding-bottom: 4px;
                    }
                    .plan-status-text {
                        display: inline;
                        padding-right: 2px;
                        @include media-breakpoint-down(sm) {
                            display: none;
                        }
                    }
                    span {
                        color: map-get($cp-gray-shades, 10);
                        vertical-align: middle;
                        &:first-child {
                            width: 140px;
                            @extend .text-ellipsis;
                        }
                    }
                    .icon {
                        font-size: 14px;
                        padding-left: 2px;
                        &.icon-cp_planned {
                            color: map-get($cp-blue-shades, 3);
                        }
                        &.icon-cp_unplanned {
                            color: map-get($cp-red-shades, 6);
                        }
                    }
                }
                .status-footer {
                    @include media-breakpoint-up(md) {
                        border-top: 1px solid map-get($cp-gray-shades, 17);
                        padding-top: 6px;
                    }
                    padding-top: 4px;                    
                    p {                    
                        color: map-get($cp-gray-shades, 10); 
                        @include media-breakpoint-down(sm) {
                            font-size: 11px;
                        }                                           
                    }
                }                
            }
        }
    }

    .grade-tab-content {
        border: 2px solid map-get($cp-gray-shades, 4);
        border-radius: $cp-radius * 2;        
        background-color: $cp-light-shade;
        padding: 5px 7px;
        margin-right: 8px;
        position: relative;
        height: 100%;
        @include media-breakpoint-only(md) {
            width: 450px;
        }
        @include media-breakpoint-only(lg) {
            width: 635px;
        }        
        @include media-breakpoint-up(xl) {
            width: 795px;
        }
        @include media-breakpoint-down(sm) {
            border: none;
            padding: 0 0 5px 0;
            margin: 0 auto;            
            width: 98%;
        }         
        .grade-list-block {
            margin-top: 8px;             
            .grade-body {
                border: 1px solid map-get($cp-gray-shades, 17);
                padding: 7px 7px 0;
                border-radius: $cp-radius - 2px;
                margin-bottom: 8px;
                &:last-child {
                    margin-bottom: 0;
                } 
                .grade-body-title {
                    font-weight: bold;
                    color: map-get($cp-gray-shades, 22);
                    display: inline-block;
                    padding-bottom: 5px;
                }
            }
        }
        .summary-info {
            p {
                color: map-get($cp-gray-shades, 14);
                margin-bottom: 10px;
                padding: 3px 10px 20px 8px;
                line-height: 20px;
                border-bottom: 2px solid map-get($cp-gray-shades, 4);
            }
        }
        .summary-tab-content {
            &.grade-list-block {
                overflow: hidden;
            }
            .grade-block {                
                @extend .border-with-radius;  
                margin-bottom: 7px;
                @media (min-width: 414px) {
                    display: flex;
                }       
                @include media-breakpoint-up(lg) {
                    @include grid (2, 1.125%);
                }                       
                @include media-breakpoint-up(xl) {
                    height: 80px;
                }
                .grade-title {
                    background-color: map-get($cp-blue-shades, 3);
                    color: $cp-light-shade;
                    font-weight: bold;                    
                    border-radius: 4px 0 0 4px;
                    border-right: 2px solid map-get($cp-gray-shades, 4);
                    display: flex;
                    align-items: center;
                    width: 160px;
                    padding: 5px;
                    justify-content: center;                                        
                    h2 {                        
                        margin-bottom: 0;
                        span {
                            display: block;
                            text-align: center;
                        }
                        .grade-text {
                            font-size: 30px;
                        }
                        .subject-text {
                            font-size: 14px;
                        }
                        .base-text {
                            font-size: 14px;                    
                        }
                        @include media-breakpoint-up(sm) {
                            .base-text {
                                font-size: 16px;                    
                            }
                        }                        
                    }                    
                    @include media-breakpoint-down(sm) {
                        h2 {
                            .grade-text {
                                font-size: 24px;
                            }
                        }                        
                    }
                    @media (max-width: 413px) {
                        width: auto; 
                        height: 50px;
                    }
                    @media (max-width: 374px) {                       
                        h2 {
                            .grade-text {
                                font-size: 22px;
                            }
                        }
                    }
                }
                .grade-status {
                    padding: 15px 10px;                    
                    display: flex;
                    @media (min-width: 414px) {
                        width: 80%;
                    }
                    @include media-breakpoint-only(lg) {
                        display: block;
                        h5 {
                            display: inline-block;
                            margin-bottom: 10px !important;
                            padding-right: 20px;
                            &:last-child {
                                margin-bottom: 0 !important;
                                padding-right: 0;
                            } 
                        }                        
                    }
                    h5 {
                        font-size: 12px;
                        font-weight: bold;
                        color: map-get($cp-gray-shades, 15);
                        position: relative;                                          
                        top: 3px;
                        margin-bottom: 0;
                        .icon {
                            font-size: 22px;
                            top: 2px;
                            padding-right: 5px;
                        }                    
                        .number {
                            font-size: 26px;
                            font-weight: 400;
                        }
                        .title-text {
                            @include media-breakpoint-only(xl) {
                                width: 80px;
                                display: flex;
                                height: 30px;
                                align-items: flex-end;
                            }
                            @media (min-width: 415px) and (max-width: 479px) {
                                width: 70px;
                                display: flex;
                                height: 30px;
                                align-items: flex-end; 
                            }                            
                        }
                    }
                    @include media-breakpoint-up(xl) {
                        padding: 0 5px;
                    }
                    @include media-breakpoint-down(sm) {
                        padding: 8px 10px;
                        h5 {
                            top: 2px;
                            .icon {
                                font-size: 20px;
                            }
                            .number {
                                font-size: 22px;
                            }
                        }
                    }
                    @media (max-width: 374px) {
                        h5 {
                            .icon {
                                font-size: 18px;
                                padding-right: 3px;
                            }
                            .number {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        } 
        .grade-header {
            @extend .border-with-radius;
            background-color: map-get($cp-gray-shades, 6);            
            &.subject-header {
                .left-title {
                    h5 span {
                        font-weight: bold;
                        font-size: 15px;
                    }
                }
            }
            .header-left {
                padding: 12px 10px 10px;
                @include media-breakpoint-up(md) {
                    width: 81%;
                }
                @include media-breakpoint-down(xs) {
                    padding: 8px 5px 0;
                    width: 93%;
                    .grade-name {
                        h3 {
                            font-size: 18px;
                            padding-bottom: 5px;                           
                        }
                    }
                }
                @include media-breakpoint-down(sm) {
                    flex: 1;
                }
                color: map-get($cp-gray-shades, 13);
                .grade-name {                      
                    @include media-breakpoint-up(md) {
                        h3 {
                            font-size: 22px; 
                        }
                    }
                }
                h3 {
                    font-size: 20px;
                    font-weight: bold;
                    flex: 1;                   
                }
                h5 {
                    font-size: 15px;
                    font-weight: bold;
                  span {
                      font-weight: 400;
                      font-size: 18px;
                      @include media-breakpoint-up(md) {
                        font-size: 20px;
                    }                      
                  }  
                }
                .subject-plan-ratio span {
                    font-size: 14px;
                    margin-bottom: 0;
                    font-weight: 700;
                    padding-top: 3px;
                }
                .status-block {
                    padding-right: 10px;
                    &:last-child {
                        padding-right: 0;
                    }
                    @media (min-width: 414px) {
                        display: flex;
                        align-items: center;
                    }
                    @include media-breakpoint-up(xl) {                       
                        padding-right: 26px;
                    }
                    .icon {
                        font-size: 32px;
                    }
                    h5 {
                        font-size: 12px;
                        margin-bottom: 0;
                        padding-left: 5px;
                    }
                    @include media-breakpoint-down(md) {
                        padding-top: 5px;
                        .icon {
                            font-size: 28px;
                            top: 2px;
                        }
                    }                    
                    @media (max-width: 374px) {
                        h5 {
                            font-size: 11px;
                            padding-left: 2px;
                        }
                    }
                }
            }
            .header-right {
                background-color: map-get($cp-blue-shades, 2);                
                position: relative;
                width: 30px;
                text-align: center;                
                border-radius: 0 4px 4px 0;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .addmore {
                    font-size: 14px;
                    color: $cp-light-shade;
                    width: 100%;
                    padding: 0 5px;
                    cursor: pointer;
                    button {
                        background: transparent;
                        border: 0px;
                        color: white;
                        display: block;
                        margin: auto;
                    }
                }
                @include media-breakpoint-only(sm) {
                    width: 45px
                }
                @include media-breakpoint-only(md) {
                    width: 100px;
                }
                @include media-breakpoint-up(lg) {
                    width: 150px;
                }  
            }
        }       
        .grade-inline-block {
            background-color: map-get($cp-gray-shades, 7);            
            border-radius: $cp-radius;  
            position: relative;
            &.required-courses {
                border: 1px solid map-get($cp-gray-shades, 17);
                .header {
                    background-color: map-get($cp-gray-shades, 26);
                }
            }
            &.completed-courses {
                border: 1px solid map-get($cp-green-shades, 5);
                .header {
                    background-color: map-get($cp-green-shades, 5);
                } 
            }         
            @include media-breakpoint-down(lg) {
                margin-bottom: 7px;                
            }
            @include media-breakpoint-only(lg) {
                @include grid (2, 1%);               
            }
            @include media-breakpoint-up(xl) {
                @include grid (3, 1%);
            }            
            &.planned-courses {
                border: 1px solid map-get($cp-blue-shades, 6);
                .header {
                    background-color: map-get($cp-blue-shades, 6);
                } 
            }
            .header {                
                padding: 10px 20px 10px 10px;
                color: $cp-light-shade;                                                                
                @include media-breakpoint-down(sm) {                    
                    padding: 10px 30px 10px 7px;
                }                 
                h3 {
                    font-size: 13px;
                    font-weight: bold;
                    margin-bottom: 0;
                    @include media-breakpoint-up(xl) {
                        width: 105px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }                    
                    @include media-breakpoint-only(md) {
                        font-size: 12px;
                    }
                    @media (max-width: 374px) {
                        h3 {
                            width: auto;
                        }
                    }
                }
                h3 span {
                    padding-top: 5px;
                    font-weight: 400;
                }
                @include media-breakpoint-down(xs) {
                    .header {
                        padding: 5px 10px 5px 10px;
                    }
                    h3 {
                        font-size: 11px;
                    }
                }                
            }
            .content {
                padding: 0;
                font-size: 12px;
                color: map-get($cp-gray-shades, 13); 
                display: flex;
                justify-content: space-between;
                align-items: center;
                .content-bottom {
                    display: table;
                    padding: 6px 5px 6px 10px;
                    span {
                        vertical-align: middle;
                    }
                    .icon {
                        font-size: 18px;                        
                        padding-right: 5px; 
                        top: 1px;                       
                    }
                    button {
                        display: contents;
                        background: transparent;
                        border: none;
                        outline: 0;
                        cursor: pointer;
                        color: map-get($cp-gray-shades, 13); 
                    }                    
                    @media (max-width: 374px) {
                        .icon {                        
                            top: 2px;                       
                        } 
                        span {
                            font-size: 11px;
                        }
                    }                    
                }
                .content-icon {
                    padding: 6px 10px;
                    border-left: 1px solid map-get($cp-gray-shades, 17);
                    height: 100%;
                    button {
                        padding: 0;
                        border: 0;
                        background-color: transparent;
                        cursor: pointer;                        
                        i {
                            font-size: 26px;
                            color: map-get($cp-green-shades, 5);
                        }
                    }
                    @include media-breakpoint-only(md) {
                        padding: 6px 5px;
                        button i {
                            font-size: 26px; 
                        }
                    } 
                    @include media-breakpoint-up(lg) {
                        padding: 6px 20px;
                        button i {
                            font-size: 34px; 
                        }
                    }                   
                }                
                @include media-breakpoint-down(sm) {
                    .content-bottom {
                        padding: 6px 5px 6px 5px;
                    }
                }               
            }
            .triangle-shape {
                height: 100%;
                position: absolute;
                top: 0;
                right: -1px;                
                cursor: pointer;
                .shape-box {
                    border: none;
                    background: transparent;
                    &:after {
                        position: absolute;
                        content: "\E07B";
                        font-family: plpicons;
                        font-size: 10px;
                        color: $cp-light-shade;
                    }
                }
                @include media-breakpoint-up(md) {
                    width: 21px;
                    height: 39px;
                    border-right: 1px solid $cp-light-shade;
                    transform: rotate(135deg);
                    top: -14px;
                    right: -5px;
                    border-top: 20px solid transparent;
                    border-bottom: 20px solid transparent;
                    border-right: 20px solid map-get($cp-red-shades, 1);
                    .shape-box {                        
                        position: absolute;
                        height: 7px;
                        width: 15px;
                        position: absolute;
                        top: -4px;
                        left: 5px;
                        &:after {
                            transform: rotate(225deg);
                            top: -4px;
                            left: 3px;
                        }
                    }
                }
                .shape-box {
                    @include media-breakpoint-down(sm) {
                        width: 25px;
                        height: 100%;
                        background-color: map-get($cp-red-shades, 1);
                        border-radius: 0 $cp-radius $cp-radius 0;
                        &:after {                           
                            top: 50%;
                            transform: translateY(-50%);
                            right: 0;
                            left: 0;
                        }

                    }
                }
            }            
        }
        .courses-filter-block {
            position: absolute;
            top: 0;
            right: -2px;
            border: 2px solid map-get($cp-blue-shades, 4);
            background-color: $cp-light-shade;
            border-radius: $cp-radius + $cp-radius;
            width: 570px;
            height: 430px;
            z-index: 0;
            @include media-breakpoint-down(md) {
                width: 100%;
                right: 0;
            }
            @include animation('slide-up .5s');
            .close-btn {
                position: absolute;
                right: 9px;
                top: 9px;
                font-size: 10px;
                cursor: pointer;
                background: transparent;
                border: none;
            }
            .courses-filter-header {
                background-color: map-get($cp-gray-shades, 5);
                padding: 8px 3px 5px 10px;
                color: map-get($cp-gray-shades, 15);
                border-bottom: 2px solid map-get($cp-blue-shades, 4);
                border-radius: $cp-radius + $cp-radius $cp-radius + $cp-radius 0 0; 
                font-size: 12px;  
                font-weight: bold;                             
            }
            .courses-filter-body {
                padding: 5px;
                .input-group {
                    border: 2px solid map-get($cp-gray-shades, 17);
                    border-radius: $cp-radius + 2px;
                    background-color: map-get($cp-gray-shades, 7);
                    .btn {
                        background-color: map-get($cp-gray-shades, 7);
                        border: none;
                        font-size: 18px;
                        color: map-get($cp-gray-shades, 10);
                        cursor: pointer;
                        height: 30px;
                        padding: 0 5px;
                    }
                    .form-control {
                        border: none;
                        border-radius: $cp-radius + $cp-radius;
                        background-color: transparent;
                        font-size: 14px;
                        color: map-get($cp-gray-shades, 15);
                        padding: 4px;
                    }
                }
                .filter-inner-body {
                    height: 300px;
                    @extend .scroll-y;
                }                
                .filter-btn-block {
                    text-align: right;
                    .filter-btn-class[aria-expanded="false"] {
                        .filter-btn-icon {
                            background-color: map-get($cp-green-shades, 2);
                            border: 1px solid map-get($cp-gray-shades, 3);
                            }
                        .icon-cp_close {
                            display: none;
                        }
                    }
                    .filter-btn-class[aria-expanded="true"]{
                        .filter-btn-icon {
                            background-color: map-get($cp-red-shades, 7);
                            border: 1px solid map-get($cp-red-shades, 3);
                        }
                        .icon-filter {
                            display: none;
                        }
                    }
                    .grade-btn {
                        background-color: map-get($cp-gray-shades, 3); 
                        color: map-get($cp-gray-shades, 10); 
                        font-size: 10px; 
                        border-radius: $cp-radius * 10;
                        display: inline-block;
                        width: 60px;
                        padding: 4px 5px 4px 12px; 
                        margin-right: 5px;
                        border: 1px solid map-get($cp-gray-shades, 3);
                        font-weight: bold;                        
                        span {
                            float: right;
                            color: map-get($cp-red-shades, 2);
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 16px;
                            cursor: pointer;
                        }                                        
                    }
                    .filter-btn-icon {
                        border-radius: $cp-radius;
                        color: $cp-light-shade;
                        cursor: pointer;
                        box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.5);
                        padding: 4px 10px 2px;
                    }  
                    button {
                        background: transparent;
                        border: none;
                    }
                    .dropdown-toggle::after{
                        display: none;
                    }
                }
                .blocks-list {                  
                    .block { 
                        width: 98.5%;                       
                        border-radius: $cp-radius;
                        border: 2px solid map-get($cp-gray-shades, 17);
                        font-weight: bold;
                        color: map-get($cp-gray-shades, 15);
                        font-size: 13px;
                        letter-spacing: -0.5px;
                        cursor: pointer;                                                                       
                        @include media-breakpoint-up(xs) {
                            width: 49%;
                            margin: 0 5px 5px 0;
                            display: inline-block;
                            &:nth-child(2n) {
                                margin-right: 0;
                            }
                        }
                        @include media-breakpoint-down(sm) {
                            margin-bottom: 5px;
                        }
                        .header {
                            background-color: map-get($cp-gray-shades, 5);                                                       
                            padding: 6px 5px 5px 5px;
                            border-bottom: 2px solid map-get($cp-gray-shades, 17);
                            display: flex;
                            align-items: center;
                            height: 50px;
                            @include media-breakpoint-between(sm, md) {
                                height: 60px;
                            }
                        }
                        .footer {
                            display: flex;
                            justify-content: space-between;
                            padding: 8px 5px 2px 10px;
                            span i {
                                font-size: 16px;
                                color: transparent;
                            }
                        }
                        &.course-added {
                            cursor: not-allowed;
                            border: 2px solid map-get($cp-blue-shades, 3);
                            .header {
                                background-color: map-get($cp-blue-shades, 3);
                                color: $cp-light-shade;
                                border-bottom: 2px solid map-get($cp-blue-shades, 3);
                            }
                            .footer,
                            .footer span i {
                                color: map-get($cp-blue-shades, 3);
                            }
                        }
                    }
                    .no-data-box {
                        position: relative;
                        // height: 100%;
                        img {
                            position: absolute;
                            top: 50%;
                        }
                    }
                }
                .filter-content-list {
                    .control p span{
                        font-weight: 400;
                        color: #000;
                        font-size: 14px;
                    }
                    background-color: $cp-light-shade;
                    padding: 10px 5px 0 10px;
                    border-radius: $cp-radius;
                    border: 2px solid map-get($cp-blue-shades, 4);
                    top: 0;
                    &:before {
                        position: absolute;
                        content: "";
                        top: 2px;
                        right: 31px;
                        width: 14px;
                        height: 14px;
                        background-color: #fff;
                        transform: rotate(-45deg);
                        border-top: 2px solid map-get($cp-blue-shades, 4);
                        border-right: 2px solid map-get($cp-blue-shades, 4);                        
                    }
                    .scroll-y {                           
                        @media (max-width: 479px) {
                            height: 292px;
                        }
                    }
                    .filter-check {
                        @media (min-width: 414px) {
                            width: 80%;
                            margin: 0 auto 10px;
                        }
                        margin-bottom: 10px;                        
                    }
                    .checkmark {
                        font-size: 12px;
                        font-weight: bold;
                        color: map-get($cp-gray-shades, 18);
                    }
                    .graduation-plan { 
                        text-align: left;                                                                       
                        @media (min-width: 480px) {
                            display: flex;
                            justify-content: space-between;
                        }
                        span {
                           color: map-get($cp-gray-shades, 10); 
                           font-weight: 700;
                           margin-bottom: 10px;
                        }   
                        .dropdown-btn {
                            display: flex;
                            align-items: center;
                            position: relative;
                            width: 175px;
                            padding: 5px 10px 5px 5px;
                            @media (max-width: 479px) {
                                width: 160px
                            }
                            @media (max-width: 413px) {
                                width: 100%;
                                margin-top: 15px;
                            }
                            .selected-item {
                                width: 78%;
                                @extend .text-ellipsis;
                                background-color: map-get($cp-blue-shades, 3);
                                position: relative;
                                @include media-breakpoint-up(md) {
                                    width: 80%;
                                }
                                a {
                                    position: absolute;
                                    bottom: 1px;
                                    right: 3px;
                                }
                            }
                            .dropdown-down,
                            .dropdown-up {
                                top: 50%;
                                right: 5px;
                                position: absolute;
                                transform: translateY(-50%);
                                border-left: 5px solid transparent;
                                border-right: 5px solid transparent
                            }
                            .dropdown-down {                                
                                border-top: 6px solid #adadad;                                                                
                            }
                            .dropdown-up {
                                border-bottom: 6px solid #adadad;                                
                            }                            
                        }
                        .dropdown-list {
                            position: relative;                                                      
                            padding: 0;
                            margin-top: 0;
                            margin-right: 5px;
                            border: 0;
                            box-shadow: none;
                            @include media-breakpoint-up(lg) {
                                width: 225px;
                            }
                            @include media-breakpoint-only(md) {
                                width: 190px;
                            }
                            @include media-breakpoint-only(sm) {
                                width: 200px;
                            }
                            @include media-breakpoint-only(xs) {
                                width: 170px;
                            }
                            @media (max-width: 479px) {
                                width: 100%;
                            }
                            ul.item1 {
                                .filter-textbox {
                                    padding: 4px 5px 4px 10px;
                                }
                                li {
                                    padding: 8px 0 !important;
                                }
                            }
                            ul.item2 {
                                overflow: inherit;
                                @media (max-width: 479px) {
                                    max-height: inherit !important;
                                    padding-bottom: 10px;
                                }
                            }
                            li {
                                padding: 5px 0;
                                input[type=checkbox]:checked + div:before {
                                    background-color: transparent;
                                    animation: inherit;
                                }
                                div {
                                    &:before {
                                        border: 1px solid map-get($cp-gray-shades, 28);
                                        border-radius: 3px;                                        
                                    }
                                    &:after {
                                        left: 3px;
                                        border-color: map-get($cp-gray-shades, 28);
                                    }
                                }
                            }
                        }                        
                    }
                }
            }
            /* Edit Course scss */
            .edit-course-filter {
                padding-right: 3px;
                @extend .scroll-y;
                height: 380px; 
               .edit-course-block {
                   .course-title {
                       font-size: 14px;
                       padding: 0 0 10px;
                       color: map-get($cp-gray-shades, 15);
                   } 
                   .blocks-filter-list {
                       border: 1px solid map-get($cp-gray-shades, 21);
                       border-radius: $cp-radius;
                       margin-bottom: 5px;
                       text-align: center;
                       position: relative;
                       padding: 7px 5px;
                       &:last-child {
                           margin-bottom: 0;
                       }
                       @include media-breakpoint-up(lg) {
                           display: flex;
                           justify-content: space-around;
                       }
                       .grade-list {
                           color: map-get($cp-gray-shades, 11);
                           text-align: left;                           
                            & > span {
                                margin-right: 4px;
                                @media (max-width: 374px) {
                                    display: block;
                                }                                
                            }                           
                           span {
                               font-weight: bold;
                               font-size: 12px;                               
                           }
                           .btn {
                               padding: 6px 3px;
                               text-align: left;
                               width: 117px;
                               @include media-breakpoint-down(xs) {
                                width: 115px;
                                padding: 6px 2px;
                            } 
                               &:after {
                                   float: right;
                                   margin-top: 5px;
                                   border-top: 5px solid;                            
                                   border-right: 5px solid transparent;                            
                                   border-left: 5px solid transparent;   
                               }
                               &.border-danger{
                                border: 1px solid map-get($cp-red-shades, 7);
                            }
                           }
                           .dropdown-menu {
                               min-width: 110px;
                               right: 0;
                               padding: 0;
                               @media (max-width: 340px) {
                                right: inherit;
                               }
                               .dropdown-item {
                                   font-size: 13px;                                 
                                   cursor: pointer; 
                                   padding: 3px 5px;                               
                                   a {   
                                    padding: 5px;            
                                    color: $gray-dark;
                                    display: block;                                   
                                    &:focus {
                                        background-color: nth($white-shades, 7); 
                                        outline: 1px dotted !important;
                                        z-index: 99;
                                    }
                                }
                               }
                           }
                       }
                       .btn {
                           font-size: 12px;
                           font-weight: bold;                        
                           background-color: map-get($cp-gray-shades, 7);
                           color: map-get($cp-gray-shades, 11);
                           padding: 6px 12px;
                       }
                       .btn-group {
                           border: 1px solid map-get($cp-gray-shades, 21);
                           border-radius: $cp-radius;
                           display: flex;
                           .btn {
                               border-right: 1px solid map-get($cp-gray-shades, 21);                                                                                  
                               &:last-child {
                                   border-right: 0;
                               }
                               &.btnactive {
                                   background-color: map-get($cp-green-shades, 4);
                                   color: $cp-light-shade;
                               }
                           }
                           @media (min-width: 480px) {
                                display: inline-flex;
                                .btn {
                                    width: 68px; 
                                }
                            }
                            @media (max-width: 479px) {
                                width: 85%;
                                margin: 10px auto 0;
                                .btn {
                                    flex: 1; 
                                }
                            }
                            @media (max-width: 374px) {
                                width: 90%;
                                margin: 0;
                            }                              
                       }
                       .term-dropdown {
                            display: flex;
                            justify-content: space-between;
                            @media (min-width: 375px) {
                                justify-content: space-around;
                            } 
                            @include media-breakpoint-down(md) {                                
                                align-items: flex-end;
                                width: 80%;
                                margin: 0 auto 10px;
                            }  
                            @media (max-width: 479px) {
                                width: 100%;
                            }                         
                       }
                       .remove-term {                          
                            font-size: 16px;  
                            text-align: right;
                            width: 20px;
                            height: 25px;                                          
                            @include media-breakpoint-down(md) {
                                position: absolute;
                                right: 5px;
                                bottom: 10px;
                            }
                            button {
                                background-color: transparent;
                                border: 0;
                                color: map-get($cp-red-shades, 3);
                                cursor: pointer;
                                padding: 0;
                                margin-left: 5px;
                            }
                        }
                        .authoritative {
                            &.show {
                                .dropdown-menu {
                                    display: none;
                                }
                            }
                            .btn{
                                cursor: not-allowed;
                                &:active:focus {
                                    outline: none;
                                    box-shadow: none;
                                }
                            }
                        }                                                                    
                    }
                   .btn-add {
                       background-color: #388E3C;
                       border: 2px solid transparent;
                       color: $cp-light-shade;
                       &:hover, &:focus {
                        background-color: $cp-light-shade;
                        border: 2px solid map-get($cp-green-shades, 5);
                        color: map-get($cp-green-shades, 5);
                    }
                   }
                   .btn-remove {
                       border: 2px solid transparent;
                       background-color: map-get($cp-red-shades, 3);
                       color: $cp-light-shade;
                       font-size: 14px;
                       font-weight: normal !important;
                       border-radius: $cp-radius;
                       padding: 8px 10px;
                       @include media-breakpoint-down(xs) {
                        padding: 8px 5px;
                       } 
                       &.close {
                           opacity: 1;
                           text-shadow: none;
                           float: none;
                       }
                       span {
                           font-size: 22px;
                           line-height: 12px;
                           position: relative;
                           top: 3px;
                           right: -2px;
                       }
                       &:hover, &:focus {
                           background-color: transparent;
                           border: 2px solid map-get($cp-red-shades, 3);
                           color: map-get($cp-red-shades, 3);
                       }
                   }
                }
                .notes-block {
                   border: 1px solid map-get($cp-gray-shades, 21);
                   border-radius: $cp-radius;
                   background-color: map-get($cp-gray-shades, 6);
                   padding: 3px;
                   .notes-title {
                       font-size: 13px;
                       font-weight: bold;
                       color: map-get($cp-gray-shades, 11);
                    }
                   .note-cards {
                       position: relative;
                       background-color: $cp-light-shade;
                       padding: 3px 5px 3px;
                       font-size: 13px;
                       line-height: 16px;
                       color: map-get($cp-gray-shades, 11);
                       margin: 0 0 10px;
                       border-radius: $cp-radius;
                       box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                       p {
                           margin: 3px 0 0; 
                           width: 90%;
                       }
                       span {
                           width: 90%;
                           &:focus {
                            border-bottom: 1px solid map-get($cp-gray-shades, 21);
                            outline: none;
                               
                           }
                       }
                       .searchclear {
                            position: absolute;
                            top: 13px;
                            right: 8px;
                            width: 13px;
                            font-size: 10px;
                            cursor: pointer;
                            color: map-get($cp-red-shades, 3);
                        }
                    }
                    .remove-note {
                        position: absolute;
                        right: 5px;
                        bottom: 5px;                           
                        font-size: 14px;
                        button {
                            background-color: transparent;
                            border: 0;
                            color: map-get($cp-red-shades, 3);
                            cursor: pointer;
                        }
                    }
                    input {
                        padding: 8px;
                        font-size: 13px;
                        width: 93%;
                        @media (max-width: 374px) {
                            width: 91%;
                        }
                    }
                    input[type=text]::-ms-clear{
                        display: none;
                      }
                }                   
            }
            .btn-add {
                background-color: $cp-light-shade;
                border: 2px solid map-get($cp-blue-shades, 4);
                color: map-get($cp-blue-shades, 4);
                border-radius: $cp-radius;
                font-size: 14px;
                padding: 5px 7px;
                height: 35px;
                cursor: pointer;
                span {
                    font-size: 26px;
                    line-height: 14px;
                    position: relative;
                    top: 4px;
                    right: 0;
                    margin-right: 2px;
                }
                &:hover, &:focus {
                    background-color: map-get($cp-blue-shades, 4);
                    border: 2px solid $cp-light-shade;
                    color: $cp-light-shade;
                }
            }
        }            
    }        
    .icon {
        position: relative;
        .red {
            color: map-get($cp-red-shades, 4);
        }
        .blue {
            color: map-get($cp-blue-shades, 7);
        }
        .green {
            color: map-get($cp-green-shades, 6);
        }
    }

    .list-of-courses {
        background-color: map-get($cp-gray-shades, 8);
        padding: 8px;
        @include media-breakpoint-up(md) {
            background-color: $cp-light-shade;
            padding: 10px;
        }        
        .courses-inner-block {
            position: relative;            
            padding: 8px;
            border-radius: $cp-radius;
            background-color: $cp-light-shade;
            @include media-breakpoint-up(md) {
                background-color: map-get($cp-gray-shades, 8);
            }
            .courses-selected-list {                                
                .btn {
                    margin: 0 8px 8px 0;
                    font-size: 12px;                    
                    flex-grow: 1;
                    font-weight: bold;
                    background-color: map-get($cp-blue-shades, 3);
                    border: 1px solid map-get($cp-blue-shades, 3);
                    color: $cp-light-shade;
                    width: 100%;
                    @include media-breakpoint-up(md) {
                        width: 300px;
                    }
                }
                .courses-data {
                    height: 80px;
                    overflow: hidden;
                    display: block;
                    transition: all 0.4s ease-in-out 0s;
                }
                .more-courses-btn {
                    font-weight: bold;
                    color: map-get($cp-gray-shades, 10);
                    cursor: pointer;
                    padding-right: 10px;
                    text-align: right;
                }
            }
            .information-block {
                width: 95%;               
                text-align: center;
                padding: 10px 0;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                img {
                    width: 85px;
                    margin-right: 15px;
                }                                
                p {
                    color: map-get($cp-gray-shades, 23);
                    line-height: 20px;
                    font-size: 16px;
                    margin-bottom: 0;
                    text-align: left;
                }
                @include media-breakpoint-down(sm) {
                    border-bottom: 1px solid map-get($cp-gray-shades, 24);
                }
            }
            .remove-course-block {
                position: absolute;
                top: 5px;
                right: 5px;
                border: 1px solid map-get($cp-red-shades, 5);
                border-radius: $cp-radius;                
                button {
                    font-size: 10px;
                    background-color: transparent;
                    border: none;
                    position: relative;
                    top: 1px;
                    color: map-get($cp-red-shades, 5);
                    cursor: pointer;
                    padding: 0 5px;
                }
            }
        }
    }
    .child-pathway {
        background-color: #2bb15a;
        color: white;
    } 

/****************courseplanner-snackbar************/
.snackbar-wrap {
    width: 300px;
    background-color: map-get($cp-green-shades, 8);
    border: 2px solid $cp-light-shade;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
    padding: 10px;
    position: fixed;
    bottom: 50px;
    z-index: 99999; 
    left: 50%;
    padding: 10px 0;
    border-radius: 5px;
    min-width: 20%;
    transform-origin: center center;
    transform: translate(-50%, 0%);
    &.success {
      background-color: map-get($cp-green-shades, 8);
    }
    &.danger {
      background-color: map-get($cp-red-shades, 7);
    }
    &.warning {
        background-color: map-get($cp-red-shades, 4);
      }
    .snackbar-text {
      text-align: center;
      font-size: 14px;
      color: $cp-light-shade;
      margin: 0;
    }
  }/******courseplanner-snackbar ends*******/
.class-forOpacity {
    opacity: 0.5;
    cursor: not-allowed!important;
}
/* The below code for Modal Popup */
/* Start */
.modal-content {
    .cp-modal-box {
        color: map-get($cp-gray-shades, 10);
        .modal-header {
            background-color: map-get($cp-blue-shades, 3);
            color: $cp-light-shade;
            padding: 0 0 0 15px;
            .close {
                height: 43px;   
                width: 45px;
                opacity: 1;
                font-size: 14px;
                background-color: map-get($cp-red-shades, 3);
                text-shadow: none;
                color: $cp-light-shade;
            }
        }
        .modal-body {
            .table th, 
            .table td {
                padding: 10px 12px 3px;
                font-size: 14px;    
            }
        }
        .terms-list {
            color: map-get($cp-gray-shades, 11);
            text-align: left;
            @media (min-width: 480px) {
                display: flex;
                align-items: center;
            }
            span {
                font-size: 14px;
            }
            .dropdown {
                width: 150px;
                .btn {
                    padding:5px;
                    text-align: left;
                    width: 130px;
                    font-size: 14px; 
                    background:rgba(221, 221, 221,1);
                    &:after {
                        float: right;
                        margin-top: 5px;
                        border-top: 5px solid;                            
                        border-right: 5px solid transparent;                            
                        border-left: 5px solid transparent;   
                    }
                    &.border-danger {
                        border: 1px solid map-get($cp-red-shades, 7);
                    }
                }
                .dropdown-menu {
                    min-width: 8rem; 
                    padding: 0;   
                    .dropdown-item {
                        font-size: 13px;
                        padding: 3px 5px;
                        cursor: pointer;
                    }
                }
            }                
        }
        .modal-footer {
            border: 0;
            .btn {
                border: 2px solid transparent;
                color: $cp-light-shade;
                cursor: pointer;
                padding: 4px 20px;            
                &.btn-del {
                    background-color: map-get($cp-red-shades, 3);
                    &:hover, &:focus {
                        background-color: $cp-light-shade;
                        border: 2px solid map-get($cp-red-shades, 3);
                        color: map-get($cp-red-shades, 3);
                    }
                }
                &.btn-save {
                    background-color: map-get($cp-blue-shades, 4);
                    &:hover, &:focus {
                        background-color: $cp-light-shade;
                        border: 2px solid map-get($cp-blue-shades, 4);
                        color: map-get($cp-blue-shades, 4);
                    }
                }
            }        
        }
    }    
 }/* End */



// multi checkBoxs style under filter button
.control-group{
    display: inline-flex;
    vertical-align: top;
    background: #fff;
    text-align: left;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    padding: 20px;
    width: 100%;    
    margin: 0;
}
// Basic control styles
.control{
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 0px;
    cursor: pointer;
     // Hide default browser input
  input{
  position: absolute;
  z-index: -1;
  opacity: 0;
  outline: 1px dotted #000;
}
input:checked:focus ~ .control__indicator{
    outline: 1px dotted #000;
}
input:focus ~ .control__indicator{
    outline: 1px dotted #000;
}
input:checked ~ .control__indicator:after {
    display: block;
}
 
}
 
    
.filter-dropdown
{
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    top: 12%;
    border: 0;
    padding-bottom:0;
}

// Custom control
.control__indicator{
    position: absolute;
    top: 5px;
    left: 0;
    height: 14px;
    width: 14px;
    border: 1px solid #464a4c;
    border-radius: 3px;
}

.control--checkbox{
    .control__indicator:after {
        left: 4px;
        top: 1px;
        width: 5px;
        height: 9px;
        border: solid #464a4c;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
} 
.control__indicator:after {
    content: '';
    position: absolute;
    display: none;
}