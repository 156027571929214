
// We have written important to override formio styles, without this(!important) styles are not getting applied
// .choices__list--single {
//     display:none !important; 
// }
// .choices[data-type*=select-one] {
//     .choices__input {
//         border: 0 !important;
//     }
//     &:after {
//         display: none !important;
//     }
// }    
// .choices[data-type*=select-one] .choices__input{
//     display:none !important;
// }
app-custom-form {
    .form-check-inline {
        margin-right: 0.75rem;
    }
    .form-check-inline + .form-check-inline {        
        margin-left: 0;
    }
    .form-control {
        color: nth($black-shades, 7);
        width: 100% !important;       
    }
    .form-control[pattern],
    :not(output):-moz-ui-invalid{
        box-shadow:none;    
    }    
    .alert-danger,
    .has-error,
    .help-block,
    .control-label {
        color: nth($black-shades, 7) !important;
    }
    @include media-breakpoint-down(xs) {
        .col {
            flex-basis: auto !important;
        }       
    }
    @include media-breakpoint-down(md) {
        .input-group,
        .control-label {            
            width: 100% !important;
        }
        .control-label {
            float: none !important;
        }                   
        .input-group {
            margin-left: 0 !important;
            flex-wrap: nowrap;
        }
        .formio-component-columns {
            .col-sm-4 {
                max-width: 100%;
                flex: 100%;
            }
        }
    }
    .form-control:focus {
        color: nth($black-shades, 7);
    } 
    .form-control:-ms-input-placeholder {
        color: #464a4c;
     }   
     .form-control.choices__input.is-hidden{
        display:none !important;
    }
    
    .formio-component{
        .form-control{
            flex-direction: row!important;
            input#worldWorkCareerInterest{
                @include media-breakpoint-down(xs){
                    font-size: $font-sizes-22;
                    padding-left: 4px;
                }
            }
        }
     }
    .formio-component .submit-success::after{
        content: '';
        display: none;
    }
    .formio-component .submit-fail::after{
        content: '';
        display: none;
    }   
    .mypanel .card-body {
        padding: 0 15px;
    }
}
.input-group {
    margin-left: 0 !important;
    flex-wrap: nowrap;
}